import { Skeleton } from "antd";
import { ToastContainer, toast } from "react-toastify";
import Header from "../../Components/Header";
import MainHeader from "../../Components/MainHeader";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import { Delete, GetTheList, Post } from "../../Config/ApiHandling";
import { useEffect, useState, useCallback } from "react";
import EditButton from "../../Components/EditButton";
import { Modal } from "react-bootstrap";
import SearchInput from "../../Components/SearchInp";
import Copyright from "../../Components/Copyright";
import DeleteButton from "../../Components/DeleteButton";
import Swal from "sweetalert2";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
// import { useNavigate } from 'react-router-dom';

const SaloonList = ({ setCollapsed, collapsed }) => {
  let [data, setData] = useState([]);
  let [flag, setflag] = useState(false);
  const [showModal, setShowModal] = useState(false);
  let [detail, setDetail] = useState([]);
  // let navigation = useNavigate()
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState([]);
  const startIndex = (currentPage - 1) * 10 + 1;
  const googleMapsApiKey = "AIzaSyC5PZM8s51v1b1Vf_-QQQHWwZlTtn2RQHY";
  const { isLoaded } = useLoadScript({
    googleMapsApiKey,
  });

  const navigate = useNavigate();
  const center = {
    lat: Number(detail[0]?.latitude),
    lng: Number(detail[0]?.longitude),
  };

  const mapOptions = {
    disableDefaultUI: true,
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const PartnerDetails = (userId) => {
    setDetail(data.filter((item) => item._id === userId));
    setShowModal(true);
  };
  const EditPartnerDetail = (userId) => {
    let filterdata = data.filter((item) => item._id === userId);
    // console.log(filterdata);
    navigate("/dashboard/EditPartner", { state: { data: filterdata } });
  };

  const PayOnCash = (userId) => {
    Post(`admin/PayonCashScenario/${userId}`, {}, token)
      .then((succ) => {
        const successMessage =
          succ.data && succ.data.message && succ.data.message
            ? "Pay on cash successfully"
            : "An error occurred";
        toast(successMessage, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      })
      .catch((err) => {
        const errorMessage =
          err.response && err.response.data && err.response.data.message
            ? err.response.data.message
            : "An error occurred";
        toast(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  };

  let cols = [
    {
      name: "Sr No",
      key: "index",
    },
    {
      name: "Salon Name",
      key: "partnername_eng",
    },
    {
      name: "Action",
      displayFeild: (item) => (
        <>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              onClick={() => PayOnCash(item?._id)}
              className="px-4"
              style={{
                marginTop: "1px",
                marginRight: "10px",
                backgroundColor: "#212529",
                color: "#fff",
                borderRadius: "3px",
                height: "32px",
                textTransform: "capitalize",
              }}
              type="primary"
            >
              {item?.isPayOnCash === true
                ? "Pay on cash enabled"
                : "Pay on cash disabled"}
            </Button>
            <EditButton
              text="Saloon Details"
              click={() => PartnerDetails(item._id)}
            />
            <DeleteButton text="Delete" click={() => DeleteSaloon(item._id)} />
            <Button
              onClick={() => EditPartnerDetail(item?._id)}
              className="px-4"
              style={{
                marginTop: "1px",
                backgroundColor: "#212529",
                color: "#fff",
                borderRadius: "3px",
                height: "32px",
                textTransform: "capitalize",
              }}
              type="primary"
            >
              Edit
            </Button>
          </div>
        </>
      ),
    },
  ];

  const token = localStorage.getItem("token");
  const GetData = useCallback(() => {
    const limit = 10;
    GetTheList(`partner/getBusiness`, currentPage, limit, token)
      .then((res) => {
        setData(res.data.data.businesses);
        setTotalPages(res.data.data.last_page);
      })
      .catch((e) => {
        const errorMessage =
          e.response && e.response.data && e.response.data.message
            ? e.response.data.message
            : "An error occurred";
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      })
      .finally(() => {
        setflag(false);
      });
  }, [currentPage, token, data]);

  useEffect(() => {
    GetData();
  }, [currentPage, GetData]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  let DeleteSaloon = (e) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    })
      ?.then((result) => {
        if (result.isConfirmed) {
          Delete("/partner/deleteBusiness", e, token)
            ?.then((res) => {
              GetData();
            })
            .catch((e) => {
              const errorMessage =
                e.response && e.response.data && e.response.data.message
                  ? e.response.data.message
                  : "An error occurred";
              toast.error(errorMessage, {
                position: "top-right",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
            });
        }
      })
      .catch((e) => {
        const errorMessage =
          e.response && e.response.data && e.response.data.message
            ? e.response.data.message
            : "An error occurred";
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  };

  const renderPaginationButtons = () => {
    const buttons = [];
    const maxButtonsToShow = 5;
    const halfWay = Math.floor(maxButtonsToShow / 2);

    let startPage, endPage;

    if (totalPages <= maxButtonsToShow) {
      // If total pages are less than max buttons to show, show all pages
      startPage = 1;
      endPage = totalPages;
    } else if (currentPage <= halfWay) {
      // If current page is near the start, show the first few pages
      startPage = 1;
      endPage = maxButtonsToShow;
    } else if (currentPage + halfWay >= totalPages) {
      // If current page is near the end, show the last few pages
      startPage = totalPages - maxButtonsToShow + 1;
      endPage = totalPages;
    } else {
      // Otherwise, center the current page in the range
      startPage = currentPage - halfWay;
      endPage = currentPage + halfWay;
    }

    // Generate page buttons
    for (let i = startPage; i <= endPage; i++) {
      buttons.push(
        <li
          key={i}
          className={`page-item ${currentPage === i ? "active" : ""}`}
        >
          <button
            className="page-link"
            onClick={() => handlePageChange(i)}
            style={{
              backgroundColor: currentPage === i ? "white" : "#212529",
              border: "1px solid #212529",
              borderRadius: "5px",
              color: currentPage === i ? "black" : "white",
            }}
          >
            {i}
          </button>
        </li>
      );
    }

    return buttons;
  };

  return (
    <>
      <div>
        <MainHeader setCollapsed={setCollapsed} collapsed={collapsed} />
        <Header screenTitle="Saloon List" />
        <ToastContainer />
        <div
          style={{
            display: "flex",
            alignItems: "end",
            justifyContent: "end",
            width: "100%",
            margin: "0px -40px",
          }}
        >
          <SearchInput
            setflag={setflag}
            data={data}
            setData={setData}
            originalData={GetData}
            endpoint={"partner/search-Business"}
            msg={"This_is_not_typ_case"}
          />
        </div>
        <>
          <Modal
            show={showModal}
            onHide={handleCloseModal}
            centered
            backdrop="true"
            keyboard={false}
            dialogClassName="custom-modal"
            size="lg"
          >
            <Modal.Header closeButton>
              <Modal.Title>Salon Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {detail && (
                <>
                  <div className="container">
                    <div className="row mt-4">
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <h6>Salon Name (Eng): </h6>{" "}
                        <input
                          type="text"
                          style={{
                            border: "1px solid black",
                            borderRadius: "5px",
                            padding: "8px",
                            width: "100%",
                          }}
                          value={detail[0]?.partnername_eng}
                          disabled
                        />
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <h6>Salon Name (Arb): </h6>{" "}
                        <input
                          type="text"
                          style={{
                            border: "1px solid black",
                            borderRadius: "5px",
                            padding: "8px",
                            width: "100%",
                            textAlign: "end",
                          }}
                          value={detail[0]?.partnername_arb}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <h6>Your Status: </h6> <p>{detail[0]?.status}</p>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <h6>Store Link: </h6>{" "}
                        <input
                          type="text"
                          style={{
                            border: "1px solid black",
                            borderRadius: "5px",
                            padding: "8px",
                            width: "100%",
                          }}
                          value={detail[0]?.store_link}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <h6>Home Amount Min (SAR): </h6>{" "}
                        <input
                          type="text"
                          style={{
                            border: "1px solid black",
                            borderRadius: "5px",
                            padding: "8px",
                            width: "100%",
                          }}
                          value={detail[0]?.home_Amnt_min}
                          disabled
                        />
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <h6>Salon Amount Min (SAR): </h6>{" "}
                        <input
                          type="text"
                          style={{
                            border: "1px solid black",
                            borderRadius: "5px",
                            padding: "8px",
                            width: "100%",
                          }}
                          value={detail[0]?.salon_Amnt_min}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <h6>Settlement Frequency: </h6>{" "}
                        <input
                          type="text"
                          style={{
                            border: "1px solid black",
                            borderRadius: "5px",
                            padding: "8px",
                            width: "100%",
                          }}
                          value={detail[0]?.settlement_frequency}
                          disabled
                        />
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <h6>Supplier Availability(Gender(s)): </h6>{" "}
                        <input
                          type="text"
                          style={{
                            border: "1px solid black",
                            borderRadius: "5px",
                            padding: "8px",
                            width: "100%",
                          }}
                          value={detail[0]?.supplier_availability}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <h6>Auto Confirm Bookings: </h6>{" "}
                        <input
                          type="text"
                          style={{
                            border: "1px solid black",
                            borderRadius: "5px",
                            padding: "8px",
                            width: "100%",
                          }}
                          value={detail[0]?.auto_Confirm_Bookings}
                          disabled
                        />
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <h6>Payment Modes: </h6>{" "}
                        <input
                          type="text"
                          style={{
                            border: "1px solid black",
                            borderRadius: "5px",
                            padding: "8px",
                            width: "100%",
                          }}
                          value={detail[0]?.payment_Modes}
                          disabled
                        />
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <h6>Service Type: </h6>{" "}
                        <input
                          type="text"
                          style={{
                            border: "1px solid black",
                            borderRadius: "5px",
                            padding: "8px",
                            width: "100%",
                          }}
                          value={detail[0]?.service_Type}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <h6>Available Today: </h6>{" "}
                        <p>{detail[0]?.available_today}</p>
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <h6>Description(eng) (Optional): </h6>{" "}
                        <textarea
                          type="text"
                          style={{
                            border: "1px solid black",
                            borderRadius: "5px",
                            padding: "8px",
                            width: "100%",
                            height: "20vh",
                            resize: "none",
                          }}
                          value={detail[0]?.description_eng}
                          disabled
                        />
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <h6>Description(arb) (Optional): </h6>{" "}
                        <textarea
                          type="text"
                          style={{
                            border: "1px solid black",
                            borderRadius: "5px",
                            padding: "8px",
                            width: "100%",
                            height: "20vh",
                            resize: "none",
                            textAlign: "end",
                          }}
                          value={detail[0]?.description_arb}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div className="col-lg-12 col-md-6 col-sm-12">
                        <h6>Location: </h6>
                        <div style={{ height: "400px", width: "100%" }}>
                          {!isLoaded ? (
                            <h1>Loading...</h1>
                          ) : detail[0]?.latitude && detail[0]?.longitude ? (
                            <GoogleMap
                              mapContainerStyle={{
                                height: "100%",
                                width: "100%",
                              }}
                              zoom={10}
                              center={center}
                              options={mapOptions}
                            >
                              <Marker
                                position={{
                                  lat: Number(detail[0]?.latitude),
                                  lng: Number(detail[0]?.longitude),
                                }}
                              />
                            </GoogleMap>
                          ) : (
                            <p>No location available</p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <h6>Neighborhood: </h6>{" "}
                        <input
                          type="text"
                          style={{
                            border: "1px solid black",
                            borderRadius: "5px",
                            padding: "8px",
                            width: "100%",
                          }}
                          value={detail[0]?.neighborhood}
                          disabled
                        />
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <h6>Location Name: </h6>{" "}
                        <input
                          type="text"
                          style={{
                            border: "1px solid black",
                            borderRadius: "5px",
                            padding: "8px",
                            width: "100%",
                          }}
                          value={detail[0]?.location_name}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <h6>Country: </h6>{" "}
                        <input
                          type="text"
                          style={{
                            border: "1px solid black",
                            borderRadius: "5px",
                            padding: "8px",
                            width: "100%",
                          }}
                          value={detail[0]?.country}
                          disabled
                        />
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <h6>City: </h6>{" "}
                        <input
                          type="text"
                          style={{
                            border: "1px solid black",
                            borderRadius: "5px",
                            padding: "8px",
                            width: "100%",
                          }}
                          value={detail[0]?.city}
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}
            </Modal.Body>
            <Modal.Header>
              <Modal.Title>Working Hours</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {detail && (
                <>
                  <div className="container">
                    <div className="row mt-4">
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <h6>Days </h6>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <h6>Start Time </h6>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <h6>End Time </h6>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <h6>Off day </h6>
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <h6>Sunday</h6>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <input
                          type="text"
                          style={{
                            border: "1px solid black",
                            borderRadius: "5px",
                            padding: "8px",
                            width: "100%",
                          }}
                          value={detail[0]?.sunday_startTime}
                          disabled
                        />
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <input
                          type="text"
                          style={{
                            border: "1px solid black",
                            borderRadius: "5px",
                            padding: "8px",
                            width: "100%",
                          }}
                          value={detail[0]?.sunday_endTime}
                          disabled
                        />
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <h6>
                          {detail[0]?.sunday_offday === true
                            ? "Closed"
                            : "Open"}
                        </h6>
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <h6>Monday</h6>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <input
                          type="text"
                          style={{
                            border: "1px solid black",
                            borderRadius: "5px",
                            padding: "8px",
                            width: "100%",
                          }}
                          value={detail[0]?.monday_startTime}
                          disabled
                        />
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <input
                          type="text"
                          style={{
                            border: "1px solid black",
                            borderRadius: "5px",
                            padding: "8px",
                            width: "100%",
                          }}
                          value={detail[0]?.monday_endTime}
                          disabled
                        />
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <h6>
                          {detail[0]?.monday_offday === true
                            ? "Closed"
                            : "Open"}
                        </h6>
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <h6>Tuesday</h6>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <input
                          type="text"
                          style={{
                            border: "1px solid black",
                            borderRadius: "5px",
                            padding: "8px",
                            width: "100%",
                          }}
                          value={detail[0]?.tuesday_startTime}
                          disabled
                        />
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <input
                          type="text"
                          style={{
                            border: "1px solid black",
                            borderRadius: "5px",
                            padding: "8px",
                            width: "100%",
                          }}
                          value={detail[0]?.tuesday_endTime}
                          disabled
                        />
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <h6>
                          {detail[0]?.tuesday_offday === true
                            ? "Closed"
                            : "Open"}
                        </h6>
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <h6>Wednesday</h6>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <input
                          type="text"
                          style={{
                            border: "1px solid black",
                            borderRadius: "5px",
                            padding: "8px",
                            width: "100%",
                          }}
                          value={detail[0]?.wednesday_startTime}
                          disabled
                        />
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <input
                          type="text"
                          style={{
                            border: "1px solid black",
                            borderRadius: "5px",
                            padding: "8px",
                            width: "100%",
                          }}
                          value={detail[0]?.wednesday_endTime}
                          disabled
                        />
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <h6>
                          {detail[0]?.wednesday_offday === true
                            ? "Closed"
                            : "Open"}
                        </h6>
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <h6>Thursday</h6>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <input
                          type="text"
                          style={{
                            border: "1px solid black",
                            borderRadius: "5px",
                            padding: "8px",
                            width: "100%",
                          }}
                          value={detail[0]?.thursday_startTime}
                          disabled
                        />
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <input
                          type="text"
                          style={{
                            border: "1px solid black",
                            borderRadius: "5px",
                            padding: "8px",
                            width: "100%",
                          }}
                          value={detail[0]?.thursday_endTime}
                          disabled
                        />
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <h6>
                          {detail[0]?.thursday_offday === true
                            ? "Closed"
                            : "Open"}
                        </h6>
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <h6>Friday</h6>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <input
                          type="text"
                          style={{
                            border: "1px solid black",
                            borderRadius: "5px",
                            padding: "8px",
                            width: "100%",
                          }}
                          value={detail[0]?.friday_startTime}
                          disabled
                        />
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <input
                          type="text"
                          style={{
                            border: "1px solid black",
                            borderRadius: "5px",
                            padding: "8px",
                            width: "100%",
                          }}
                          value={detail[0]?.friday_endTime}
                          disabled
                        />
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <h6>
                          {detail[0]?.friday_offday === true
                            ? "Closed"
                            : "Open"}
                        </h6>
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <h6>Saturday</h6>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <input
                          type="text"
                          style={{
                            border: "1px solid black",
                            borderRadius: "5px",
                            padding: "8px",
                            width: "100%",
                          }}
                          value={detail[0]?.saturday_startTime}
                          disabled
                        />
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <input
                          type="text"
                          style={{
                            border: "1px solid black",
                            borderRadius: "5px",
                            padding: "8px",
                            width: "100%",
                          }}
                          value={detail[0]?.saturday_endTime}
                          disabled
                        />
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <h6>
                          {detail[0]?.saturday_offday === true
                            ? "Closed"
                            : "Open"}
                        </h6>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </Modal.Body>
            <Modal.Header>
              <Modal.Title>Bank Account Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {detail && (
                <>
                  <div className="container">
                    <div className="row mt-4">
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <h6>Account Number: </h6>{" "}
                        <input
                          type="text"
                          style={{
                            border: "1px solid black",
                            borderRadius: "5px",
                            padding: "8px",
                            width: "100%",
                          }}
                          value={detail[0]?.account_number}
                          disabled
                        />
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <h6>Account Name: </h6>{" "}
                        <input
                          type="text"
                          style={{
                            border: "1px solid black",
                            borderRadius: "5px",
                            padding: "8px",
                            width: "100%",
                          }}
                          value={detail[0]?.account_name}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <h6>Branch Name: </h6>{" "}
                        <input
                          type="text"
                          style={{
                            border: "1px solid black",
                            borderRadius: "5px",
                            padding: "8px",
                            width: "100%",
                          }}
                          value={detail[0]?.branch_name}
                          disabled
                        />
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <h6>IBAN: </h6>{" "}
                        <input
                          type="text"
                          style={{
                            border: "1px solid black",
                            borderRadius: "5px",
                            padding: "8px",
                            width: "100%",
                          }}
                          value={detail[0]?.iban}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div className="col-lg-12 col-md-6 col-sm-12">
                        <h6>Extra Information: </h6>{" "}
                        <input
                          type="text"
                          style={{
                            border: "1px solid black",
                            borderRadius: "5px",
                            padding: "8px",
                            width: "100%",
                          }}
                          value={detail[0]?.extra_info}
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}
            </Modal.Body>
          </Modal>

          <div style={{ display: "flex", justifyContent: "center" }}>
            {flag ? (
              <>
                <table
                  className="table table-striped table-bordered text-center"
                  style={{
                    width: "90%",
                    margin: "1%",
                    border: "1px solid #ccc",
                  }}
                >
                  <thead>
                    <tr>
                      {cols &&
                        cols.map((item, index) => {
                          return (
                            <th
                              style={{
                                backgroundColor: "#212529",
                                color: "#fff",
                              }}
                              key={index}
                            >
                              {item.name}
                            </th>
                          );
                        })}
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td colSpan="6">
                        {flag ? (
                          <Skeleton.Input
                            style={{ width: "90vh" }}
                            active
                            animation="wave"
                          />
                        ) : (
                          "No Data"
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </>
            ) : Array.isArray(data) && data.length > 0 ? (
              <table
                className="table table-striped table-bordered text-center"
                style={{ width: "90%", margin: "1%", border: "1px solid #ccc" }}
              >
                <thead>
                  <tr>
                    {cols &&
                      cols.map((item, index) => {
                        return (
                          <th
                            className="tablehead"
                            style={{
                              backgroundColor: "#212529",
                              color: "#fff",
                              fontSize: "20px",
                            }}
                            key={index}
                          >
                            {item.name}
                          </th>
                        );
                      })}
                  </tr>
                </thead>
                <tbody>
                  {data.map((rowData, key) => (
                    <tr key={key} className="table_img tablerow">
                      {cols.map((col, index) => (
                        <td style={{ verticalAlign: "middle" }} key={index}>
                          {col.displayFeild ? (
                            col.displayFeild(rowData)
                          ) : col.key === "index" ? (
                            startIndex + key
                          ) : col.img ? (
                            <img
                              className="table_img"
                              src={`${rowData[col.img]}`}
                              alt="NoImage"
                              style={{ width: "60px", height: "55px" }}
                            />
                          ) : col.key === "amount" || col.key === "price" ? (
                            `$${rowData[col.key]}`
                          ) : col.key === "partnername_eng" ? (
                            rowData?.partnername_eng
                          ) : null}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <table
                className="table table-striped table-bordered text-center"
                style={{ width: "90%", margin: "1%", border: "1px solid #ccc" }}
              >
                <thead>
                  <tr>
                    {cols &&
                      cols.map((item, index) => {
                        return (
                          <th
                            style={{
                              backgroundColor: "#212529",
                              color: "#fff",
                            }}
                            key={index}
                          >
                            {item.name}
                          </th>
                        );
                      })}
                  </tr>
                </thead>
                <tbody>
                  {cols.map((item, index) => (
                    <tr key={index}>
                      <td key={index} colSpan="6">
                        <Skeleton.Input
                          style={{ width: "90vh" }}
                          active
                          animation="wave"
                        />{" "}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <nav aria-label="Page navigation example">
            <ul className="pagination justify-content-center gap-2  ">
              <li
                className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
              >
                <button
                  className="page-link"
                  style={{ color: "black" }}
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                >
                  Previous
                </button>
              </li>
              {/* {Array.from({ length: totalPages }, (_, index) => (
                                <li key={index} className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}>
                                    <button className="page-link" onClick={() => handlePageChange(index + 1)} style={{
                            backgroundColor: currentPage === index + 1 ? "white" : "#212529",
                            border: "1px solid #212529",
                            borderRadius: "5px",
                            color: currentPage === index + 1 ? "black" : "white"
                        }}>
                                        {index + 1}
                                    </button>
                                </li>
                            ))} */}
              {renderPaginationButtons()}
              <li
                className={`page-item ${
                  currentPage === totalPages ? "disabled" : ""
                }`}
              >
                <button
                  className="page-link"
                  style={{ color: "black" }}
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalPages}
                >
                  Next
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      <Copyright />
    </>
  );
};

export default SaloonList;
