import React, { useEffect, useState } from 'react';
import MainHeader from '../../Components/MainHeader';
import Header from '../../Components/Header';
import { Box } from '@mui/material';
import { Typography } from 'antd';
import BsButton from '../../Components/BsButton';
import { Post } from '../../Config/ApiHandling';
import { useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import DeleteButton from '../../Components/DeleteButton';
import Copyright from '../../Components/Copyright'

const EditSubscriber = ({ setCollapsed, collapsed }) => {
    const [loading, setLoading] = useState(true);
    const [formValues, setFormValues] = useState({
        email: ''
    });
    const navigation = useNavigate();
    const location = useLocation();

    useEffect(() => {
        setFormValues(location.state || { email: '' });
    }, [location.state]);

    // const token = localStorage.getItem('token');


    const initialValues = {
        email: formValues.email || '',
    };



    const validationSchemaSubscriber = Yup.object().shape({
        email: Yup.string()
            .email('Invalid email')
            .required('⚠️Email is required')
            .test('is-gmail', 'Email must be a Gmail address', (value) => {
                if (value) {
                    const gmailRegex = /@/;
                    return gmailRegex.test(value);
                }
                return true;
            }),
    })

    const sendData = (values) => {

        const formData = new FormData();
        formData.append('email', values.email);
        setLoading(true);
        Post('subscribe', {
            email: values.email,
        })
            .then((res) => {
                setLoading(false);
                toast("Subscriber created successfully", {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                navigation("/dashboard/subscriptionlist");
            })
            .catch((err) => {
                const errorMessage = err.response && err.response.data && err.response.data.message
                    ? err.response.data.message
                    : 'An error occurred';

                toast(errorMessage, {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            });
    };

    return (
        <div>
            <MainHeader setCollapsed={setCollapsed} collapsed={collapsed} />
            <Header
                screenTitle={'Create Subscription'}
                buttonList={[
                    {
                        displayField: () => (
                            <DeleteButton click={() => navigation('/dashboard/subscriptionlist')} text="Cancel" />
                        ),
                    },
                ]}
            />
            <Box sx={{ width: { md: '98%', sm: '325px', xs: '100%' } }} className=" shadow m-auto mt-2">
                <Box>
                    <ToastContainer />
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchemaSubscriber}
                        onSubmit={(values) => sendData(values)}
                        validateOnChange
                        enableReinitialize={true}
                    >
                        {({ handleSubmit, setFieldValue, touched, errors }) => (
                            <Form onSubmit={handleSubmit}>
                                <Box className="row p-3">
                                    <div className="col-md-6">
                                        <Typography variant="p"> Email : </Typography>
                                        <div className="mt-2">
                                            <Field
                                                className="form-control w-100"
                                                type="text"
                                                name="email"
                                                value={formValues.email}
                                                onChange={(e) => {
                                                    setFieldValue('email', e.target.value);
                                                    setFormValues({ ...formValues, email: e.target.value });
                                                }}
                                            />
                                        </div>
                                        {touched.email && errors.email ? (
                                            <div className="error text-danger">{errors.email}</div>
                                        ) : null}
                                    </div>
                                    <Box sx={{ display: 'flex', justifyContent: 'start' }} className=" py-3">
                                        <BsButton text={'Create Subscription'} loading={loading} onClick={handleSubmit} type="submit" />
                                    </Box>
                                </Box>
                            </Form>
                        )}
                    </Formik>
                </Box>
            </Box>
            <Copyright />
        </div>
    );
};

export default EditSubscriber;
