import { Box } from "@mui/system";
import React, { useState } from "react";
import BsButton from "../Components/BsButton";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { Post } from "../Config/ApiHandling";
import loginImg1 from "../assets/feature-flower 1.png";
import loginImg2 from "../assets/feature-flower 2.png";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import Swal from "sweetalert2";

const Login = () => {
  // const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false);
  const [showpassword, setshowpassword] = useState(false);
  const navig = useNavigate();

  const handleShowPassword = () => {
    setshowpassword((prevShowPassword) => !prevShowPassword);
  };

  const initialValues = {
    email: "",
    password: "",
  };

  const validationSchemaLogin = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email")
      .required("⚠️Email is required")
      .test("is-email", "Invalid email address", (value) => {
        if (value) {
          const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          return emailRegex.test(value);
        }
        return true;
      }),

    password: Yup.string()
      .min(8, "Password at least 8 characters")
      .required("⚠️Password is required"),
  });

  const loginButton = (values) => {
    setLoading(true);
    // setOpen(true);

    const formData = new FormData();
    formData.append("email", values.email);
    formData.append("password", values.password);

    Post("admin/login ", {
      email: values.email,
      password: values.password,
    })
      .then((succ) => {
        setLoading(false);
        // setOpen(false);
        let token = succ.data.data.token;
        let user = succ.data.data.user;
        localStorage.setItem("token", token);
        localStorage.setItem("islogin", true);
        localStorage.setItem("Admin Details", JSON.stringify(user));
        const successMessage =
          succ.data && succ.data.message && succ.data.message
            ? "Login successfully"
            : "An error occurred";
        Swal.fire({
          title: "Login!",
          text: `${successMessage}`,
          icon: "success",
        });
        navig("/dashboard");
      })
      .catch((err) => {
        setLoading(false);
        // setOpen(false);

        const errorMessage =
          err.response && err.response.data && err.response.data.message
            ? err.response.data.message
            : "An error occurred";

        toast(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  };

  return (
    <div className="bgimg-login">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchemaLogin}
        onSubmit={(values) => loginButton(values)}
        validateOnChange
      >
        {({ handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <Box
              sx={{
                width: {
                  md: "400px",
                  sm: "350px",
                  xs: "100%",
                  margin: "135px auto",
                  borderRadius: "20px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#CECECE",
                  opacity: "80%",
                },
                height: { md: "450px", sm: "350px", xs: "70%" },
              }}
              className="shadow"
            >
              <Box>
                <ToastContainer />
                <div className="login-head">
                  <img src={loginImg1} alt="" />
                  <h1 className="text-center fw-bolder py-2">Login</h1>
                  <img src={loginImg2} alt="" />
                </div>
                <Box className="px-3">
                  <Box className=" my-3  ">
                    <div className="mx-1">
                      <Field
                        type="text"
                        label="Email"
                        name="email"
                        placeholder="Email"
                        className="input-fields"
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="error text-danger"
                      />
                    </div>
                  </Box>
                  <Box className=" my-3  ">
                    <div className="mx-1 d-flex relative-position">
                      <Field
                        type={showpassword ? "text" : "password"}
                        label="Password"
                        name="password"
                        placeholder="Password"
                        className="input-fields"
                      />
                      <button
                        className="hide_btn"
                        onClick={handleShowPassword}
                        type="button"
                      >
                        {showpassword ? (
                          <EyeOutlined style={{ color: "#14213d" }} />
                        ) : (
                          <EyeInvisibleOutlined style={{ color: "#14213d" }} />
                        )}
                      </button>
                    </div>
                    <ErrorMessage
                      name="password"
                      component="div"
                      className="error text-danger"
                    />
                  </Box>

                  <Box
                    sx={{ display: "flex", justifyContent: "center" }}
                    className="py-3"
                  >
                    <BsButton
                      text="Login"
                      isButtonDisabled={loading}
                      type="submit"
                      onClick={handleSubmit}
                      loading={loading}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Login;
