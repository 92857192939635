import React, { useCallback, useEffect, useState } from "react";
import MainHeader from "../../Components/MainHeader";
import Header from "../../Components/Header";
import { Box } from "@mui/material";
import { Typography, Skeleton } from "antd";
import BsButton from "../../Components/BsButton";
import { GetList, Post } from "../../Config/ApiHandling";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import DeleteButton from "../../Components/DeleteButton";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import Copyright from "../../Components/Copyright";
import axios from "axios";
// import TextField from "@mui/material/TextField";
// import { values } from 'lodash'

const CreateCashBack = ({ setCollapsed, collapsed }) => {
  const [loading, setLoading] = useState(true);
  const [user, setuser] = useState(false);
  const [UserData, setUserData] = useState();
  let navigation = useNavigate();
  // const params = useParams()
  const token = localStorage.getItem("token");
  const [userList, setUserList] = useState([]);
  const [formValues, setFormValues] = useState({
    user: "",
    userId: "",
    amount: "",
  });

  const initialValues = {
    user: "",
    amount: "",
  };

  const validationSchemaCashBack = Yup.object().shape({
    user: Yup.string().required("⚠️ User is required"),
    amount: Yup.string().required("⚠️ Amount is required"),
  });

  const GetSalonData = useCallback(() => {
    GetList(`/auth/getbookings`, token)
      .then((res) => {
        setUserList(res?.data?.data?.Bookings);
        setLoading(false);
      })
      .catch((e) => {
        const errorMessage =
          e.response && e.response.data && e.response.data.message
            ? e.response.data.message
            : "An error occurred";
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  }, [token]);

  useEffect(() => {
    GetSalonData();
  }, [GetSalonData]);

  let sendData = (values) => {
    const formData = new FormData();
    formData.append("userId", formValues?.userId);
    formData.append("cash_amount", values.amount);

    Post("/admin/cashBack", formData, token)
      .then((res) => {
        setLoading(false);
        toast("Cash Back created successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          draggable: true,
          progress: undefined,
        });
        setTimeout(() => {
          navigation("/dashboard/promolist");
        }, 1000);
      })
      .catch((err) => {
        setLoading(false);
        const errorMessage =
          err.response && err.response.data && err.response.data.message
            ? err.response.data.message
            : "An error occurred";

        toast(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  const getuserbyid = async (id) => {
    console.log(id);
    try {
      const response = await axios.get(
        `https://backend.beautyparkbp.com/api/auth/getUserById/${id}`
      );
      if (response) {
        console.log(response?.data?.data);
        setUserData(response?.data?.data);
      }
    } catch (error) {}
  };
  return (
    <div>
      <MainHeader setCollapsed={setCollapsed} collapsed={collapsed} />
      <Header
        screenTitle={"Create Cash Back Promo"}
        buttonList={[
          {
            displayField: () => (
              <DeleteButton
                click={() => navigation("/dashboard/promolist")}
                text="Cancel"
              />
            ),
          },
        ]}
      />
      <Box
        sx={{ width: { md: "98%", sm: "325px", xs: "100%" } }}
        className=" shadow m-auto mt-2"
      >
        <Box>
          <ToastContainer />
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchemaCashBack}
            onSubmit={(values) => sendData(values)}
            validateOnChange
            enableReinitialize={true}
          >
            {({ handleSubmit, setFieldValue, touched, errors }) => (
              <Form onSubmit={handleSubmit}>
                {loading ? (
                  <div className="mt-2">
                    <Box className="row p-3">
                      <div className="col-md-6">
                        <Typography variant="p"> Select User : </Typography>
                        <div className="mt-2">
                          <Skeleton.Input active style={{ width: "50vh" }} />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <Typography variant="p"> Amount : </Typography>
                        <div className="mt-2">
                          <Skeleton.Input active style={{ width: "50vh" }} />
                        </div>
                      </div>
                      <Box
                        sx={{ display: "flex", justifyContent: "start" }}
                        className="  py-3"
                      >
                        <Skeleton.Button
                          active
                          shape="button"
                          style={{ width: "20vh" }}
                        />
                      </Box>
                    </Box>
                  </div>
                ) : (
                  <Box className="row p-3">
                    <div className="col-md-6">
                      <Typography variant="p"> Select User : </Typography>
                      <div className="mt-2">
                        <select
                          className="form-control w-100"
                          name="salon"
                          value={formValues?.user || ""}
                          onChange={(e) => {
                            setuser(true);
                            const selectedIndex = e.target.selectedIndex;
                            const selectedId =
                              selectedIndex > 0
                                ? userList[selectedIndex - 1]?.user?._id
                                : "";
                            setFieldValue("user", e.target.value);
                            setFormValues({
                              ...formValues,
                              user: e.target.value,
                              userId: selectedId,
                            });
                            getuserbyid(selectedId);
                          }}
                        >
                          {userList && userList.length > 0 ? (
                            <>
                              <option value="" disabled hidden>
                                Select the user
                              </option>
                              {userList.map((item, index) => (
                                <option
                                  key={index}
                                  value={item?.user?.firstname}
                                >
                                  {item?.user?.firstname} {item?.user?.lastname}
                                </option>
                              ))}
                            </>
                          ) : (
                            <option value="" disabled hidden>
                              No user found
                            </option>
                          )}
                        </select>
                        {touched.user && errors.user ? (
                          <div className="error text-danger">{errors.user}</div>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <Typography variant="p"> Amount : </Typography>
                      <div className="mt-2">
                        <Field
                          className="form-control w-100"
                          type="number"
                          name="amount"
                          value={formValues?.amount || ""} // Bind to the form's value for 'amount'
                          onChange={(e) => {
                            setFieldValue("amount", e.target.value);
                            setFormValues({
                              ...formValues,
                              amount: e.target.value,
                            });
                          }}
                        />
                        {touched.amount && errors.amount ? (
                          <div className="error text-danger">
                            {errors.amount}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <Box
                      sx={{ display: "flex", justifyContent: "start" }}
                      className="  py-3"
                    >
                      <BsButton
                        text={"Create Cash Back"}
                        loading={loading}
                        onClick={handleSubmit}
                        type="submit"
                      />
                    </Box>
                  </Box>
                )}
              </Form>
            )}
          </Formik>
        </Box>
      </Box>
      <div className="container mt-4">
        {user && (
          <div>
            <div className="userdetail">
              <p className="text-black fs-2 header-title">User Details</p>
              <div className="row">
                <div className="col-md-6 mb-3">
                  <div className="d-flex flex-column w-100">
                    <label htmlFor="" className="mb-1">First Name:</label>
                    <input type="text" disabled value={UserData?.firstname} className="rounded px-3 py-1"/>
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <div className="d-flex flex-column w-100">
                    <label htmlFor="" className="mb-1">Last Name:</label>
                    <input className="rounded px-3 py-1" type="text" disabled value={UserData?.lastname} />
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <div className="d-flex flex-column w-100">
                    <label htmlFor="" className="mb-1">Email:</label>
                    <input className="rounded px-3 py-1" type="text" disabled value={UserData?.email} />
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <div className="d-flex flex-column w-100">
                    <label htmlFor="" className="mb-1">Phone Number :</label>
                    <input className="rounded px-3 py-1" type="text" disabled value={UserData?.phonenumber} />
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <div className="d-flex flex-column w-100">
                    <label htmlFor="" className="mb-1">Gender :</label>
                    <input className="rounded px-3 py-1" type="text" disabled value={UserData?.gender?UserData?.gender:"---"} />
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <div className="d-flex flex-column w-100">
                    <label htmlFor="" className="mb-1">Address :</label>
                    <input className="rounded px-3 py-1" type="text" disabled value={UserData?.address?UserData?.address:"---"} />
                  </div>
                </div>
                
              </div>
            </div>
          </div>
        )}
      </div>
      <Copyright />
    </div>
  );
};

export default CreateCashBack;
