import React, { useEffect, useState } from 'react';
import MainHeader from '../../Components/MainHeader';
import Header from '../../Components/Header';
import { Box } from '@mui/material';
import { Typography, Skeleton } from 'antd';
import BsButton from '../../Components/BsButton';
import { GetById, Post, Put } from '../../Config/ApiHandling';
import { useNavigate, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import DeleteButton from '../../Components/DeleteButton';
import Copyright from '../../Components/Copyright'

const EditClient = ({ setCollapsed, collapsed }) => {
    const [loading, setLoading] = useState(true);
    const [formValues, setFormValues] = useState({
        clientName: '',
        clientName_arb: '',
    });
    const navigation = useNavigate();
    const params = useParams()
    const token = localStorage.getItem('token');

    useEffect(() => {
        if (params.id) {
            GetById("getclientByid", params.id, token)
                .then((res) => {
                    setFormValues(res.data.data[0])
                    setLoading(false);
                })
                .catch((err) => {
                    const errorMessage = err.response && err.response.data && err.response.data.message
                        ? err.response.data.message
                        : 'An error occurred';
                    toast.error(errorMessage, {
                        position: "top-right",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    setLoading(false);
                })
        } else {
            setFormValues({
                clientName: '',
                clientName_arb: '',
            });
            setLoading(false);
        }
    }, [params?.id,token]);



    const initialValues = {
        clientName: formValues.clientName || '',
        clientName_arb: formValues.clientName_arb || '',
    };



    const validationSchemaClient = Yup.object().shape({
        clientName: Yup.string()
            .required('⚠️Client Name (English) is required'),
        clientName_arb: Yup.string()
            .required('⚠️Client Name (Arabic) is required')
    })

    const sendData = (values) => {
        if (formValues?._id) {
            setLoading(true);
            Put('updateClient', formValues._id, {
                clientName: values.clientName,
                clientName_arb: values.clientName_arb,
            }, token,)
                .then((res) => {
                    setLoading(false);
                    toast("Client updated successfully", {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        draggable: true,
                        progress: undefined,
                    });
                    setTimeout(() => {
                        navigation("/dashboard/clientlist");
                    }, 1000);
                })
                .catch((err) => {
                    const errorMessage = err.response && err.response.data && err.response.data.message
                        ? err.response.data.message
                        : 'An error occurred';
                    toast(errorMessage, {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        draggable: true,
                        progress: undefined,
                    });
                });
        } else {
            setLoading(true);
            Post('create-client', {
                clientName: values.clientName,
                clientName_arb: values.clientName_arb,
            }, token)
                .then((res) => {
                    setLoading(false);
                    toast("Client created successfully", {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        draggable: true,
                        progress: undefined,
                    });
                    setTimeout(() => {
                        navigation("/dashboard/clientlist");
                    }, 1000);
                })
                .catch((err) => {
                    const errorMessage = err.response && err.response.data && err.response.data.message
                        ? err.response.data.message
                        : 'An error occurred';
                    toast(errorMessage, {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        draggable: true,
                        progress: undefined,
                    });
                });
        }
    };

    return (
        <div>
            <MainHeader setCollapsed={setCollapsed} collapsed={collapsed} />
            <Header
                screenTitle={`${formValues?._id ? 'Edit Client' : 'Create Client'}`}
                buttonList={[
                    {
                        displayField: () => (
                            <DeleteButton click={() => navigation('/dashboard/clientlist')} text="Cancel" />
                        ),
                    },
                ]}
            />
            <Box sx={{ width: { md: '98%', sm: '325px', xs: '100%' } }} className=" shadow m-auto mt-2">
                <Box>
                    <ToastContainer />
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchemaClient}
                        onSubmit={(values) => sendData(values)}
                        validateOnChange
                        enableReinitialize={true}
                    >
                        {({ handleSubmit, setFieldValue, touched, errors }) => (
                            <Form onSubmit={handleSubmit}>
                                {loading ? (
                                    <div className="mt-2">
                                        <Box className='row p-3'>
                                            <div className="col-md-6">
                                                <Typography variant='p'> Name (Eng) : </Typography>
                                                <div className='mt-2'>
                                                    <Skeleton.Input active style={{ width: '50vh' }} />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <Typography variant='p'> Name (Arb) : </Typography>
                                                <div className='mt-2'>
                                                    <Skeleton.Input active style={{ width: '50vh' }} />
                                                </div>
                                            </div>
                                        </Box>
                                    </div>
                                ) : (
                                    <Box className="row p-3">
                                        <div className="col-md-6">
                                            <Typography variant="p"> Name (Eng) : </Typography>
                                            <div className="mt-2">
                                                <Field
                                                    className="form-control w-100"
                                                    type="text"
                                                    name="clientName"
                                                    value={formValues.clientName}
                                                    onChange={(e) => {
                                                        setFieldValue('clientName', e.target.value);
                                                        setFormValues({ ...formValues, clientName: e.target.value });
                                                    }}
                                                />
                                            </div>
                                            {touched.clientName && errors.clientName ? (
                                                <div className="error text-danger">{errors.clientName}</div>
                                            ) : null}
                                        </div>
                                        <div className="col-md-6">
                                            <Typography variant="p"> Name (Arb) : </Typography>
                                            <div className="mt-2">
                                                <Field
                                                    className="form-control w-100"
                                                    type="text"
                                                    name="clientName_arb"
                                                    value={formValues.clientName_arb}
                                                    onChange={(e) => {
                                                        setFieldValue('clientName_arb', e.target.value);
                                                        setFormValues({ ...formValues, clientName_arb: e.target.value });
                                                    }}
                                                />
                                            </div>
                                            {touched.clientName_arb && errors.clientName_arb ? (
                                                <div className="error text-danger">{errors.clientName_arb}</div>
                                            ) : null}
                                        </div>
                                        <Box sx={{ display: 'flex', justifyContent: 'start' }} className=" py-3">
                                            <BsButton text={`${formValues?._id ? 'Update' : 'Create Client'}`} loading={loading} onClick={handleSubmit} type="submit" isButtonDisabled={loading} />
                                        </Box>
                                    </Box>
                                )}
                            </Form>
                        )}
                    </Formik>
                </Box >
            </Box >
            <Copyright />
        </div >
    );
};

export default EditClient;
