import "./SearchResultsList.css";
import { SearchResult } from "./SearchResult";

export const SearchResultsList = ({ results, onSelectSalon, saloonName }) => {
  return (
    <div className={`results-list ${saloonName ? "hidden" : ""}`}>
      {results.map((result, id) => {
        return (
          <SearchResult
            result={result}
            key={id}
            onClick={() => onSelectSalon(result._id, result.partnername_eng)}
          />
        );
      })}
    </div>
  );
};
