import { Button } from "@mui/material";
import React from "react";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";

const EditButton = ({ text, click, component }) => {
  const Icon = <RemoveRedEyeIcon />;

  return (
    <Button
      component={component} // Use the component prop to specify what HTML element to render
      onClick={click}
      className="px-4"
      style={{
        marginTop: "1px",
        backgroundColor: "#212529",
        color: "#fff",
        borderRadius: "3px",
        height: "32px",
        textTransform: "capitalize",
      }}
      type="button" // Use "button" type instead of "primary"
      startIcon={Icon} // Render the icon using startIcon prop
    >
      <span style={{ marginLeft: "5px" }}>{text}</span>
    </Button>
  );
};

export default EditButton;
