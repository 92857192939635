import React, { useEffect, useState } from 'react'
import MainHeader from '../../Components/MainHeader'
import Header from '../../Components/Header'
import { Box } from '@mui/material'
import { Typography, Skeleton } from 'antd'
import BsButton from '../../Components/BsButton'
import { GetById, Put } from '../../Config/ApiHandling'
import { useNavigate, useParams } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import DeleteButton from '../../Components/DeleteButton'
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import Copyright from '../../Components/Copyright'


const EditAdmin = ({ setCollapsed, collapsed }) => {
  const [formValues, setFormValues] = useState({
    firstname: '',
    lastname: '',
    email: '',
    phonenumber: '',
  });
  const params = useParams()
  const [loading, setLoading] = useState(true);
  let navigation = useNavigate()
  const token = localStorage.getItem("token")

  useEffect(() => {
    GetById("admin/getByID", params.id, token)
      .then((res) => {
        setFormValues(res.data.data)
        setLoading(false);
      })
      .catch((err) => {
        const errorMessage = err.response && err.response.data && err.response.data.message
          ? err.response.data.message
          : 'An error occurred';
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setLoading(false);
      })
  }, [token,params.id]);


  // const countrycode = [
  //   "+1", // United States
  //   "+44", // United Kingdom
  //   "+92", // Pakistan
  //   "+91", // India
  //   "+33", // France
  //   "+49", // Germany
  //   "+81", // Japan
  //   "+86", // China
  //   "+61", // Australia
  //   "+34", // Spain
  //   "+39", // Italy
  //   "+7", // Russia
  //   "+82", // South Korea
  //   "+52", // Mexico
  //   "+55", // Brazil
  //   "+971", // United Arab Emirates
  //   "+966", // Saudi Arabia
  //   "+968", // Oman
  //   "+20", // Egypt
  //   "+27", // South Africa
  //   "+30", // Greece
  //   "+31", // Netherlands
  //   "+32", // Belgium
  //   "+46", // Sweden
  //   "+47", // Norway
  //   "+48", // Poland
  //   "+54", // Argentina
  //   "+56", // Chile
  //   "+57", // Colombia
  //   "+58", // Venezuela
  //   "+60", // Malaysia
  //   "+63", // Philippines
  //   "+64", // New Zealand
  //   "+65", // Singapore
  //   "+66", // Thailand
  //   "+81", // Japan
  //   "+82", // South Korea
  //   "+84", // Vietnam
  //   "+86", // China
  //   "+90", // Turkey
  //   "+91", // India
  //   "+92", // Pakistan
  //   "+93", // Afghanistan
  //   "+94", // Sri Lanka
  //   "+95", // Myanmar
  //   "+98", // Iran
  //   "+212", // Morocco
  //   "+213", // Algeria
  //   "+216", // Tunisia
  //   "+218", // Libya
  //   "+220", // Gambia
  //   "+221", // Senegal
  //   "+222", // Mauritania
  //   "+223", // Mali
  //   "+224", // Guinea
  //   "+225", // Ivory Coast
  //   "+226", // Burkina Faso
  //   "+227", // Niger
  //   "+228", // Togo
  //   "+229", // Benin
  //   "+230", // Mauritius
  //   "+231", // Liberia
  //   "+232", // Sierra Leone
  //   "+233", // Ghana
  //   "+234", // Nigeria
  //   "+235", // Chad
  //   "+236", // Central African Republic
  //   "+237", // Cameroon
  //   "+238", // Cape Verde
  //   "+239", // Sao Tome and Principe
  //   "+240", // Equatorial Guinea
  //   "+241", // Gabon
  //   "+242", // Republic of the Congo
  //   "+243", // Democratic Republic of the Congo
  //   "+244", // Angola
  //   "+245", // Guinea-Bissau
  //   "+246", // British Indian Ocean Territory
  //   "+247", // Ascension Island
  //   "+248", // Seychelles
  //   "+249", // Sudan
  //   "+250", // Rwanda
  //   "+251", // Ethiopia
  //   "+252", // Somalia
  //   "+253", // Djibouti
  //   "+254", // Kenya
  //   "+255", // Tanzania
  //   "+256", // Uganda
  //   "+257", // Burundi
  //   "+258", // Mozambique
  //   "+260", // Zambia
  //   "+261", // Madagascar
  //   "+262", // Reunion
  //   "+263", // Zimbabwe
  //   "+264", // Namibia
  //   "+265", // Malawi
  //   "+266", // Lesotho
  //   "+267", // Botswana
  //   "+268", // Eswatini
  //   "+269", // Comoros
  // ];



  const initialValues = {
    firstname: formValues.firstname || '',
    lastname: formValues.lastname || '',
    email: formValues.email || '',
    phonenumber: formValues.phonenumber || '',
  };




  const validationSchemaUpdateAdmin = Yup.object().shape({
    firstname: Yup.string().required("⚠️First Name is Required"),
    lastname: Yup.string().required("⚠️Last Name is Required"),
    email: Yup.string().required("⚠️Email is Required"),
    phonenumber: Yup.string()
      .matches(/^[-+0-9]+$/, ('Phone number must only contain digits'))
      .min(10, "Phone number is too short")
      .max(15, 'Phone number is too long')
      .required('⚠️Phone number is required'),
  })

  let sendData = (values) => {
    setLoading(true);

    const formData = new FormData();
    formData.append('firstname', values.firstname);
    formData.append('lastname', values.lastname);
    formData.append('email', values.email);
    formData.append('phonenumber', values.phonenumber);

    Put("admin/updateadmin", formValues._id, {
      firstname: values.firstname,
      lastname: values.lastname,
      email: values.email,
      phonenumber: values.phonenumber,
      password: values.password,
    })
      .then((res) => {
        setLoading(false);
        toast.success("Admin updated successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          draggable: true,
          progress: undefined,
        });
        setTimeout(() => {
          navigation("/dashboard/adminlist");
        }, 1000);
      })
      .catch((error) => {
        setLoading(false);
        if (error && error.response && error.response.data) {
          const { status, data, message } = error.response.data;
          if (status === false && data === null && message) {
            toast.error(`Error: ${message}`, {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              draggable: true,
              progress: undefined,
            });
            return;
          }
        }
      });

  };

  return (
    <div>
      <MainHeader setCollapsed={setCollapsed} collapsed={collapsed} />
      <Header screenTitle="Edit Admin" buttonList={[
        {
          displayField: () => (
            <DeleteButton click={() => navigation("/dashboard/adminlist")} text="Cancel" />
          )
        }
      ]} />
      <Box sx={{ width: { md: '98%', sm: '325px', xs: '100%' } }} className=' shadow m-auto mt-2'>
        <Box >
          <ToastContainer />
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchemaUpdateAdmin}
            onSubmit={(values) => sendData(values)}
            validateOnChange
            enableReinitialize={true}
          >
            {({ handleSubmit, setFieldValue, touched, errors }) => (
              <Form onSubmit={handleSubmit}>
                {loading ? (
                  <div className="mt-2">
                    <Box className='row p-3'>
                      <div className="col-md-6">
                        <Typography variant='p'> First Name : </Typography>
                        <div className='mt-2'>
                          <Skeleton.Input active style={{ width: '40vh' }} />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <Typography variant='p'> Last Name : </Typography>
                        <div className='mt-2'>
                          <Skeleton.Input active style={{ width: '40vh' }} />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <Typography className='mt-5' variant='p'> Email : </Typography>
                        <div className='mt-2'>
                          <Skeleton.Input active style={{ width: '40vh' }} />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <Typography className='mt-5' variant='p'> Phone Number : </Typography>
                        <div className='mt-2'>
                          <Skeleton.Input active style={{ width: '40vh' }} />
                        </div>
                      </div>
                      <Box sx={{ display: 'flex', justifyContent: 'start' }} className='  py-3'>
                        <Skeleton.Button active shape='button' style={{ width: '20vh' }} />
                      </Box>
                    </Box>
                  </div>
                ) : (
                  <Box className='row p-3'>
                    <div className="col-md-6">
                      <Typography variant='p'> First Name : </Typography>
                      <div className='mt-2'>
                        <Field
                          className="form-control w-100"
                          type="text"
                          name="firstname"
                          value={formValues.firstname}
                          onChange={(e) => {
                            setFieldValue('firstname', e.target.value);
                            setFormValues({ ...formValues, firstname: e.target.value });
                          }}
                        />
                        {touched.firstname && errors.firstname ? (
                          <div className="error text-danger">{errors.firstname}</div>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <Typography variant='p'> Last Name : </Typography>
                      <div className='mt-2'>
                        <Field
                          className="form-control w-100"
                          type="text"
                          name="lastname"
                          value={formValues.lastname}
                          onChange={(e) => {
                            setFieldValue('lastname', e.target.value);
                            setFormValues({ ...formValues, lastname: e.target.value });
                          }}
                        />
                        {touched.lastname && errors.lastname ? (
                          <div className="error text-danger">{errors.lastname}</div>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <Typography className='mt-5' variant='p'> Email : </Typography>
                      <div className='mt-2'>
                        <Field
                          className="form-control w-100"
                          type="text"
                          name="email"
                          value={formValues.email}
                          onChange={(e) => {
                            setFieldValue('email', e.target.value);
                            setFormValues({ ...formValues, email: e.target.value });
                          }}
                        />
                        {touched.email && errors.email ? (
                          <div className="error text-danger">{errors.email}</div>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <Typography className='mt-5' variant='p'> Phone Number : </Typography>
                      <div className='mt-2'>
                        <div className="d-flex">
                          <Field
                            className="form-control w-100"
                            type="text"
                            name="phonenumber"
                            value={formValues.phonenumber}
                            onChange={(e) => {
                              setFieldValue('phonenumber', e.target.value);
                              setFormValues({ ...formValues, phonenumber: e.target.value });
                            }}
                          />
                        </div>
                        {touched.phonenumber && errors.phonenumber ? (
                          <div className="error text-danger">{errors.phonenumber}</div>
                        ) : null}
                      </div>
                    </div>
                    <Box sx={{ display: 'flex', justifyContent: 'start' }} className='  py-3'>
                      <BsButton
                        text={"Update"}
                        loading={loading}
                        onClick={handleSubmit}
                        type="submit"
                      />
                    </Box>
                  </Box>
                )}
              </Form>
            )}
          </Formik>
        </Box>
      </Box>
      <Copyright />
    </div>
  )

}

export default EditAdmin
