import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
// import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";

export default function PartnerTable({ data, tablename }) {
  console.log(data, tablename);
  //   const [page, setPage] = React.useState(0);

  let ConfirmOrdercolumns = [
    { id: "BookingID", label: "Booking ID", minWidth: 170 },
    { id: "BookingAmount", label: "Booking Amount", minWidth: 170 },
    { id: "BookingDate", label: "Booking Date", minWidth: 170 },
    { id: "Service_cat", label: "Service_cat", minWidth: 170 },
    { id: "Staffname", label: "Staff Name", minWidth: 170 },
  ];
  let StaffOrdercolumns = [
    { id: "first_name", label: "First Name", minWidth: 170 },
    { id: "last_name", label: "Last Name", minWidth: 170 },
  ];
  let Servicescolumns = [
    { id: "service_cat", label: "service_cat", minWidth: 170 },
    { id: "treatment_type", label: "Treatment Type", minWidth: 170 },
  ];
  let PendingOrdercolumns = [
    { id: "BookingID", label: "Booking ID", minWidth: 170 },
    { id: "BookingAmount", label: "Booking Amount", minWidth: 170 },
    { id: "BookingDate", label: "Booking Date", minWidth: 170 },
    { id: "Service_cat", label: "Service_cat", minWidth: 170 },
    { id: "Staffname", label: "Staff Name", minWidth: 170 },
    { id: "status", label: "Status", minWidth: 170 },
  ];
  let TotalOrdercolumns = [
    { id: "BookingID", label: "Booking ID", minWidth: 170 },
    { id: "BookingAmount", label: "Booking Amount", minWidth: 170 },
    { id: "BookingDate", label: "Booking Date", minWidth: 170 },
    { id: "Service_cat", label: "Service_cat", minWidth: 170 },
    { id: "Staffname", label: "Staff Name", minWidth: 170 },
    { id: "Status", label: "Status", minWidth: 170 },
  ];
  let ConfirmOrderrows =
    data &&
    data?.map((item) => ({
      BookingID: item?.bookingID,
      BookingAmount: item?.bookingAmount,
      BookingDate: item?.bookingdate,
      Service_cat: item?.service?.service_cat,
      Staffname: item?.staff?.first_name,
    }));
  let TotalOrderrows = data.map((item) => ({
    BookingID: item.bookingID,
    BookingAmount: item.bookingAmount,
    BookingDate: item.bookingdate,
    Service_cat: item.service?.service_cat,
    Staffname: item.staff?.first_name,
    Status: item?.status,
  }));
  let PendingOrderrows = data.map((item) => ({
    BookingID: item.bookingID,
    BookingAmount: item.bookingAmount,
    BookingDate: item.bookingdate,
    Service_cat: item.service?.service_cat,
    Staffname: item.staff?.first_name,
    Status: item?.status,
  }));
  let StaffOrderrows = data.map((item) => ({
    first_name: item.first_name,
    last_name: item.last_name,
  }));
  let Servicesrows = data.map((item) => ({
    service_cat: item.service_cat,
    treatment_type: item.treatment_type,
  }));
  console.log(ConfirmOrderrows);

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {tablename === "Confirm Order" ? (
                ConfirmOrderrows.length > 0 ? (
                  ConfirmOrdercolumns?.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{
                        minWidth: column.minWidth,
                        backgroundColor: "rgb(33, 37, 41)",
                        color: "rgb(255, 255, 255)",
                        borderWidth: " 0 var(--bs-border-width)",
                        fontSize: "20px !important",
                      }}
                      className="header-title text-center Partnertableheading"
                    >
                      {column.label}
                    </TableCell>
                  ))
                ) : (
                  <TableCell
                    style={{
                      backgroundColor: "rgb(33, 37, 41)",
                      color: "rgb(255, 255, 255)",
                      borderWidth: " 0 var(--bs-border-width)",
                      fontSize: "20px !important",
                    }}
                    className="header-title text-center Partnertableheading"
                  >
                    No Data Found
                  </TableCell>
                )
              ) : tablename === "Total Order" ? (
                TotalOrderrows.length > 0 ? (
                  TotalOrdercolumns?.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{
                        minWidth: column.minWidth,
                        backgroundColor: "rgb(33, 37, 41)",
                        color: "rgb(255, 255, 255)",
                        borderWidth: " 0 var(--bs-border-width)",
                        fontSize: "20px !important",
                      }}
                      className="header-title text-center Partnertableheading"
                    >
                      {column.label}
                    </TableCell>
                  ))
                ) : (
                  <TableCell
                    style={{
                      backgroundColor: "rgb(33, 37, 41)",
                      color: "rgb(255, 255, 255)",
                      borderWidth: " 0 var(--bs-border-width)",
                      fontSize: "20px !important",
                    }}
                    className="header-title text-center Partnertableheading"
                  >
                    No Data Found
                  </TableCell>
                )
              ) : tablename === "Pending Order" ? (
                PendingOrderrows.length > 0 ? (
                  PendingOrdercolumns?.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{
                        minWidth: column.minWidth,
                        backgroundColor: "rgb(33, 37, 41)",
                        color: "rgb(255, 255, 255)",
                        borderWidth: " 0 var(--bs-border-width)",
                      }}
                      className="header-title text-center Partnertableheading"
                    >
                      {column.label}
                    </TableCell>
                  ))
                ) : (
                  <TableCell
                    style={{
                      backgroundColor: "rgb(33, 37, 41)",
                      color: "rgb(255, 255, 255)",
                      borderWidth: " 0 var(--bs-border-width)",
                      fontSize: "20px !important",
                    }}
                    className="header-title text-center Partnertableheading"
                  >
                    No Data Found
                  </TableCell>
                )
              ) : tablename === "Staff" ? 
              StaffOrderrows.length>0?
              (
                StaffOrdercolumns?.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{
                      minWidth: column.minWidth,
                      backgroundColor: "rgb(33, 37, 41)",
                      color: "rgb(255, 255, 255)",
                      borderWidth: " 0 var(--bs-border-width)",
                    }}
                    className="header-title text-center Partnertableheading"
                  >
                    {column.label}
                  </TableCell>
                ))
              ):
              <TableCell
                    style={{
                      backgroundColor: "rgb(33, 37, 41)",
                      color: "rgb(255, 255, 255)",
                      borderWidth: " 0 var(--bs-border-width)",
                      fontSize: "20px !important",
                    }}
                    className="header-title text-center Partnertableheading"
                  >
                    No Data Found
                  </TableCell> 
              : tablename === "Services" ? 
              Servicesrows.length>0? (
                Servicescolumns?.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{
                      minWidth: column.minWidth,
                      backgroundColor: "rgb(33, 37, 41)",
                      color: "rgb(255, 255, 255)",
                      borderWidth: " 0 var(--bs-border-width) ",
                    }}
                    className="header-title text-center Partnertableheading"
                  >
                    {column.label}
                  </TableCell>
                ))
              ) :
              <TableCell
                    style={{
                      backgroundColor: "rgb(33, 37, 41)",
                      color: "rgb(255, 255, 255)",
                      borderWidth: " 0 var(--bs-border-width)",
                      fontSize: "20px !important",
                    }}
                    className="header-title text-center Partnertableheading"
                  >
                    No Data Found
                  </TableCell>
              : (
                "No data found"
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {tablename === "Confirm Order"
              ? ConfirmOrderrows.map((row, rowIndex) => (
                  <TableRow
                    key={rowIndex}
                    className={`${
                      rowIndex % 2 === 0 ? "checkcolor" : "bg-[white]"
                    }`}
                  >
                    {ConfirmOrdercolumns.length > 0 ? (
                      ConfirmOrdercolumns.map((column, index) => (
                        <TableCell key={column.id} align="center" style={{borderRight:"2px solid rgb(167 159 159 / 35%)"}}>
                          {row[column.id]}
                        </TableCell>
                      ))
                    ) : (
                      <TableCell>No Data Found</TableCell>
                    )}
                  </TableRow>
                ))
              : tablename === "Total Order"
              ? TotalOrderrows.map((row, rowIndex) => (
                  <TableRow
                    key={rowIndex}
                    className={`${
                      rowIndex % 2 === 0 ? "checkcolor" : "bg-[white]"
                    }`}
                  >
                    {TotalOrdercolumns.map((column) => (
                      <TableCell key={column.id} align="center" style={{borderRight:"2px solid rgb(167 159 159 / 35%)"}}>
                        {row[column.id]}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              : tablename === "Pending Order"
              ? PendingOrderrows.map((row, rowIndex) => (
                  <TableRow
                    key={rowIndex}
                    className={`${
                      rowIndex % 2 === 0 ? "checkcolor" : "bg-[white]"
                    }`}
                  >
                    {TotalOrdercolumns.map((column) => (
                      <TableCell key={column.id} align="center" style={{borderRight:"2px solid rgb(167 159 159 / 35%)"}}>
                        {row[column.id]}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              : tablename === "Staff"
              ? StaffOrderrows.map((row, rowIndex) => (
                  <TableRow
                    key={rowIndex}
                    className={`${
                      rowIndex % 2 === 0 ? "checkcolor" : "bg-[white]"
                    }`}
                  >
                    {StaffOrdercolumns.map((column) => (
                      <TableCell key={column.id} align="center" style={{borderRight:"2px solid rgb(167 159 159 / 35%)"}}>
                        {row[column.id]}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              : tablename === "Services"
              ? Servicesrows.map((row, rowIndex) => (
                  <TableRow
                    key={rowIndex}
                    className={`${
                      rowIndex % 2 === 0 ? "checkcolor" : "bg-[white]"
                    }`}
                  >
                    {Servicescolumns.map((column) => (
                      <TableCell key={column.id} align="center" style={{borderRight:"2px solid rgb(167 159 159 / 35%)"}}>
                        {row[column.id]}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              : "No data found"}
          </TableBody>
        </Table>
      </TableContainer>
      {/* <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      /> */}
    </Paper>
  );
}
