import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Box, Typography, CircularProgress } from '@mui/material';
import { toast, ToastContainer } from 'react-toastify';
import MainHeader from '../../Components/MainHeader';
import Header from '../../Components/Header';
import EditButton from '../../Components/EditButton';
import Copyright from '../../Components/Copyright';
import { Delete, Get, GetById, GetList } from '../../Config/ApiHandling';
import DeleteButton from '../../Components/DeleteButton';
import Swal from 'sweetalert2';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';

const HomeSlider = ({ setCollapsed, collapsed }) => {
    const [data, setData] = useState([]);
    const Icon = <RemoveRedEyeIcon />;
    const [loading, setLoading] = useState(true);
    const [flag, setFlag] = useState(false);
    const token = localStorage.getItem('token');
    const [currentPage, setCurrentPage] = useState(1);
    const startIndex = (currentPage - 1) * 10 + 1;
    const navigation = useNavigate();

    const cols = [
        {
            name: "Sr No",
            key: "index"
        },
        {
            name: "Name",
            key: "name"
        },
        {
            name: "Action",
            displayFeild: (item) => (
                <Box display="flex" justifyContent="center" gap={'10px'}>
                    <Button
                        variant="contained"

                        onClick={() => view(item)}
                        sx={{
                            borderRadius: '2px',
                            height: '32px',
                            backgroundColor: 'rgb(33, 37, 41)', // Dark background color
                            color: '#fff', // White text color
                            '&:hover': {
                                backgroundColor: 'rgb(28, 32, 34)' // Slightly darker on hover
                            }
                        }}
                        startIcon={Icon}
                    >
                    </Button>
                    <Button
                        variant="contained"

                        onClick={() => edit(item)}
                        sx={{
                            borderRadius: '2px',
                            height: '32px',
                            backgroundColor: 'rgb(33, 37, 41)', // Dark background color
                            color: '#fff', // White text color
                            '&:hover': {
                                backgroundColor: 'rgb(28, 32, 34)' // Slightly darker on hover
                            }
                        }}
                    >
                        Edit
                    </Button>
                    <DeleteButton text="Delete" click={() => deleteSlider(item)} />
                    <Button
                        variant="contained"
                        onClick={() => active(item)}
                        sx={{
                            borderRadius: '2px',
                            height: '32px',
                            width: '106px',
                            backgroundColor: item.isActive ? 'green' : '#d32f2f', // Green for active, red for inactive
                            color: '#fff', // White text color
                            '&:hover': {
                                backgroundColor: item.isActive ? '#266426' : '#c62828' // Slightly darker on hover
                            }
                        }}
                    >
                        {item.isActive ? 'Active' : 'UnActive'}
                    </Button>

                </Box>
            ),
        },
    ];

    const fetchData = () => {
        GetList('/admin/gethomeSlider-images', token)
            .then((res) => {
                setData(res.data.data);
                setLoading(false);
            })
            .catch((e) => {
                const errorMessage = e.response?.data?.message || 'An error occurred';
                toast.error(errorMessage);
                setLoading(false);
            });
    };

    useEffect(() => {
        fetchData();
    }, []);
    const active = (item) => {
        Swal.fire({
            title: 'Are you sure?',
            text: `You are about to ${item.isActive ? 'deactivate' : 'activate'} this item.`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, proceed!'
        }).then((result) => {
            if (result.isConfirmed) {
                const action = item.isActive ? 'deactivate' : 'activate';
                GetById(`admin/activeHomeSlider-images`, item._id, token)
                    .then(() => {
                        Swal.fire(
                            `${action.charAt(0).toUpperCase() + action.slice(1)}d!`,
                            `The item has been ${action}d.`,
                            'success'
                        );
                        fetchData(); // Refresh the data after activation or deactivation
                    })
                    .catch((error) => {
                        Swal.fire(
                            'Error!',
                            `There was an error ${action}ing the item.`,
                            'error'
                        );
                    });
            }
        });
    };
    const edit = (item) => {
        navigation(`/dashboard/edithomesliderimages?id=${item._id}`, {
            state: item
        });
    };
    const deleteSlider = (item) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'Cancel'
        }).then((result) => {
            if (result.isConfirmed) {
                // User confirmed, proceed with deletion
                Delete('admin/deletehomeSlider-images', item._id, token)
                    .then(() => {
                        Swal.fire(
                            'Deleted!',
                            'Your slider image has been deleted.',
                            'success'
                        );
                        // Optionally refresh the data or navigate away
                        fetchData(); // Refresh data after deletion
                    })
                    .catch((err) => {
                        Swal.fire(
                            'Error!',
                            'An error occurred while deleting the image.',
                            'error'
                        );
                        const errorMessage = err.response?.data?.message || 'An error occurred';
                        toast.error(errorMessage);
                    });
            }
        });
    };
    const view = (item) => {
        navigation(`/dashboard/edithomesliderimages?viewid=${item._id}`, {
            state: item
        });
    }

    const renderTable = () => {
        if (loading) {
            return (
                <Box display="flex" justifyContent="center" alignItems="center" height="200px">
                    <CircularProgress />
                </Box>
            );
        } else if (data.length === 0) {
            return (
                <Typography variant="body1" align="center" color="textSecondary">
                    No data available
                </Typography>
            );
        } else {
            return (
                <table className="table table-striped table-bordered text-center" style={{ width: '90%', margin: '1%', border: '1px solid #ccc' }}>
                    <thead>
                        <tr>
                            {cols.map((item, index) => (
                                <th className='header-title' style={{ backgroundColor: '#212529', color: '#fff', fontSize: '20px' }} key={index}>{item.name}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((rowData, key) => (
                            <tr key={key} className='table_img tablerow'>
                                {cols.map((col, index) => (
                                    <td style={{ verticalAlign: 'middle' }} key={index}>
                                        {col.displayFeild ? (
                                            col.displayFeild(rowData)
                                        ) : col.key === 'index' ? (
                                            startIndex + key
                                        ) : (
                                            rowData.textOne
                                        )}
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            );
        }
    };

    return (
        <>
            <div>
                <MainHeader setCollapsed={setCollapsed} collapsed={collapsed} />
                <Header screenTitle="Home Slider Images" />
                <Box mb={2}>
                    <div className='mx-3 d-flex justify-content-end' style={{ width: '95%' }}>
                        <Button
                            variant="contained"

                            onClick={() => navigation('/dashboard/addhomesliderimages')}
                            sx={{
                                backgroundColor: 'rgb(33, 37, 41)', // Dark background color
                                color: '#fff', // White text color
                                '&:hover': {
                                    backgroundColor: 'rgb(28, 32, 34)' // Slightly darker on hover
                                }
                            }}
                        >
                            Add New Slider Image
                        </Button>
                    </div>
                </Box>
                <ToastContainer />
                <Box display="flex" justifyContent="center">
                    {renderTable()}
                </Box>
            </div>
            <Copyright />
        </>
    );
};

export default HomeSlider;
