// import React, { useEffect, useState } from 'react'
// import MainHeader from '../../Components/MainHeader'
// import Header from '../../Components/Header'
// import { Box, Button } from '@mui/material'
// import { Typography } from 'antd'
// import BsButton from '../../Components/BsButton'
// import {  Put, imageurl, GetList } from '../../Config/ApiHandling'
// import { useNavigate } from 'react-router-dom'
// import { ToastContainer, toast } from 'react-toastify'
// import DeleteButton from '../../Components/DeleteButton'
// import { Field, Form, Formik } from 'formik';
// import * as Yup from 'yup';
// import Copyright from '../../Components/Copyright'

// const EditDiscover = ({ setCollapsed, collapsed }) => {
//     const [selectedImage, setSelectedImage] = useState(null);
//     // const [data, setData] = useState();
//     const [formValues, setFormValues] = useState({
//         title: '',
//         btn_text: '',
//         btn_text_arb: '',
//         btn_link: '',
//         title_arb: '',
//         images: null,
//         id: ''
//     });
//     let navigation = useNavigate()
//     const token = localStorage.getItem("token")

//     const fetchData = () => {
//         GetList(`getDiscover`, token)
//             .then((res) => {
//                 const discoverData = res.data.data[0];
//                 setFormValues(prevValues => ({
//                     ...prevValues,
//                     title: discoverData?.title || '',
//                     title_arb: discoverData?.title_arb || '',
//                     btn_text: discoverData?.btn_text || '',
//                     btn_text_arb: discoverData?.btn_text_arb || '',
//                     btn_link: discoverData?.btn_link || '',
//                     id: discoverData?._id || '',
//                     images: discoverData?.images || null
//                 }));
//             })
//             .catch((e) => {
//                 const errorMessage = e.response && e.response.data && e.response.data.message
//                     ? e.response.data.message
//                     : 'An error occurred';
//                 toast.error(errorMessage, {
//                     position: "top-right",
//                     autoClose: 4000,
//                     hideProgressBar: false,
//                     closeOnClick: true,
//                     pauseOnHover: true,
//                     draggable: true,
//                     progress: undefined,
//                     theme: "light",
//                 });
//             });
//     };


//     useEffect(() => {
//         fetchData();
//     }, []);

//     const initialValues = {
//         title: formValues?.title || '',
//         title_arb: formValues?.title_arb || '',
//         btn_text: formValues?.btn_text || '',
//         btn_text_arb: formValues?.btn_text_arb || '',
//         btn_link: formValues?.btn_link || '',
//         images: formValues?.images || null,
//     };


//     const validationSchemaDiscover = Yup.object().shape({
//         title: Yup.string()
//             .required('⚠️ Title (English) is required'),
//         title_arb: Yup.string()
//             .required('⚠️ Title (Arabic) is required'),
//         btn_text: Yup.string()
//             .required('⚠️ Button Text (English) is required'),
//         btn_text_arb: Yup.string()
//             .required('⚠️ Button Text (Arabic) is required'),
//         btn_link: Yup.string()
//             .required('⚠️ Button Link is required'),
//         images: Yup.mixed().required("⚠️ Image is Required"),
//     });


//     let sendData = (values) => {
//         const formData = new FormData();
//         formData.append('title', values.title);
//         formData.append('title_arb', values.title_arb);
//         formData.append('btn_text', values.btn_text);
//         formData.append('btn_text_arb', values.btn_text_arb);
//         formData.append('btn_link', values.btn_link);
//         formData.append('images', values.images);


//         Put("updateDiscover", formValues.id, formData, token)
//             .then((res) => {
//                 // setLoading(false);
//                 toast("Discover updated successfully", {
//                     position: "top-right",
//                     autoClose: 3000,
//                     hideProgressBar: false,
//                     closeOnClick: true,
//                     draggable: true,
//                     progress: undefined,
//                 });
//                 setTimeout(() => {
//                     navigation("/dashboard/discover");
//                 }, 1000);
//             })
//             .catch((err) => {
//                 // setLoading(false);
//                 const errorMessage = err.response && err.response.data && err.response.data.message
//                     ? err.response.data.message
//                     : 'An error occurred';

//                 toast(errorMessage, {
//                     position: "top-right",
//                     autoClose: 3000,
//                     hideProgressBar: false,
//                     closeOnClick: true,
//                     draggable: true,
//                     progress: undefined,
//                 });
//             });
//     };


//     const handleFileChange = (e, setFieldValue) => {
//         const file = e.target.files[0];

//         if (file) {
//             const newImageURL = URL.createObjectURL(file);
//             setSelectedImage(newImageURL);
//             setFieldValue('images', file);
//             setFormValues({ ...formValues, images: file });
//         } else {
//             setSelectedImage(null);
//             setFieldValue('images', null);
//             setFormValues({ ...formValues, images: null });
//         }
//     };


//     return (
//         <div>
//             <MainHeader setCollapsed={setCollapsed} collapsed={collapsed} />
//             <Header screenTitle={"Edit Discover"} buttonList={[
//                 {
//                     displayField: () => (
//                         <DeleteButton click={() => navigation("/dashboard/discover")} text="Cancel" />
//                     )
//                 }
//             ]} />
//             <Box sx={{ width: { md: '98%', sm: '325px', xs: '100%' } }} className=' shadow m-auto mt-2'>
//                 <Box >
//                     <ToastContainer />
//                     <Formik
//                         initialValues={initialValues}
//                         validationSchema={validationSchemaDiscover}
//                         onSubmit={(values) => sendData(values)}
//                         validateOnChange
//                         enableReinitialize={true}
//                     >
//                         {({ handleSubmit, setFieldValue, touched, errors }) => (
//                             <Form onSubmit={handleSubmit}>

//                                 <Box className='row p-3'>
//                                     <div className="col-md-6">
//                                         <Typography variant='p'> Title (Eng) : </Typography>
//                                         <div className='mt-2'>
//                                             <Field
//                                                 className="form-control w-100"
//                                                 type="text"
//                                                 name="title"
//                                                 value={formValues?.title || ""}
//                                                 onChange={(e) => {
//                                                     setFieldValue('title', e.target.value);
//                                                     setFormValues({ ...formValues, title: e.target.value });
//                                                 }}
//                                             />
//                                             {touched.title && errors.title ? (
//                                                 <div className="error text-danger">{errors.title}</div>
//                                             ) : null}
//                                         </div>
//                                     </div>
//                                     <div className="col-md-6">
//                                         <Typography variant='p'> Title (Arb) : </Typography>
//                                         <div className='mt-2'>
//                                             <Field
//                                                 className="form-control w-100"
//                                                 type="text"
//                                                 name="title_arb"
//                                                 value={formValues?.title_arb || ""}
//                                                 onChange={(e) => {
//                                                     setFieldValue('title_arb', e.target.value);
//                                                     setFormValues({ ...formValues, title_arb: e.target.value });
//                                                 }}
//                                             />
//                                             {touched.title_arb && errors.title_arb ? (
//                                                 <div className="error text-danger">{errors.title_arb}</div>
//                                             ) : null}
//                                         </div>
//                                     </div>
//                                     <div className="col-md-6">
//                                         <Typography variant='p'> Button Text (Eng) : </Typography>
//                                         <div className='mt-2'>
//                                             <Field
//                                                 className="form-control w-100"
//                                                 type="text"
//                                                 name="btn_text"
//                                                 value={formValues?.btn_text || ""}
//                                                 onChange={(e) => {
//                                                     setFieldValue('btn_text', e.target.value);
//                                                     setFormValues({ ...formValues, btn_text: e.target.value });
//                                                 }}
//                                             />
//                                             {touched.btn_text && errors.btn_text ? (
//                                                 <div className="error text-danger">{errors.btn_text}</div>
//                                             ) : null}
//                                         </div>
//                                     </div>
//                                     <div className="col-md-6">
//                                         <Typography variant='p'> Button Text (Arb) : </Typography>
//                                         <div className='mt-2'>
//                                             <Field
//                                                 className="form-control w-100"
//                                                 type="text"
//                                                 name="btn_text_arb"
//                                                 value={formValues?.btn_text_arb || ""}
//                                                 onChange={(e) => {
//                                                     setFieldValue('btn_text_arb', e.target.value);
//                                                     setFormValues({ ...formValues, btn_text_arb: e.target.value });
//                                                 }}
//                                             />
//                                             {touched.btn_text_arb && errors.btn_text_arb ? (
//                                                 <div className="error text-danger">{errors.btn_text_arb}</div>
//                                             ) : null}
//                                         </div>
//                                     </div>
//                                     <div className="col-md-6">
//                                         <Typography variant='p'> Button Link : </Typography>
//                                         <div className='mt-2'>
//                                             <Field
//                                                 className="form-control w-100"
//                                                 type="text"
//                                                 name="btn_link"
//                                                 value={formValues?.btn_link || ""}
//                                                 onChange={(e) => {
//                                                     setFieldValue('btn_link', e.target.value);
//                                                     setFormValues({ ...formValues, btn_link: e.target.value });
//                                                 }}
//                                             />
//                                             {touched.btn_link && errors.btn_link ? (
//                                                 <div className="error text-danger">{errors.btn_link}</div>
//                                             ) : null}
//                                         </div>
//                                     </div>
//                                     <div className="col-md-6">
//                                         <Typography variant='p'> Image : </Typography>
//                                         <div className='mt-2'>
//                                             <input
//                                                 type="file"
//                                                 id="fileInput"
//                                                 style={{ display: 'none' }}
//                                                 accept=".jpg, .jpeg, .png"
//                                                 onChange={(e) => handleFileChange(e, setFieldValue)}
//                                             />

//                                             <Button
//                                                 component="label"
//                                                 className="form-control w-100"
//                                                 htmlFor="fileInput"
//                                             >
//                                                 Select Image
//                                             </Button>
//                                             {formValues?.images && (
//                                                 <div>
//                                                     {formValues?.images instanceof File ? (
//                                                         <img
//                                                             src={selectedImage}
//                                                             alt="Selected"
//                                                             style={{ width: '100px', marginTop: '10px' }}
//                                                         />
//                                                     ) : (
//                                                         <img
//                                                             src={`${imageurl(formValues.images).imageURL}`}
//                                                             style={{ width: '100px', marginTop: '10px' }}
//                                                             alt=''
//                                                         />
//                                                     )}
//                                                 </div>
//                                             )}
//                                             {touched.images && errors.images ? (
//                                                 <div className="error text-danger">{errors.images}</div>
//                                             ) : null}
//                                         </div>
//                                     </div>
//                                     <Box sx={{ display: 'flex', justifyContent: 'start' }} className='  py-3'>
//                                         <BsButton text={"Update"} onClick={handleSubmit} type="submit" />
//                                     </Box>
//                                 </Box>
//                             </Form>
//                         )}
//                     </Formik>
//                 </Box >
//             </Box >
//             <Copyright />
//         </div >
//     )

// }

// export default EditDiscover


import React, { useEffect, useState, useCallback } from 'react';
import MainHeader from '../../Components/MainHeader';
import Header from '../../Components/Header';
import { Box, Button } from '@mui/material';
import { Typography } from 'antd';
import BsButton from '../../Components/BsButton';
import { Put, imageurl, GetList } from '../../Config/ApiHandling';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import DeleteButton from '../../Components/DeleteButton';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import Copyright from '../../Components/Copyright';

const EditDiscover = ({ setCollapsed, collapsed }) => {
    const [selectedImage, setSelectedImage] = useState(null);
    const [formValues, setFormValues] = useState({
        title: '',
        btn_text: '',
        btn_text_arb: '',
        btn_link: '',
        title_arb: '',
        images: null,
        id: ''
    });
    let navigation = useNavigate();
    const token = localStorage.getItem("token");

    const fetchData = useCallback(() => {
        GetList(`getDiscover`, token)
            .then((res) => {
                const discoverData = res.data.data[0];
                setFormValues(prevValues => ({
                    ...prevValues,
                    title: discoverData?.title || '',
                    title_arb: discoverData?.title_arb || '',
                    btn_text: discoverData?.btn_text || '',
                    btn_text_arb: discoverData?.btn_text_arb || '',
                    btn_link: discoverData?.btn_link || '',
                    id: discoverData?._id || '',
                    images: discoverData?.images || null
                }));
            })
            .catch((e) => {
                const errorMessage = e.response && e.response.data && e.response.data.message
                    ? e.response.data.message
                    : 'An error occurred';
                toast.error(errorMessage, {
                    position: "top-right",
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            });
    }, [token]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const initialValues = {
        title: formValues?.title || '',
        title_arb: formValues?.title_arb || '',
        btn_text: formValues?.btn_text || '',
        btn_text_arb: formValues?.btn_text_arb || '',
        btn_link: formValues?.btn_link || '',
        images: formValues?.images || null,
    };

    const validationSchemaDiscover = Yup.object().shape({
        title: Yup.string()
            .required('⚠️ Title (English) is required'),
        title_arb: Yup.string()
            .required('⚠️ Title (Arabic) is required'),
        btn_text: Yup.string()
            .required('⚠️ Button Text (English) is required'),
        btn_text_arb: Yup.string()
            .required('⚠️ Button Text (Arabic) is required'),
        btn_link: Yup.string()
            .required('⚠️ Button Link is required'),
        images: Yup.mixed().required("⚠️ Image is Required"),
    });

    let sendData = (values) => {
        const formData = new FormData();
        formData.append('title', values.title);
        formData.append('title_arb', values.title_arb);
        formData.append('btn_text', values.btn_text);
        formData.append('btn_text_arb', values.btn_text_arb);
        formData.append('btn_link', values.btn_link);
        formData.append('images', values.images);

        Put("updateDiscover", formValues.id, formData, token)
            .then((res) => {
                toast("Discover updated successfully", {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    draggable: true,
                    progress: undefined,
                });
                setTimeout(() => {
                    navigation("/dashboard/discover");
                }, 1000);
            })
            .catch((err) => {
                const errorMessage = err.response && err.response.data && err.response.data.message
                    ? err.response.data.message
                    : 'An error occurred';

                toast(errorMessage, {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    draggable: true,
                    progress: undefined,
                });
            });
    };

    const handleFileChange = (e, setFieldValue) => {
        const file = e.target.files[0];

        if (file) {
            const newImageURL = URL.createObjectURL(file);
            setSelectedImage(newImageURL);
            setFieldValue('images', file);
            setFormValues({ ...formValues, images: file });
        } else {
            setSelectedImage(null);
            setFieldValue('images', null);
            setFormValues({ ...formValues, images: null });
        }
    };

    return (
        <div>
            <MainHeader setCollapsed={setCollapsed} collapsed={collapsed} />
            <Header screenTitle={"Edit Discover"} buttonList={[
                {
                    displayField: () => (
                        <DeleteButton click={() => navigation("/dashboard/discover")} text="Cancel" />
                    )
                }
            ]} />
            <Box sx={{ width: { md: '98%', sm: '325px', xs: '100%' } }} className=' shadow m-auto mt-2'>
                <Box>
                    <ToastContainer />
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchemaDiscover}
                        onSubmit={(values) => sendData(values)}
                        validateOnChange
                        enableReinitialize={true}
                    >
                        {({ handleSubmit, setFieldValue, touched, errors }) => (
                            <Form onSubmit={handleSubmit}>
                                <Box className='row p-3'>
                                    <div className="col-md-6">
                                        <Typography variant='p'> Title (Eng) : </Typography>
                                        <div className='mt-2'>
                                            <Field
                                                className="form-control w-100"
                                                type="text"
                                                name="title"
                                                value={formValues?.title || ""}
                                                onChange={(e) => {
                                                    setFieldValue('title', e.target.value);
                                                    setFormValues({ ...formValues, title: e.target.value });
                                                }}
                                            />
                                            {touched.title && errors.title ? (
                                                <div className="error text-danger">{errors.title}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <Typography variant='p'> Title (Arb) : </Typography>
                                        <div className='mt-2'>
                                            <Field
                                                className="form-control w-100"
                                                type="text"
                                                name="title_arb"
                                                value={formValues?.title_arb || ""}
                                                onChange={(e) => {
                                                    setFieldValue('title_arb', e.target.value);
                                                    setFormValues({ ...formValues, title_arb: e.target.value });
                                                }}
                                            />
                                            {touched.title_arb && errors.title_arb ? (
                                                <div className="error text-danger">{errors.title_arb}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <Typography variant='p'> Button Text (Eng) : </Typography>
                                        <div className='mt-2'>
                                            <Field
                                                className="form-control w-100"
                                                type="text"
                                                name="btn_text"
                                                value={formValues?.btn_text || ""}
                                                onChange={(e) => {
                                                    setFieldValue('btn_text', e.target.value);
                                                    setFormValues({ ...formValues, btn_text: e.target.value });
                                                }}
                                            />
                                            {touched.btn_text && errors.btn_text ? (
                                                <div className="error text-danger">{errors.btn_text}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <Typography variant='p'> Button Text (Arb) : </Typography>
                                        <div className='mt-2'>
                                            <Field
                                                className="form-control w-100"
                                                type="text"
                                                name="btn_text_arb"
                                                value={formValues?.btn_text_arb || ""}
                                                onChange={(e) => {
                                                    setFieldValue('btn_text_arb', e.target.value);
                                                    setFormValues({ ...formValues, btn_text_arb: e.target.value });
                                                }}
                                            />
                                            {touched.btn_text_arb && errors.btn_text_arb ? (
                                                <div className="error text-danger">{errors.btn_text_arb}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <Typography variant='p'> Button Link : </Typography>
                                        <div className='mt-2'>
                                            <Field
                                                className="form-control w-100"
                                                type="text"
                                                name="btn_link"
                                                value={formValues?.btn_link || ""}
                                                onChange={(e) => {
                                                    setFieldValue('btn_link', e.target.value);
                                                    setFormValues({ ...formValues, btn_link: e.target.value });
                                                }}
                                            />
                                            {touched.btn_link && errors.btn_link ? (
                                                <div className="error text-danger">{errors.btn_link}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <Typography variant='p'> Image : </Typography>
                                        <div className='mt-2'>
                                            <input
                                                type="file"
                                                id="fileInput"
                                                style={{ display: 'none' }}
                                                accept=".jpg, .jpeg, .png"
                                                onChange={(e) => handleFileChange(e, setFieldValue)}
                                            />
                                            <Button
                                                component="label"
                                                className="form-control w-100"
                                                htmlFor="fileInput"
                                            >
                                                Select Image
                                            </Button>
                                            {formValues?.images && (
                                                <div>
                                                    {formValues?.images instanceof File ? (
                                                        <img
                                                            src={selectedImage}
                                                            alt="Selected"
                                                            style={{ width: '100px', marginTop: '10px' }}
                                                        />
                                                    ) : (
                                                        <img
                                                            src={`${imageurl(formValues.images).imageURL}`}
                                                            style={{ width: '100px', marginTop: '10px' }}
                                                            alt=''
                                                        />
                                                    )}
                                                </div>
                                            )}
                                            {touched.images && errors.images ? (
                                                <div className="error text-danger">{errors.images}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <Box sx={{ display: 'flex', justifyContent: 'start' }} className='py-3'>
                                        <BsButton text={"Update"} onClick={handleSubmit} type="submit" />
                                    </Box>
                                </Box>
                            </Form>
                        )}
                    </Formik>
                </Box>
            </Box>
            <Copyright />
        </div>
    );
};

export default EditDiscover;
