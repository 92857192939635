import React, { useState, useEffect, useMemo } from 'react';
import { Layout, Menu } from 'antd';
import MainIcon from "../assets/BeautyParlour.png"
import { DashboardOutlined } from '@ant-design/icons';
import { BiSliderAlt } from "react-icons/bi";
import { TfiWorld } from "react-icons/tfi";
import { FaMoneyBillWave } from "react-icons/fa6";
import { RiAdminLine, RiPagesLine } from "react-icons/ri";
import { TbCategory, TbCategoryFilled } from "react-icons/tb";
import { FaRegListAlt, FaRegUser, FaQuestionCircle, FaClipboardList  } from "react-icons/fa";
import { MdSubscriptions, MdOutlineLocalOffer, MdDesignServices } from "react-icons/md";
import { FaRegNoteSticky } from "react-icons/fa6";
import { GrContactInfo } from "react-icons/gr";
import { IoIosPeople } from "react-icons/io";
import { RxScissors } from "react-icons/rx";
import { PiUserListBold, PiLinkSimpleBold } from "react-icons/pi";
import { IoPeopleOutline } from "react-icons/io5";
import { BiSolidOffer } from "react-icons/bi";
import { BsClipboard2Check } from "react-icons/bs";
import { Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import AdminList from '../DashboardScreen/Admin/AdminList';
import AdminDetails from '../DashboardScreen/Profile/AdminDetails'
import EditAdmin from '../DashboardScreen/Admin/EditAdmin';
import UserList from '../DashboardScreen/User/UserList';
import PageList from '../DashboardScreen/Pages/PageList';
import CartegoryList from '../DashboardScreen/Category/CategoryList'
import EditCategory from '../DashboardScreen/Category/EditCategory'
import EditPage from '../DashboardScreen/Pages/EditPage';
import PartnerList from '../DashboardScreen/Partner/PartnerList';
import TeamList from '../DashboardScreen/Team/TeamList';
import { MdOutlineSettings } from "react-icons/md";
import EditTeam from '../DashboardScreen/Team/EditTeam';
import ContactList from '../DashboardScreen/Contact/ContactList';
import FAQsList from '../DashboardScreen/FAQ/FAQsList';
import EditFAQs from '../DashboardScreen/FAQ/EditFAQs';
import SubMainDashboard from '../DashboardScreen/SubMainDashboard';
import CreateAdmin from '../DashboardScreen/Admin/CreateAdmin';
import TestimonialList from '../DashboardScreen/Testinomials/TestinomialList';
import EditTestinomial from '../DashboardScreen/Testinomials/EditTestinomial';
import SubscriptionList from '../DashboardScreen/Subscription/SubscriptionList';
import EditSubscription from '../DashboardScreen/Subscription/EditSubscription';
import SaloonList from '../DashboardScreen/Saloon/SaloonList';
import ServiceList from '../DashboardScreen/Service/ServiceList';
import PromoList from '../DashboardScreen/Promo/PromoList'
import CashBack from '../DashboardScreen/Promo/CreateCashBack'
import ClientList from '../DashboardScreen/Client/ClientList'
import EditClient from '../DashboardScreen/Client/EditClient'
import SocialLinks from '../DashboardScreen/Links/SocialLinks'
import EditSocialLinks from '../DashboardScreen/Links/EditSocialLinks'
import AppLinks from '../DashboardScreen/Links/AppLinksList';
import EditAppLinks from '../DashboardScreen/Links/EditAppLinks';
import Footer from '../DashboardScreen/Footer/Footer'
import EditFooter from '../DashboardScreen/Footer/EditFooter'
import Discover from '../DashboardScreen/Discover/Discover'
import EditDiscover from '../DashboardScreen/Discover/EditDiscover'
import PartnerDetail from '../DashboardScreen/Partner/PartnerDetail';
import EditDetail from '../DashboardScreen/Saloon/EditDetail';
import HomeSlider from '../DashboardScreen/HomeSlider/HomeSlider';
import EditHomeSlider from '../DashboardScreen/HomeSlider/EditHomeSlider';
import FinancialReport from '../DashboardScreen/FinancialReport/FinancialReport';
import BookingsList from '../DashboardScreen/Bookings/BookingsList';

const { Sider } = Layout;

const MainDashboard = () => {
    const navig = useNavigate();
    const location = useLocation();
    const [openKeys, setOpenKeys] = useState(['dashboard']);
    const [selectedKeys, setSelectedKeys] = useState(['dashboard']);
    const [collapsed, setCollapsed] = useState(false);
    const [buttonClickOpen, setButtonClickOpen] = useState(false);

    let nav = useMemo(() => [

        {
            mainitem: {
                name: "Admin",
                icon: <RiAdminLine />
            },
            item1: {
                name: "Admin List",
                key: "/dashboard/adminlist",
                icon1: <FaClipboardList />
            }
        },
        {
            mainitem: {
                name: "User",
                icon: <FaRegUser />
            },
            item1: {
                name: "User List",
                key: "/dashboard/userlist",
                icon1: <PiUserListBold />
            }
        },
        {
            mainitem: {
                name: "Partners",
                icon: <IoPeopleOutline />
            },
            item1: {
                name: "Partner List",
                key: "/dashboard/partnerlist",
                icon1: <IoPeopleOutline />
            }
        },
        {
            mainitem: {
                name: "Financial Report",
                icon: <FaMoneyBillWave />
            },
            item1: {
                name: "Financial Report",
                key: "/dashboard/financialreport",
                icon1: <FaMoneyBillWave />

            }
        },
        {
            mainitem: {
                name: "Client",
                icon: <BsClipboard2Check />
            },
            item1: {
                name: "Client List",
                key: "/dashboard/clientlist",
                icon1: <BsClipboard2Check />
            }
        },
        {
            mainitem: {
                name: "Saloon",
                icon: <RxScissors />
            },
            item1: {
                name: "Saloon List",
                key: "/dashboard/saloonlist",
                icon1: <RxScissors />
            }
        },
        {
            mainitem: {
                name: "Bookings",
                icon: <FaClipboardList />
            },
            item1: {
                name: "Bookings List",
                key: "/dashboard/bookingslist",
                icon1: <FaClipboardList />
            }
        },
        {
            mainitem: {
                name: "Service",
                icon: <MdDesignServices />
            },
            item1: {
                name: "Service List",
                key: "/dashboard/servicelist",
                icon1: <MdDesignServices />
            }
        },
        {
            mainitem: {
                name: "Promo",
                icon: <MdOutlineLocalOffer />
            },
            item1: {
                name: "Promo List",
                key: "/dashboard/promolist",
                icon1: <BiSolidOffer />
            }
        },
        {
            mainitem: {
                name: "Category",
                icon: <TbCategory />
            },
            item1: {
                name: "Category List",
                key: "/dashboard/categorylist",
                icon1: <TbCategoryFilled />
            }
        },
        {
            mainitem: {
                name: "Page",
                icon: <RiPagesLine />
            },
            item1: {
                name: "Page List",
                key: "/dashboard/pagelist",
                icon1: <FaRegListAlt />
            }
        },

        // {
        //     mainitem: {
        //         name: "Teams",
        //         icon: <IoIosPeople />
        //     },
        //     item1: {
        //         name: "Teams List",
        //         key: "/dashboard/teamlist",
        //         icon1: <IoIosPeople />
        //     }
        // },
        // {
        //     mainitem: {
        //         name: "Contact",
        //         icon: <GrContactInfo />
        //     },
        //     item1: {
        //         name: "Contact List",
        //         key: "/dashboard/contactlist",
        //         icon1: <GrContactInfo />
        //     }
        // },
        // {
        //     mainitem: {
        //         name: "Faq",
        //         icon: <FaQuestionCircle />
        //     },
        //     item1: {
        //         name: "Faqs List",
        //         key: "/dashboard/faqlist",
        //         icon1: <FaQuestionCircle />
        //     }
        // },
        // {
        //     mainitem: {
        //         name: "Discover",
        //         icon: <TfiWorld />
        //     },
        //     item1: {
        //         name: "Discover Page",
        //         key: "/dashboard/discover",
        //         icon1: <TfiWorld />
        //     }
        // },
        // {
        //     mainitem: {
        //         name: "Testinomials",
        //         icon: <FaRegNoteSticky />
        //     },
        //     item1: {
        //         name: "Testimonials List",
        //         key: "/dashboard/testinomiallist",
        //         icon1: <FaRegNoteSticky />
        //     }
        // },
        // {
        //     mainitem: {
        //         name: "Subscription",
        //         icon: <MdSubscriptions />
        //     },
        //     item1: {
        //         name: "Subscription List",
        //         key: "/dashboard/subscriptionlist",
        //         icon1: <MdSubscriptions />
        //     }
        // },
        // {
        //     mainitem: {
        //         name: "Footer",
        //         icon: <PiLinkSimpleBold />
        //     },
        //     item1: {
        //         name: "Footer",
        //         key: "/dashboard/footer",
        //         icon1: <PiLinkSimpleBold />
        //     }
        // },
        // {
        //     mainitem: {
        //         name: "Links",
        //         icon: <PiLinkSimpleBold />
        //     },
        // },
        {
            mainitem: {
                name: "Customization",
                icon: <MdOutlineSettings />
            },
            item1: {
                name: "Testimonials List",
                key: "/dashboard/testinomiallist",
                icon1: <FaRegNoteSticky />
            },
            item2: {
                name: "Social Links",
                key: "/dashboard/sociallinkslist",
                icon2: <PiLinkSimpleBold />
            },
            item3: {
                name: "App Links",
                key: "/dashboard/applinkslist",
                icon3: <PiLinkSimpleBold />
            },
            item4: {
                name: "Footer",
                key: "/dashboard/footer",
                icon4: <PiLinkSimpleBold />
            },
            item5: {
                name: "Faqs List",
                key: "/dashboard/faqlist",
                icon5: <FaQuestionCircle />
            },
            item6: {
                name: "Subscription List",
                key: "/dashboard/subscriptionlist",
                icon6: <MdSubscriptions />
            },
            item7: {
                name: "Discover Page",
                key: "/dashboard/discover",
                icon7: <TfiWorld />
            },
            item8: {
                name: "Teams List",
                key: "/dashboard/teamlist",
                icon8: <IoIosPeople />
            },
            item9: {
                name: "Contact List",
                key: "/dashboard/contactlist",
                icon9: <GrContactInfo />
            },
            item10: {
                name: "Home Slider",
                key: "/dashboard/homeslider",
                icon10: <BiSliderAlt />
            }
        }
    ], []);

    const toggleCollapsed = () => {
        setButtonClickOpen(!buttonClickOpen);
        setCollapsed(!collapsed);
    };

    const navItem = (key) => {
        setSelectedKeys([key]);
        navig(key);
    };


    const handleOpenChange = (keys) => {
        const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
        setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    };

    useEffect(() => {
        const currentKey = location.pathname;
        setSelectedKeys([currentKey]);
        const parentKey = nav.find((item) =>
            item.item1.key || item.item2.key === currentKey || (item.item1 && item.item1.key && item.item2 && item.item2.key === currentKey)
        )?.mainitem.key;
        if (parentKey) {
            setOpenKeys([parentKey]);
        } else {
            setOpenKeys([]);
        }
    }, [location.pathname, nav]);


    return (
        <div style={{ display: 'flex', flexDirection: 'column', minHeight: '145vh', minWidth: "100vh" }}>
            <Layout style={{ flex: 1 }} >
                <Sider
                    width={collapsed ? 60 : 250}
                    collapsed={collapsed}
                    onCollapse={toggleCollapsed}
                    style={{
                        backgroundColor: '#DADADA',
                        minHeight: '100vh',
                        overflowY: 'auto',
                    }}
                    theme="dark"
                >
                    <div className="logo" style={{ display: "flex", justifyContent: "center" }} onClick={() => navItem('')}>
                        <img src={MainIcon} alt="" width={80} height={170} />
                    </div>
                    <Menu
                        style={{ backgroundColor: "#DADADA", color: "#000", fontSize: "20px" }}
                        mode="inline"
                        openKeys={openKeys}
                        onOpenChange={handleOpenChange}
                        selectedkeys={selectedKeys}
                        collapsed={collapsed ? "true" : "false"}
                    >
                        <Menu.Item
                            onClick={() => navItem('')}
                            key="dashboard"
                            className='dashboard'
                            icon={<DashboardOutlined />}
                            selectedkeys={selectedKeys}
                            style={{
                                backgroundColor: selectedKeys.includes('dashboard') ? "#000" : "#DADADA",
                                color: selectedKeys.includes('dashboard') ? "#000" : "#000",
                            }}
                        >
                            {"Dashboard"}
                        </Menu.Item>

                        {nav.map((item, index) => (
                            <Menu.SubMenu
                                key={`submenu-${index}`}
                                icon={item.mainitem.icon}
                                className='dashboard'
                                title={!collapsed && item.mainitem.name}
                                style={{
                                    backgroundColor: openKeys.includes(item.mainitem.key) ? "#000" : "#DADADA",
                                    color: selectedKeys.includes(item.mainitem.key) ? "#FFF" : "#000",
                                }}
                            >
                                <Menu.Item
                                    key={item.item1.key}
                                    icon={item.item1.icon1}
                                    className='dashboard'
                                    onClick={() => navItem(item.item1.key)}
                                    style={{
                                        backgroundColor: selectedKeys.includes(item.item1.key) ? "#000" : "#DADADA",
                                        color: selectedKeys.includes(item.item1.key) ? "#FFF" : "#000",
                                        fontSize: selectedKeys.includes(item.item1.key) ? "17px" : "17px"
                                    }}
                                >
                                    {item.item1.name}
                                </Menu.Item>
                                {item.item2 && (  // Render item2 only if it exists
                                    <Menu.Item
                                        key={item.item2.key}
                                        icon={item.item2.icon2}
                                        className='dashboard'
                                        onClick={() => navItem(item.item2.key)}
                                        style={{
                                            backgroundColor: selectedKeys.includes(item.item2.key) ? "#000" : "#DADADA",
                                            color: selectedKeys.includes(item.item2.key) ? "#FFF" : "#000",
                                            fontSize: selectedKeys.includes(item.item2.key) ? "17px" : "17px"
                                        }}
                                    >
                                        {item.item2.name}
                                    </Menu.Item>
                                )}
                                {item.item3 && (  // Render item2 only if it exists
                                    <Menu.Item
                                        key={item.item3.key}
                                        icon={item.item3.icon3}
                                        className='dashboard'
                                        onClick={() => navItem(item.item3.key)}
                                        style={{
                                            backgroundColor: selectedKeys.includes(item.item3.key) ? "#000" : "#DADADA",
                                            color: selectedKeys.includes(item.item3.key) ? "#FFF" : "#000",
                                            fontSize: selectedKeys.includes(item.item3.key) ? "17px" : "17px"
                                        }}
                                    >
                                        {item.item3.name}
                                    </Menu.Item>
                                )}
                                {item.item4 && (  // Render item4 only if it exists
                                    <Menu.Item
                                        key={item.item4.key}
                                        icon={item.item4.icon4}
                                        className='dashboard'
                                        onClick={() => navItem(item.item4.key)}
                                        style={{
                                            backgroundColor: selectedKeys.includes(item.item4.key) ? "#000" : "#DADADA",
                                            color: selectedKeys.includes(item.item4.key) ? "#FFF" : "#000",
                                            fontSize: selectedKeys.includes(item.item4.key) ? "17px" : "17px"
                                        }}
                                    >
                                        {item.item4.name}
                                    </Menu.Item>
                                )}
                                {item.item5 && (  // Render item5 only if it exists
                                    <Menu.Item
                                        key={item.item5.key}
                                        icon={item.item5.icon5}
                                        className='dashboard'
                                        onClick={() => navItem(item.item5.key)}
                                        style={{
                                            backgroundColor: selectedKeys.includes(item.item5.key) ? "#000" : "#DADADA",
                                            color: selectedKeys.includes(item.item5.key) ? "#FFF" : "#000",
                                            fontSize: selectedKeys.includes(item.item5.key) ? "17px" : "17px"
                                        }}
                                    >
                                        {item.item5.name}
                                    </Menu.Item>
                                )}
                                {item.item6 && (  // Render item6 only if it exists
                                    <Menu.Item
                                        key={item.item6.key}
                                        icon={item.item6.icon6}
                                        className='dashboard'
                                        onClick={() => navItem(item.item6.key)}
                                        style={{
                                            backgroundColor: selectedKeys.includes(item.item6.key) ? "#000" : "#DADADA",
                                            color: selectedKeys.includes(item.item6.key) ? "#FFF" : "#000",
                                            fontSize: selectedKeys.includes(item.item6.key) ? "17px" : "17px"
                                        }}
                                    >
                                        {item.item6.name}
                                    </Menu.Item>
                                )}
                                {item.item7 && (  // Render item7 only if it exists
                                    <Menu.Item
                                        key={item.item7.key}
                                        icon={item.item7.icon7}
                                        className='dashboard'
                                        onClick={() => navItem(item.item7.key)}
                                        style={{
                                            backgroundColor: selectedKeys.includes(item.item7.key) ? "#000" : "#DADADA",
                                            color: selectedKeys.includes(item.item7.key) ? "#FFF" : "#000",
                                            fontSize: selectedKeys.includes(item.item7.key) ? "17px" : "17px"
                                        }}
                                    >
                                        {item.item7.name}
                                    </Menu.Item>
                                )}
                                {item.item8 && (  // Render item8 only if it exists
                                    <Menu.Item
                                        key={item.item8.key}
                                        icon={item.item8.icon8}
                                        className='dashboard'
                                        onClick={() => navItem(item.item8.key)}
                                        style={{
                                            backgroundColor: selectedKeys.includes(item.item8.key) ? "#000" : "#DADADA",
                                            color: selectedKeys.includes(item.item8.key) ? "#FFF" : "#000",
                                            fontSize: selectedKeys.includes(item.item8.key) ? "17px" : "17px"
                                        }}
                                    >
                                        {item.item8.name}
                                    </Menu.Item>
                                )}
                                {item.item9 && (  // Render item9 only if it exists
                                    <Menu.Item
                                        key={item.item9.key}
                                        icon={item.item9.icon9}
                                        className='dashboard'
                                        onClick={() => navItem(item.item9.key)}
                                        style={{
                                            backgroundColor: selectedKeys.includes(item.item9.key) ? "#000" : "#DADADA",
                                            color: selectedKeys.includes(item.item9.key) ? "#FFF" : "#000",
                                            fontSize: selectedKeys.includes(item.item9.key) ? "17px" : "17px"
                                        }}
                                    >
                                        {item.item9.name}
                                    </Menu.Item>
                                )}
                                {item.item10 && (  // Render item9 only if it exists
                                    <Menu.Item
                                        key={item.item10.key}
                                        icon={item.item10.icon10}
                                        className='dashboard'
                                        onClick={() => navItem(item.item10.key)}
                                        style={{
                                            backgroundColor: selectedKeys.includes(item.item10.key) ? "#000" : "#DADADA",
                                            color: selectedKeys.includes(item.item10.key) ? "#FFF" : "#000",
                                            fontSize: selectedKeys.includes(item.item10.key) ? "17px" : "17px"
                                        }}
                                    >
                                        {item.item10.name}
                                    </Menu.Item>
                                )}
                            </Menu.SubMenu>
                        ))}
                    </Menu>
                </Sider>
                <Layout>
                    <Routes>
                        <Route path='/' element={<SubMainDashboard setCollapsed={setCollapsed} collapsed={collapsed} />} />

                        <Route path='admindetails/:id' element={<AdminDetails setCollapsed={setCollapsed} collapsed={collapsed} />} />

                        <Route path='adminlist' element={<AdminList setCollapsed={setCollapsed} collapsed={collapsed} />} />
                        <Route path='editadmin/:id' element={<EditAdmin setCollapsed={setCollapsed} collapsed={collapsed} />} />
                        <Route path='createadmin' element={<CreateAdmin setCollapsed={setCollapsed} collapsed={collapsed} />} />

                        <Route path='userlist' element={<UserList setCollapsed={setCollapsed} collapsed={collapsed} />} />

                        <Route path='clientlist' element={<ClientList setCollapsed={setCollapsed} collapsed={collapsed} />} />
                        <Route path='editclient/:id' element={<EditClient setCollapsed={setCollapsed} collapsed={collapsed} />} />
                        <Route path='createclient' element={<EditClient setCollapsed={setCollapsed} collapsed={collapsed} />} />

                        <Route path='saloonlist' element={<SaloonList setCollapsed={setCollapsed} collapsed={collapsed} />} />
                        <Route path='EditPartner' element={<EditDetail setCollapsed={setCollapsed} collapsed={collapsed} />} />

                        <Route path='servicelist' element={<ServiceList setCollapsed={setCollapsed} collapsed={collapsed} />} />

                        <Route path='promolist' element={<PromoList setCollapsed={setCollapsed} collapsed={collapsed} />} />
                        <Route path='createcashback' element={<CashBack setCollapsed={setCollapsed} collapsed={collapsed} />} />

                        <Route path='pagelist' element={<PageList setCollapsed={setCollapsed} collapsed={collapsed} />} />
                        <Route path='editpage/:id' element={<EditPage setCollapsed={setCollapsed} collapsed={collapsed} />} />

                        <Route path='categorylist' element={<CartegoryList setCollapsed={setCollapsed} collapsed={collapsed} />} />
                        <Route path='createcategory' element={<EditCategory setCollapsed={setCollapsed} collapsed={collapsed} />} />
                        <Route path='editCategory/:id' element={<EditCategory setCollapsed={setCollapsed} collapsed={collapsed} />} />


                        <Route path='partnerlist' element={<PartnerList setCollapsed={setCollapsed} collapsed={collapsed} />} />
                        <Route path='partnerdetail/:id' element={<PartnerDetail setCollapsed={setCollapsed} collapsed={collapsed} />} />

                        <Route path='teamlist' element={<TeamList setCollapsed={setCollapsed} collapsed={collapsed} />} />
                        <Route path='editteam/:id' element={<EditTeam setCollapsed={setCollapsed} collapsed={collapsed} />} />
                        <Route path='createteam' element={<EditTeam setCollapsed={setCollapsed} collapsed={collapsed} />} />

                        <Route path='contactlist' element={<ContactList setCollapsed={setCollapsed} collapsed={collapsed} />} />

                        <Route path='faqlist' element={<FAQsList setCollapsed={setCollapsed} collapsed={collapsed} />} />
                        <Route path='editfaq/:id' element={<EditFAQs setCollapsed={setCollapsed} collapsed={collapsed} />} />
                        <Route path='createfaq' element={<EditFAQs setCollapsed={setCollapsed} collapsed={collapsed} />} />

                        <Route path='testinomiallist' element={<TestimonialList setCollapsed={setCollapsed} collapsed={collapsed} />} />
                        <Route path='edittestinomial/:id' element={<EditTestinomial setCollapsed={setCollapsed} collapsed={collapsed} />} />
                        <Route path='createtestinomial' element={<EditTestinomial setCollapsed={setCollapsed} collapsed={collapsed} />} />

                        <Route path='subscriptionlist' element={<SubscriptionList setCollapsed={setCollapsed} collapsed={collapsed} />} />
                        <Route path='createsubscription' element={<EditSubscription setCollapsed={setCollapsed} collapsed={collapsed} />} />

                        <Route path='sociallinkslist' element={<SocialLinks setCollapsed={setCollapsed} collapsed={collapsed} />} />
                        <Route path='editsociallinks/:id' element={<EditSocialLinks setCollapsed={setCollapsed} collapsed={collapsed} />} />

                        <Route path='applinkslist' element={<AppLinks setCollapsed={setCollapsed} collapsed={collapsed} />} />
                        <Route path='editapplinks/:id' element={<EditAppLinks setCollapsed={setCollapsed} collapsed={collapsed} />} />

                        <Route path='footer' element={<Footer setCollapsed={setCollapsed} collapsed={collapsed} />} />
                        <Route path='editfooter/:id' element={<EditFooter setCollapsed={setCollapsed} collapsed={collapsed} />} />

                        <Route path='discover' element={<Discover setCollapsed={setCollapsed} collapsed={collapsed} />} />
                        <Route path='editdiscover' element={<EditDiscover setCollapsed={setCollapsed} collapsed={collapsed} />} />

                        <Route path='homeslider' element={<HomeSlider setCollapsed={setCollapsed} collapsed={collapsed} />} />
                        <Route path='edithomesliderimages' element={<EditHomeSlider setCollapsed={setCollapsed} collapsed={collapsed} />} />
                        <Route path='addhomesliderimages' element={<EditHomeSlider setCollapsed={setCollapsed} collapsed={collapsed} />} />

                        <Route path='financialreport' element={<FinancialReport setCollapsed={setCollapsed} collapsed={collapsed} />} />

                        <Route path='bookingslist' element={<BookingsList setCollapsed={setCollapsed} collapsed={collapsed} />} />
                    </Routes>
                </Layout>
            </Layout >
        </div>
    );
};

export default MainDashboard;