import React from "react";
import PartnerTable from "./PartnerTable";

export default function PartnerStaff({ heading ,data}) {
  console.log(data);
  return (
    <div className="w-[95%] mb-3 mx-3">
      <div>
        <h5 className="text-black" style={{ fontWeight: "bold" }}>
          {heading}
        </h5>
        <PartnerTable data={data?data:[]} tablename={heading}/>
      </div>
    </div>
  );
}