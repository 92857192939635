import React from 'react'
import AppRouter from './Routes/Routes'

const App = () => {
  return (
    <>
     <AppRouter />
    </>
  )
}
  
export default App
