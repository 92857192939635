import { Skeleton } from "antd";
import { ToastContainer } from "react-toastify";
import { toast } from "react-toastify";
import Header from "../../Components/Header";
import MainHeader from "../../Components/MainHeader";
import { Delete, GetTheList } from "../../Config/ApiHandling";
import Swal from "sweetalert2";
import { useEffect, useState, useCallback } from "react";
import DeleteButton from "../../Components/DeleteButton";
import AddButton from "../../Components/AddButton";
import SearchInput from "../../Components/SearchInp";
import Copyright from "../../Components/Copyright";

const SubscriptionList = ({ setCollapsed, collapsed }) => {
  let [data, setData] = useState([]);
  let [flag, setflag] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState([]);
  const startIndex = (currentPage - 1) * 10 + 1;

  let cols = [
    {
      name: "Sr No",
      key: "index",
    },
    {
      name: "Email",
      key: "email",
    },
    {
      name: "Action",
      displayFeild: (item) => (
        <>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <DeleteButton
              text="Delete"
              click={() => DeleteSubscriber(item._id)}
            />
          </div>
        </>
      ),
    },
  ];

  const token = localStorage.getItem("token");
  const GetData = useCallback(() => {
    const limit = 10;
    GetTheList(`getSubscribedUser`, currentPage, limit, token)
      .then((res) => {
        setData(res.data.data.subscribedUsers);
        setTotalPages(res.data.data.last_page);
      })
      .catch((e) => {
        const errorMessage =
          e.response && e.response.data && e.response.data.message
            ? e.response.data.message
            : "An error occurred";
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  }, [currentPage, token]);

  useEffect(() => {
    GetData(currentPage);
  }, [currentPage, GetData]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  let DeleteSubscriber = (e) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    })
      ?.then((result) => {
        if (result.isConfirmed) {
          Delete("deleteSubscribedUser", e, token)
            ?.then((res) => {
              toast.success(res.data.message, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });

              GetData();
            })
            .catch((e) => {
              const errorMessage =
                e.response && e.response.data && e.response.data.message
                  ? e.response.data.message
                  : "An error occurred";
              toast.error(errorMessage, {
                position: "top-right",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
            });
        }
      })
      .catch((e) => {
        const errorMessage =
          e.response && e.response.data && e.response.data.message
            ? e.response.data.message
            : "An error occurred";
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  };

  const renderPaginationButtons = () => {
    const buttons = [];
    const maxButtonsToShow = 5;
    const halfWay = Math.floor(maxButtonsToShow / 2);

    let startPage, endPage;

    if (totalPages <= maxButtonsToShow) {
      // If total pages are less than max buttons to show, show all pages
      startPage = 1;
      endPage = totalPages;
    } else if (currentPage <= halfWay) {
      // If current page is near the start, show the first few pages
      startPage = 1;
      endPage = maxButtonsToShow;
    } else if (currentPage + halfWay >= totalPages) {
      // If current page is near the end, show the last few pages
      startPage = totalPages - maxButtonsToShow + 1;
      endPage = totalPages;
    } else {
      // Otherwise, center the current page in the range
      startPage = currentPage - halfWay;
      endPage = currentPage + halfWay;
    }

    // Generate page buttons
    for (let i = startPage; i <= endPage; i++) {
      buttons.push(
        <li
          key={i}
          className={`page-item ${currentPage === i ? "active" : ""}`}
        >
          <button
            className="page-link"
            onClick={() => handlePageChange(i)}
            style={{
              backgroundColor: currentPage === i ? "white" : "#212529",
              border: "1px solid #212529",
              borderRadius: "5px",
              color: currentPage === i ? "black" : "white",
            }}
          >
            {i}
          </button>
        </li>
      );
    }

    return buttons;
  };

  return (
    <>
      <div>
        <MainHeader setCollapsed={setCollapsed} collapsed={collapsed} />
        <Header screenTitle="Subscription List" />
        <ToastContainer />
        <div
          style={{
            display: "flex",
            alignItems: "end",
            justifyContent: "end",
            width: "100%",
            margin: "0px -40px",
          }}
        >
          <SearchInput
            setflag={setflag}
            data={data}
            setData={setData}
            originalData={GetData}
            endpoint={"search-Subuser"}
            msg={"This_is_not_typ_case"}
          />
          <AddButton path="/dashboard/createsubscription" />
        </div>
        <>
          <div style={{ display: "flex", justifyContent: "center" }}>
            {flag ? (
              <>
                <table
                  className="table table-striped table-bordered text-center"
                  style={{
                    width: "90%",
                    margin: "1%",
                    border: "1px solid #ccc",
                  }}
                >
                  <thead>
                    <tr>
                      {cols &&
                        cols.map((item, index) => {
                          return (
                            <th
                              className="header-title"
                              style={{
                                backgroundColor: "#212529",
                                color: "#fff",
                              }}
                              key={index}
                            >
                              {item.name}
                            </th>
                          );
                        })}
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td colSpan="6">
                        {flag ? (
                          <Skeleton.Input
                            style={{ width: "90vh" }}
                            active
                            animation="wave"
                          />
                        ) : (
                          "No Data"
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </>
            ) : Array.isArray(data) && data.length > 0 ? (
              <table
                className="table table-striped table-bordered text-center"
                style={{ width: "90%", margin: "1%", border: "1px solid #ccc" }}
              >
                <thead>
                  <tr>
                    {cols &&
                      cols.map((item, index) => {
                        return (
                          <th
                            className="header-title"
                            style={{
                              backgroundColor: "#212529",
                              color: "#fff",
                              fontSize: "20px",
                            }}
                            key={index}
                          >
                            {item.name}
                          </th>
                        );
                      })}
                  </tr>
                </thead>
                <tbody>
                  {data.map((rowData, key) => (
                    <tr key={key} className="table_img tablerow">
                      {cols.map((col, index) => (
                        <td style={{ verticalAlign: "middle" }} key={index}>
                          {col.displayFeild ? (
                            col.displayFeild(rowData)
                          ) : col.key === "index" ? (
                            startIndex + key
                          ) : col.img ? (
                            <img
                              className="table_img"
                              src={`${rowData[col.img]}`}
                              alt="NoImage"
                              style={{ width: "60px", height: "55px" }}
                            />
                          ) : col.key === "amount" || col.key === "price" ? (
                            `$${rowData[col.key]}`
                          ) : col.key === "email" ? (
                            rowData?.email
                          ) : null}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <table
                className="table table-striped table-bordered text-center"
                style={{ width: "90%", margin: "1%", border: "1px solid #ccc" }}
              >
                <thead>
                  <tr>
                    {cols &&
                      cols.map((item, index) => {
                        return (
                          <th
                            className="header-title"
                            style={{
                              backgroundColor: "#212529",
                              color: "#fff",
                            }}
                            key={index}
                          >
                            {item.name}
                          </th>
                        );
                      })}
                  </tr>
                </thead>
                <tbody>
                  {cols.map((item, index) => (
                    <tr key={index}>
                      <td key={index} colSpan="6">
                        <Skeleton.Input
                          style={{ width: "90vh" }}
                          active
                          animation="wave"
                        />{" "}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <nav aria-label="Page navigation example">
            <ul className="pagination justify-content-center gap-2  ">
              <li
                className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
              >
                <button
                  className="page-link"
                  style={{ color: "black" }}
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                >
                  Previous
                </button>
              </li>
              {/* {Array.from({ length: totalPages }, (_, index) => (
                                <li key={index} className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}>
                                    <button className="page-link" onClick={() => handlePageChange(index + 1)} style={{
                            backgroundColor: currentPage === index + 1 ? "white" : "#212529",
                            border: "1px solid #212529",
                            borderRadius: "5px",
                            color: currentPage === index + 1 ? "black" : "white"
                        }}>
                                        {index + 1}
                                    </button>
                                </li>
                            ))} */}
              {renderPaginationButtons()}
              <li
                className={`page-item ${
                  currentPage === totalPages ? "disabled" : ""
                }`}
              >
                <button
                  className="page-link"
                  style={{ color: "black" }}
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalPages}
                >
                  Next
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      <Copyright />
    </>
  );
};

export default SubscriptionList;
