import { Button } from "@mui/material";
import React from "react";
import DoneIcon from "@mui/icons-material/Done";

const ApprovedButton = ({ text, click, classname, disabled }) => {
  return (
    <Button
      startIcon={disabled ? <DoneIcon /> : ""}
      style={{
        borderRadius: "2px",
        height: "32px",
        textTransform: "capitalize",
        backgroundColor: "green", // Change background color to green
        color: "white", // Text color
        width: "150px",
      }}
      className={`${classname ? classname : "mx-2"}`}
      type="button" // Use "button" instead of "primary"
      onClick={click}
      // disabled={disabled} // Set disabled based on the prop
    >
      {disabled ? "Give Comission" : text}
    </Button>
  );
};

export default ApprovedButton;
