import { Skeleton } from "antd";
import { ToastContainer, toast } from "react-toastify";
import Header from "../../Components/Header";
import MainHeader from "../../Components/MainHeader";
import { Delete, GetThePage } from "../../Config/ApiHandling";
import Swal from "sweetalert2";
import { useEffect, useState, useCallback } from "react";
import SearchInput from "../../Components/SearchInp";
import DeleteButton from "../../Components/DeleteButton";
import EditButton from "../../Components/EditButton";
import { Modal } from "react-bootstrap";
import Copyright from "../../Components/Copyright";

const UserList = ({ setCollapsed, collapsed }) => {
  let [data, setData] = useState([]);
  let [detail, setDetail] = useState([]);
  let [flag, setflag] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState([]);
  const startIndex = (currentPage - 1) * 10 + 1;

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const fetchUserDetails = (userId) => {
    const filteredData = data.filter((item) => item._id === userId);
    console.log(filteredData);
    if (filteredData.length > 0) {
      setDetail(filteredData);
      setShowModal(true);
    }
  };

  let cols = [
    {
      name: "Sr No",
      key: "index",
    },
    {
      name: "Name",
      displayname: (item) => (item?.firstname ? item?.firstname : item?.name),
    },
    {
      name: "Email",
      key: "email",
    },
    {
      name: "Number",
      key: "phonenumber",
    },
    {
      name: "Action",
      displayFeild: (item) => (
        <>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <EditButton
              text="Details"
              click={() => fetchUserDetails(item._id)}
            />
            <DeleteButton text="Delete" click={() => DeleteUser(item._id)} />
          </div>
        </>
      ),
    },
  ];

  const token = localStorage.getItem("token");
  const GetData = useCallback(() => {
    const limit = 10;
    GetThePage(`admin/getuserbytype`, "user", currentPage, limit, token)
      .then((res) => {
        console.log(res.data);

        setData(res.data.data.users);
        setTotalPages(res.data.data.totalPages);
      })
      .catch((e) => {
        const errorMessage =
          e.response && e.response.data && e.response.data.message
            ? e.response.data.message
            : "An error occurred";
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      })
      .finally(() => {
        setflag(false);
      });
  }, [currentPage, token]);

  useEffect(() => {
    GetData(currentPage);
  }, [currentPage, GetData]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  let DeleteUser = (e) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    })
      ?.then((result) => {
        if (result.isConfirmed) {
          const errorMessage =
            e.response && e.response.data && e.response.data.message
              ? e.response.data.message
              : "An error occurred";
          toast.error(errorMessage, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          Delete("auth/delete", e, token)
            ?.then((res) => {
              GetData();
            })
            .catch((e) => {
              const errorMessage =
                e.response && e.response.data && e.response.data.message
                  ? e.response.data.message
                  : "An error occurred";
              toast.error(errorMessage, {
                position: "top-right",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
            });
        }
      })
      .catch((e) => {
        const errorMessage =
          e.response && e.response.data && e.response.data.message
            ? e.response.data.message
            : "An error occurred";
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  };

  const renderPaginationButtons = () => {
    const buttons = [];
    const maxButtonsToShow = 5;
    const halfWay = Math.floor(maxButtonsToShow / 2);

    let startPage, endPage;

    if (totalPages <= maxButtonsToShow) {
      // If total pages are less than max buttons to show, show all pages
      startPage = 1;
      endPage = totalPages;
    } else if (currentPage <= halfWay) {
      // If current page is near the start, show the first few pages
      startPage = 1;
      endPage = maxButtonsToShow;
    } else if (currentPage + halfWay >= totalPages) {
      // If current page is near the end, show the last few pages
      startPage = totalPages - maxButtonsToShow + 1;
      endPage = totalPages;
    } else {
      // Otherwise, center the current page in the range
      startPage = currentPage - halfWay;
      endPage = currentPage + halfWay;
    }

    // Generate page buttons
    for (let i = startPage; i <= endPage; i++) {
      buttons.push(
        <li
          key={i}
          className={`page-item ${currentPage === i ? "active" : ""}`}
        >
          <button
            className="page-link"
            onClick={() => handlePageChange(i)}
            style={{
              backgroundColor: currentPage === i ? "white" : "#212529",
              border: "1px solid #212529",
              borderRadius: "5px",
              color: currentPage === i ? "black" : "white",
            }}
          >
            {i}
          </button>
        </li>
      );
    }

    return buttons;
  };

  return (
    <>
      <div>
        <MainHeader setCollapsed={setCollapsed} collapsed={collapsed} />
        <Header screenTitle="User List" />
        <ToastContainer />
        <div
          style={{
            display: "flex",
            alignItems: "end",
            justifyContent: "end",
            width: "100%",
            margin: "0px -40px",
          }}
        >
          <SearchInput
            setflag={setflag}
            data={data}
            setData={setData}
            originalData={GetData}
          />
        </div>
        <>
          <Modal
            show={showModal}
            onHide={handleCloseModal}
            centered
            backdrop="true"
            keyboard={false}
            dialogClassName="custom-modal"
            size="lg"
          >
            <Modal.Header closeButton>
              <Modal.Title>User Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {detail && (
                <>
                  <div className="container">
                    <div className="row mt-4">
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <h6>First Name: </h6>{" "}
                        <input
                          type="text"
                          style={{
                            border: "1px solid black",
                            borderRadius: "5px",
                            padding: "8px",
                            width: "100%",
                          }}
                          value={detail[0]?.firstname}
                          disabled
                        />
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <h6>Last Name: </h6>{" "}
                        <input
                          type="text"
                          style={{
                            border: "1px solid black",
                            borderRadius: "5px",
                            padding: "8px",
                            width: "100%",
                          }}
                          value={detail[0]?.lastname}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <h6>Email: </h6>{" "}
                        <input
                          type="text"
                          style={{
                            border: "1px solid black",
                            borderRadius: "5px",
                            padding: "8px",
                            width: "100%",
                          }}
                          value={detail[0]?.email}
                          disabled
                        />
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <h6>Address: </h6>{" "}
                        <input
                          type="text"
                          style={{
                            border: "1px solid black",
                            borderRadius: "5px",
                            padding: "8px",
                            width: "100%",
                          }}
                          value={
                            detail[0]?.address ? detail[0]?.address : "---"
                          }
                          disabled
                        />
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <h6>Country Code: </h6>{" "}
                        <input
                          type="text"
                          style={{
                            border: "1px solid black",
                            borderRadius: "5px",
                            padding: "8px",
                            width: "100%",
                          }}
                          value="+92"
                          disabled
                        />
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <h6>Phone Number: </h6>{" "}
                        <input
                          type="text"
                          style={{
                            border: "1px solid black",
                            borderRadius: "5px",
                            padding: "8px",
                            width: "100%",
                          }}
                          value={detail[0]?.phonenumber}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <h6>Gender: </h6>{" "}
                        <input
                          type="text"
                          style={{
                            border: "1px solid black",
                            borderRadius: "5px",
                            padding: "8px",
                            width: "100%",
                          }}
                          value={detail[0]?.gender}
                          disabled
                        />
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <h6>Date of Birth: </h6>{" "}
                        <input
                          type="text"
                          style={{
                            border: "1px solid black",
                            borderRadius: "5px",
                            padding: "8px",
                            width: "100%",
                          }}
                          value={detail[0]?.dob ? detail[0]?.dob : "---"}
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}
            </Modal.Body>
          </Modal>

          <div style={{ display: "flex", justifyContent: "center" }}>
            {flag ? (
              <>
                <table
                  className="table table-striped table-bordered text-center"
                  style={{
                    width: "90%",
                    margin: "1%",
                    border: "1px solid #ccc",
                  }}
                >
                  <thead>
                    <tr>
                      {cols &&
                        cols.map((item, index) => {
                          return (
                            <th
                              className="header-title"
                              style={{
                                backgroundColor: "#212529",
                                color: "#fff",
                              }}
                              key={index}
                            >
                              {item.name}
                            </th>
                          );
                        })}
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td colSpan="6">
                        {" "}
                        <Skeleton.Input
                          style={{ width: "90vh" }}
                          active
                          animation="wave"
                        />{" "}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </>
            ) : Array.isArray(data) && data.length > 0 ? (
              <table
                className="table table-striped table-bordered text-center"
                style={{ width: "90%", margin: "1%", border: "1px solid #ccc" }}
              >
                <thead>
                  <tr>
                    {cols &&
                      cols.map((item, index) => {
                        return (
                          <th
                            className="header-title"
                            style={{
                              backgroundColor: "#212529",
                              color: "#fff",
                              fontSize: "20px",
                            }}
                            key={index}
                          >
                            {item.name}
                          </th>
                        );
                      })}
                  </tr>
                </thead>
                <tbody>
                  {data.map((item, key) => (
                    <tr key={key} className="table_img tablerow">
                      {cols.map((col, index) => (
                        <td style={{ verticalAlign: "middle" }} key={index}>
                          {col.displayFeild ? (
                            col.displayFeild(item)
                          ) : col.key === "index" ? (
                            startIndex + key
                          ) : col.img ? (
                            <img
                              className="table_img"
                              src={`${item[col.img]}`}
                              alt="NoImage"
                              style={{ width: "60px", height: "55px" }}
                            />
                          ) : col.key === "amount" || col.key === "price" ? (
                            `${item[col.key]}`
                          ) : col.displayname ? (
                            col.displayname(item)
                          ) : col.key === "email" ? (
                            item.email
                          ) : col.key === "phonenumber" ? (
                            item.phonenumber
                          ) : null}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <table
                className="table table-striped table-bordered text-center"
                style={{ width: "90%", margin: "1%", border: "1px solid #ccc" }}
              >
                <thead>
                  <tr>
                    {cols &&
                      cols.map((item, index) => {
                        return (
                          <th
                            className="header-title"
                            style={{
                              backgroundColor: "#212529",
                              color: "#fff",
                            }}
                            key={index}
                          >
                            {item.name}
                          </th>
                        );
                      })}
                  </tr>
                </thead>
                <tbody>
                  {cols.map((item, index) => (
                    <tr key={index}>
                      <td key={index} colSpan="6">
                        <Skeleton.Input
                          style={{ width: "90vh" }}
                          active
                          animation="wave"
                        />{" "}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <nav aria-label="Page navigation example">
            <ul className="pagination justify-content-center gap-2  ">
              <li
                className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
              >
                <button
                  className="page-link"
                  style={{ color: "black" }}
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                >
                  Previous
                </button>
              </li>
              {/* {Array.from({ length: totalPages }, (_, index) => (
                <li key={index} className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}>
                  <button className="page-link" onClick={() => handlePageChange(index + 1)} style={{
                            backgroundColor: currentPage === index + 1 ? "white" : "#212529",
                            border: "1px solid #212529",
                            borderRadius: "5px",
                            color: currentPage === index + 1 ? "black" : "white"
                        }}>
                    {index + 1}
                  </button>
                </li>
              ))} */}
              {renderPaginationButtons()}
              <li
                className={`page-item ${
                  currentPage === totalPages ? "disabled" : ""
                }`}
              >
                <button
                  className="page-link"
                  style={{ color: "black" }}
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalPages}
                >
                  Next
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      <Copyright />
    </>
  );
};

export default UserList;
