import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { Box } from "@mui/material";
import Header from "../../Components/Header";
import MainHeader from "../../Components/MainHeader";
import { GetList } from "../../Config/ApiHandling";
import { useNavigate } from "react-router-dom";
import { Skeleton } from "antd";
import EditButton from "../../Components/EditButton";
import Copyright from "../../Components/Copyright";

const PageList = ({ setCollapsed, collapsed }) => {
  const [list, setList] = useState([]);
  const navigate = useNavigate();

  let cols = [
    {
      name: "Title",
      key: "title",
    },
    {
      name: "Action",
      displayField: (e) => (
        <>
          <EditButton text={"Edit"} click={() => edit(e)} />
        </>
      ),
    },
  ];

  useEffect(() => {
    Promise.all([
      GetList("privacypolicy"),
      GetList("aboutus"),
      GetList("/condition"),
      GetList("/howitwork"),
    ])
      .then(([privacyPolicyRes, aboutUsRes, conditionRes, howitworkRes]) => {
        const mergedData = mergeData(
          privacyPolicyRes?.data?.data || [],
          aboutUsRes?.data?.data || [],
          conditionRes?.data?.data || [],
          howitworkRes?.data?.data || [],
          "_id"
        );
        setList(mergedData);
      })
      .catch((e) => {
        const errorMessage =
          e.response && e.response.data && e.response.data.message
            ? e.response.data.message
            : "An error occurred";
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  }, []);

  const mergeData = (array1, array2, array3, array4, commonKey) => {
    const mergedArray = [];
    const mergeSingleArray = (array) => {
      array.forEach((item) => {
        const existingItemIndex = mergedArray.findIndex(
          (mergedItem) => mergedItem[commonKey] === item[commonKey]
        );
        if (existingItemIndex !== -1) {
          mergedArray[existingItemIndex] = {
            ...mergedArray[existingItemIndex],
            ...item,
          };
        } else {
          mergedArray.push(item);
        }
      });
    };
    mergeSingleArray(array1);
    mergeSingleArray(array2);
    mergeSingleArray(array3);
    mergeSingleArray(array4);
    return mergedArray;
  };

  let edit = (e) => {
    navigate(`/dashboard/editpage/${e._id}`, {
      state: e,
    });
    localStorage.setItem("pagename", e.title);
  };

  return (
    <div>
      <MainHeader setCollapsed={setCollapsed} collapsed={collapsed} />
      <Header screenTitle="Pages List " />
      <Box>
        <Box className="px-5">
          <ToastContainer />
        </Box>
        <div style={{ display: "flex", justifyContent: "center" }}>
          {list ? (
            list.length === 0 ? (
              <table
                className="table table-striped table-bordered text-center"
                style={{ width: "90%", margin: "1%", border: "1px solid #ccc" }}
              >
                <thead>
                  <tr>
                    {cols &&
                      cols.map((item, index) => {
                        return (
                          <th
                            className="header-title"
                            style={{
                              backgroundColor: "#212529",
                              color: "#fff",
                            }}
                            key={index}
                          >
                            {item.name}
                          </th>
                        );
                      })}
                  </tr>
                </thead>
                <tbody>
                  {cols.map((item, index) => (
                    <tr key={index}>
                      <td key={index} colSpan="6">
                        <Skeleton.Input
                          style={{ width: "90vh" }}
                          active
                          animation="wave"
                        />{" "}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <table
                className="table table-striped table-bordered text-center"
                style={{ width: "90%", margin: "1%", border: "1px solid #ccc" }}
              >
                <thead>
                  <tr>
                    {cols &&
                      cols.map((item, index) => {
                        return (
                          <th
                            className="header-title"
                            style={{
                              backgroundColor: "#212529",
                              color: "#fff",
                            }}
                            key={index}
                          >
                            {item.name}
                          </th>
                        );
                      })}
                  </tr>
                </thead>
                <tbody>
                  {list.map((item, key) => (
                    <tr key={key}>
                      {cols.map((col, index) => (
                        <td
                          style={{
                            maxWidth:
                              col.key === "condition" ? "250px" : "200px",
                            verticalAlign: "middle",
                          }}
                          key={index}
                        >
                          {col.displayField
                            ? col.displayField(item)
                            : item[col.key]}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            )
          ) : null}
        </div>
      </Box>
      <Copyright />
    </div>
  );
};

export default PageList;
