import React, { useEffect, useState } from 'react'
import 'react-quill/dist/quill.snow.css';
import ReactQuill from 'react-quill';
import MainHeader from '../../Components/MainHeader'
import Header from '../../Components/Header'
import { Box } from '@mui/material'
import { Typography, Skeleton } from 'antd'
import BsButton from '../../Components/BsButton'
import { Post, Put, GetById } from '../../Config/ApiHandling'
import { useNavigate, useParams } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import DeleteButton from '../../Components/DeleteButton'
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import Copyright from '../../Components/Copyright'


const EditFAQs = ({ setCollapsed, collapsed }) => {
  const [loading, setLoading] = useState(true);
  const [description, setDescription] = useState('');
  const [descriptionArb, setDescriptionArb] = useState('');
  const [formValues, setFormValues] = useState({
    title: '',
    title_arb: '',
    description: '',
    description_arb: '',
  });
  const params = useParams()
  let navigation = useNavigate()
  const token = localStorage.getItem("token")


  useEffect(() => {
    if (params.id) {
      GetById("getFaqsbyId", params.id, token)
        .then((res) => {
          setFormValues(res.data.data)
          setDescription(res.data.data.description);
          setDescriptionArb(res.data.data.description_arb);
          setLoading(false);
        })
        .catch((err) => {
          const errorMessage = err.response && err.response.data && err.response.data.message
            ? err.response.data.message
            : 'An error occurred';
          toast.error(errorMessage, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setLoading(false);
        })
    } else {
      setFormValues({
        title: '',
        title_arb: '',
        description: '',
        description_arb: '',
      });
      setLoading(false);
    }
  }, [params?.id,token]);


  const handleQuillChange = (value) => {
    const cleanedValue = value.replace(/^<p>/, '').replace(/<\/p>$/, '');
    const isEmpty = cleanedValue.replace(/<[^>]*>?/gm, '').trim() === '';
    setFormValues(formValues => ({
      ...formValues,
      description: isEmpty ? '' : cleanedValue
    }));
  };

  const handleQuillChangeArb = (value) => {
    const cleanedValue = value.replace(/^<p>/, '').replace(/<\/p>$/, '');
    const isEmpty = cleanedValue.replace(/<[^>]*>?/gm, '').trim() === '';
    setFormValues(formValues => ({
      ...formValues,
      description_arb: isEmpty ? '' : cleanedValue
    }));
  };


  const initialValues = {
    title: formValues?.title || '',
    title_arb: formValues?.title_arb || '',
    description: formValues?.description || '',
    description_arb: formValues?.description_arb || '',
  };


  const validationSchemaEditFaqs = Yup.object().shape({
    title: Yup.string().required("⚠️Title (English) is Required"),
    title_arb: Yup.string().required("⚠️Title (Arabic) is Required"),
    description: Yup.string().required("⚠️Description (English) is Required"),
    description_arb: Yup.string().required("⚠️Description (Arabic) is Required"),
  })

  let sendData = (values) => {
    const formData = new FormData();
    formData.append('title', values.title);
    formData.append('title_arb', values.title_arb);
    formData.append('description', values.description);
    formData.append('description_arb', values.description_arb);

    if (formValues?._id) {
      setLoading(true);
      Put("updateFaqs", formValues._id, {
        title: values.title,
        title_arb: values.title_arb,
        description: values.description,
        description_arb: values.description_arb,
      }, token)
        .then((res) => {
          setLoading(false);
          toast("FAQ updated successfully", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            draggable: true,
            progress: undefined,
          });
          setTimeout(() => {
            navigation("/dashboard/faqlist");
          }, 1000);
        })
        .catch((err) => {
          setLoading(false);
          const errorMessage = err.response && err.response.data && err.response.data.message
            ? err.response.data.message
            : 'An error occurred';

          toast(errorMessage, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            draggable: true,
            progress: undefined,
          });
        });

    } else {
      setLoading(true);
      Post("createFaq", {
        title: values.title,
        title_arb: values.title_arb,
        description: values.description,
        description_arb: values.description_arb,
      }, token).then((res) => {
        setLoading(false);
        toast("FAQ created successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          draggable: true,
          progress: undefined,
        });
        setTimeout(() => {
          navigation("/dashboard/faqlist");
        }, 1000);
      }).catch((err) => {
        setLoading(false);
        const errorMessage = err.response && err.response.data && err.response.data.message
          ? err.response.data.message
          : 'An error occurred';

        toast(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          draggable: true,
          progress: undefined,
        });
      })
    }

  };

  return (
    <div>
      <MainHeader setCollapsed={setCollapsed} collapsed={collapsed} />
      <Header screenTitle={`${formValues?._id ? "Edit Faq" : "Create Faq"}`} buttonList={[
        {
          displayField: () => (
            < DeleteButton click={() => navigation("/dashboard/faqlist")} text="Cancel" />
          )
        }
      ]} />
      <Box sx={{ width: { md: '98%', sm: '325px', xs: '100%' } }} className=' shadow m-auto mt-2'>
        <Box >
          <ToastContainer />

          <Formik
            initialValues={initialValues}
            validationSchema={validationSchemaEditFaqs}
            onSubmit={(values) => sendData(values)}
            validateOnChange
            enableReinitialize={true}
          >
            {({ handleSubmit, setFieldValue, touched, errors }) => (
              <Form onSubmit={handleSubmit}>
                {loading ? (
                  <div className="mt-2">
                    <Box className='row p-3'>
                      <div className="col-md-6">
                        <Typography variant='p'> Title (Eng) : </Typography>
                        <div className='mt-2'>
                          <Skeleton.Input active style={{ width: '50vh' }} />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <Typography variant='p'> Title (Arb) : </Typography>
                        <div className='mt-2'>
                          <Skeleton.Input active style={{ width: '50vh' }} />
                        </div>
                      </div>
                      <div className="my-4">
                        <Typography variant='p'> Description (Eng) : </Typography>
                        <div className='mt-2'>
                          <Skeleton.Input active style={{ width: '90vh', height: "60vh" }} />
                        </div>
                      </div>
                      <div className="my-4">
                        <Typography variant='p'> Description (Arb) : </Typography>
                        <div className='mt-2'>
                          <Skeleton.Input active style={{ width: '90vh', height: "60vh" }} />
                        </div>
                      </div>
                      <Box sx={{ display: 'flex', justifyContent: 'start' }} className='  py-3'>
                        <Skeleton.Button active shape='button' style={{ width: '20vh' }} />
                      </Box>
                    </Box>
                  </div>
                ) : (
                  <Box className='row p-3'>
                    <div className="col-md-6">
                      <Typography variant='p'> Title (Eng) : </Typography>
                      <div className='mt-2'>
                        <Field
                          className="form-control w-100"
                          type="text"
                          name="title"
                          value={formValues?.title}
                          onChange={(e) => {
                            setFieldValue('title', e.target.value);
                            setFormValues({ ...formValues, title: e.target.value });
                          }}
                        />
                        {touched.title && errors.title ? (
                          <div className="error text-danger">{errors.title}</div>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <Typography variant='p'> Title (Arb) : </Typography>
                      <div className='mt-2'>
                        <Field
                          className="form-control w-100"
                          type="text"
                          name="title_arb"
                          value={formValues?.title_arb}
                          onChange={(e) => {
                            setFieldValue('title_arb', e.target.value);
                            setFormValues({ ...formValues, title_arb: e.target.value });
                          }}
                        />
                        {touched.title_arb && errors.title_arb ? (
                          <div className="error text-danger">{errors.title_arb}</div>
                        ) : null}
                      </div>
                    </div>
                    <div className='my-4'>
                      <Typography variant='p'> Description (Eng) : </Typography>
                      <div className='mt-2'>
                        <ReactQuill
                          defaultValue={description}
                          onChange={handleQuillChange}
                        />
                        {touched.description && errors.description ? (
                          <div className="error text-danger">{errors.description}</div>
                        ) : null}
                      </div>
                    </div>
                    <div className='my-4'>
                      <Typography variant='p'> Description (Arb) : </Typography>
                      <div className='mt-2'>
                        <ReactQuill
                          defaultValue={descriptionArb}
                          onChange={handleQuillChangeArb}
                        />
                        {touched.description_arb && errors.description_arb ? (
                          <div className="error text-danger">{errors.description_arb}</div>
                        ) : null}
                      </div>
                    </div>
                    <Box sx={{ display: 'flex', justifyContent: 'start' }} className='  py-3'>
                      <BsButton text={`${formValues?._id ? "Update" : "create Faq"}`} loading={loading} onClick={handleSubmit} type="submit" />
                    </Box>
                  </Box>
                )}
              </Form>
            )}
          </Formik>
        </Box>
      </Box>
      <Copyright />
    </div>
  )

}

export default EditFAQs
