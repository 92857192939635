import React from 'react';
import { Skeleton } from 'antd';
import dayjs from 'dayjs';

const Table = ({ data, flag, cols, currentPage }) => {
  const calculateSerialNumber = (index) => {
    return (currentPage - 1) * 10 + index + 1;
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      {flag ? (
        <>
          <table
            className="table table-striped table-bordered text-center"
            style={{ width: '90%', margin: '1%', border: '1px solid #ccc' }}
          >
            <thead>
              <tr>
                <th style={{ backgroundColor: '#212529', color: '#fff', fontSize: '20px' }} className='header-title'>S.No.</th>
                {cols &&
                  cols.map((item, index) => {
                    return <th className='header-title' style={{ backgroundColor: '#212529', color: '#fff' }} key={index}>{item.name}</th>;
                  })}
              </tr>
            </thead>
            <tbody>
              {cols?.map((item, index) => (
                <tr key={index}>
                  <td colSpan={cols.length}>
                    <Skeleton.Input style={{ width: '90vh' }} active animation="wave" />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

        </>
      ) : data && data.length > 0 ? (
        <table
          className="table table-striped table-bordered text-center"
          style={{ width: '90%', margin: '1%', border: '1px solid #ccc' }}
        >
          <thead>
            <tr>
              <th style={{ backgroundColor: '#212529', color: '#fff', fontSize: '20px' }} className='header-title'>S.No.</th>
              {cols &&
                cols.map((column, index) => (
                  <th className='header-title' key={index} style={{ backgroundColor: '#212529', color: '#fff', fontSize: '20px' }}>
                    {column.name}
                  </th>
                ))}
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr key={index} className="table_img tablerow">
                <td>{calculateSerialNumber(index)}</td>
                {cols.map((column, columnIndex) => (
                  <td style={{ verticalAlign: 'middle' }} key={columnIndex}>
                    {column.displayFeild ? (
                      column.displayFeild(item)
                    ) : column.img ? (
                      <img className="table_img" src={`${item[column.img]}`} alt="NoImage" style={{ width: '60px', height: '55px' }} />
                    ) : column.key === 'amount' || column.key === 'price' ? (
                      `$${item[column.key]}`
                    ) : column.key === 'paymentPurchaseDate' ? (
                      dayjs(item[column.key]).format('D-MMM-YYYY')
                    ) : (
                      item[column.key]
                    )}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <table
          className="table table-striped table-bordered text-center"
          style={{ width: '90%', margin: '1%', border: '1px solid #ccc' }}
        >
          <thead>
            <tr>
              <th style={{ backgroundColor: '#212529', color: '#fff', fontSize: '20px' }} className='header-title'>S.No.</th>
              {cols &&
                cols.map((item, index) => {
                  return <th className='header-title' style={{ backgroundColor: '#212529', color: '#fff' }} key={index}>{item.name}</th>;
                })}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colSpan={cols?.length || 1}>No Data</td>
            </tr>
          </tbody>
        </table>

      )
      }
    </div >
  );
};

export default Table;
