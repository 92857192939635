// import React, { useEffect, useState } from "react";
// import MainHeader from "../../Components/MainHeader";
// import { useParams } from "react-router-dom";
// import Header from "../../Components/Header";
// import axios from "axios";
// import TotalOrder from "../../Components/TotalOrder";
// import OrderConfirm from "../../Components/ConfirmOrder";
// import PendingOrder from "../../Components/PendingOrder";
// import PartnerStaff from "../../Components/PartnerStaff";
// import Services from "../../Components/Services";

// export default function Partnerdata({ setCollapsed, collapsed }) {
//   const [data, setData] = useState();
//   const [shown, setshown] = useState(false);
//   const [component, setComponents] = useState('');
//   const params = useParams();
//   const token = localStorage.getItem("token");

//   useEffect(() => {
//     if (params?.id) {
//       getData(params?.id);
//     }
//   }, [params?.id,token]);

//   const getData = async (id) => {
//     try {
//       const response = await axios.get(`https://backend.beautyparkbp.com/api/admin/getPartnerDetails/${id}`, {
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//       });
//       if (response) {
//         // console.log(response);
//         setData(response?.data);
//       }
//     } catch (error) {
//       console.error(error);
//     }
//   };
//   const renderComponent = () => {
//     switch (component) {
//       case "Total Order":
//         return <TotalOrder heading={component} data={data?.totalOrder?data?.totalOrder:null} />;
//       case "Confirm Order":
//         return <OrderConfirm heading={component} data={data?.confirmOrder?data?.confirmOrder:null} />;
//       case "Pending Order":
//         return <PendingOrder heading={component} data={data?.pendingOrder?data?.pendingOrder:null} />;
//       case "Staff":
//         return <PartnerStaff heading={component} data={data?.staff?data?.staff:null} />;
//       case "Services":
//         return <Services heading={component} data={data?.services?data?.services:null} />;
//       default:
//         return '';
//     }
//   };
//   return (
//     <div>
//       <MainHeader setCollapsed={setCollapsed} collapsed={collapsed} />
//       <Header screenTitle="Partner Detail" />
//       <div
//         style={{
//           display: "flex",
//           alignItems: "end",
//           justifyContent: "end",
//           width: "100%",
//           margin: "0px -40px",
//         }}
//       ></div>
//       <div className="w-100 mb-2">
//         <div className="row p-3 w-[100%] d-flex justify-content-start container">
//           <div className="col-md-4 mb-4">
//             <div className="dashboard-icon-header2 bg-image p-3 rounded text-white d-flex justify-content-center" onClick={()=>setComponents('Total Order')}>
//               <div className="dash-header-des text-center mt-3">
//                 <span className="dasboard-box-text">Total Order</span>
//                 <h1 className="text-white" style={{ fontWeight: "bold" }}>
//                   {data?.totalOrder?.length ?? "00"}
//                 </h1>
//                 {/* <span className="dasboard-box-text">SAR</span> */}
//               </div>
//             </div>
//           </div>
//           <div className="col-md-4 mb-4">
//             <div className="dashboard-icon-header2  p-3 rounded text-white d-flex justify-content-center" onClick={()=>setComponents('Confirm Order')}>
//               <div className="dash-header-des text-center mt-3">
//                 <span className="dasboard-box-text">Order Completed</span>
//                 <h1 className="text-white" style={{ fontWeight: "bold" }}>
//                   {data?.confirmOrder.length ?? "00"}
//                 </h1>
//                 {/* <span className="dasboard-box-text">SAR</span> */}
//               </div>
//             </div>
//           </div>
//           <div className="col-md-4 mb-4">
//             <div className="dashboard-icon-header2   p-3 rounded text-white d-flex justify-content-center" onClick={()=>setComponents('Pending Order')}>
//               <div className="dash-header-des text-center mt-3">
//                 <span className="dasboard-box-text">Pending Order</span>
//                 <h1 className="text-white" style={{ fontWeight: "bold" }}>
//                   {data?.pendingOrder.length ?? "0.00"}
//                 </h1>
//                 {/* <span className="dasboard-box-text">SAR</span> */}
//               </div>
//             </div>
//           </div>
//           <div className="col-md-4 mb-4">
//             <div className="dashboard-icon-header2   p-3 rounded text-white d-flex justify-content-center" onClick={()=>setComponents('Staff')}>
//               <div className="dash-header-des text-center mt-3">
//                 <span className="dasboard-box-text">Total Staff</span>
//                 <h1 className="text-white" style={{ fontWeight: "bold" }}>
//                   {data?.staff.length ?? "0.00"}
//                 </h1>
//                 {/* <span className="dasboard-box-text">SAR</span> */}
//               </div>
//             </div>
//           </div>
//           <div className="col-md-4 mb-4">
//             <div className="dashboard-icon-header2  p-3 rounded text-white d-flex justify-content-center" onClick={()=>setComponents("Services")}>
//               <div className="dash-header-des text-center mt-3">
//                 <span className="dasboard-box-text">Total Services</span>
//                 <h1 className="text-white" style={{ fontWeight: "bold" }}>
//                   {data?.services.length ?? "0.00"}
//                 </h1>
//                 {/* <span className="dasboard-box-text">SAR</span> */}
//               </div>
//             </div>
//           </div>
//           <div className="col-md-4 mb-4">
//             <div className="dashboard-icon-header2  p-3 rounded text-white d-flex justify-content-center">
//               <div className="dash-header-des text-center mt-3">
//                 <span className="dasboard-box-text">Total Earning</span>
//                 <h1 className="text-white" style={{ fontWeight: "600" }}>
//                   {data?.totalEarnings + "$" ?? "0.00"}
//                 </h1>
//                 {/* <span className="dasboard-box-text">SAR</span> */}
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//       <div className="w-100 mb-3">
//       {renderComponent()}
          
//       </div>
//       <div className="viewdetail mx-3 mb-3">
//         <button className="rounded-pill bg-white text-black outline-none px-4 py-2 border" onClick={() => setshown(!shown)}>
//           View Detail
//         </button>
//       </div>


//       {data && (
//         <div className={`container mb-4 ${shown ? 'visible-transition' : 'hidden-transition'}`}>
//           <div className="row">
//             <div className="col-lg-6 col-md-6 col-sm-12">
//               <h6>First Name: </h6>
//               <input
//                 type="text"
//                 style={{
//                   border: "1px solid black",
//                   borderRadius: "5px",
//                   padding: "8px",
//                   width: "100%",
//                 }}
//                 value={data?.patner?.firstname ? data?.patner?.firstname : data?.patner?.name}
//                 disabled
//               />
//             </div>
//             <div className="col-lg-6 col-md-6 col-sm-12">
//               <h6>Last Name: </h6>
//               <input
//                 type="text"
//                 style={{
//                   border: "1px solid black",
//                   borderRadius: "5px",
//                   padding: "8px",
//                   width: "100%",
//                 }}
//                 value={data?.patner?.lastname ? data?.patner?.lastname : '---'}
//                 disabled
//               />
//             </div>
//           </div>
//           <div className="row mt-4">
//             <div className="col-lg-6 col-md-6 col-sm-12">
//               <h6>Email: </h6>
//               <input
//                 type="text"
//                 style={{
//                   border: "1px solid black",
//                   borderRadius: "5px",
//                   padding: "8px",
//                   width: "100%",
//                 }}
//                 value={data?.patner?.email ? data?.patner?.email : "---"}
//                 disabled
//               />
//             </div>
//             <div className="col-lg-6 col-md-6 col-sm-12">
//               <h6>Phone Number: </h6>
//               <input
//                 type="text"
//                 style={{
//                   border: "1px solid black",
//                   borderRadius: "5px",
//                   padding: "8px",
//                   width: "100%",
//                 }}
//                 value={data?.patner?.phonenumber}
//                 disabled
//               />
//             </div>
//           </div>
//           <div className="row mt-4">
//             <div className="col-lg-6 col-md-6 col-sm-12">
//               <h6>Business Name: </h6>
//               <input
//                 type="text"
//                 style={{
//                   border: "1px solid black",
//                   borderRadius: "5px",
//                   padding: "8px",
//                   width: "100%",
//                 }}
//                 value={data?.patner?.businessname}
//                 disabled
//               />
//             </div>
//             <div className="col-lg-6 col-md-6 col-sm-12">
//               <h6>Location: </h6>
//               <input
//                 type="text"
//                 style={{
//                   border: "1px solid black",
//                   borderRadius: "5px",
//                   padding: "8px",
//                   width: "100%",
//                 }}
//                 value={data?.patner?.salonId?.location_name ?? ""}
//                 disabled
//               />
//             </div>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// }
import React, { useEffect, useState } from "react";
import MainHeader from "../../Components/MainHeader";
import Header from "../../Components/Header";
import axios from "axios";
import TotalOrder from "../../Components/TotalOrder";
import OrderConfirm from "../../Components/ConfirmOrder";
import PendingOrder from "../../Components/PendingOrder";
import PartnerStaff from "../../Components/PartnerStaff";
import Services from "../../Components/Services";
import { useParams } from "react-router-dom";

export default function Partnerdata({ setCollapsed, collapsed }) {
  const [data, setData] = useState(null);
  const [shown, setShown] = useState(false);
  const [component, setComponent] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [itemsPerPage] = useState(10); // Number of items per page
  const [paginatedData, setPaginatedData] = useState([]);
  const params = useParams();
  const token = localStorage.getItem("token");

  useEffect(() => {
    if (params?.id) {
      getData(params?.id);
    }
  }, [params?.id, token, currentPage, component]); // Added `component` to the dependency array

  const getData = async (id) => {
    try {
      const response = await axios.get(`https://backend.beautyparkbp.com/api/admin/getPartnerDetails/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response) {
        const allData = response?.data; // Assuming this includes all data
        setData(allData);
        paginateData(allData); // Only if data is available
      }
    } catch (error) {
      console.error("Failed to fetch data:", error);
    }
  };

  const paginateData = (data) => {
    if (data) {
      let items = [];
      switch (component) {
        case "Total Order":
          items = data.totalOrder;
          break;
        case "Confirm Order":
          items = data.confirmOrder;
          break;
        case "Pending Order":
          items = data.pendingOrder;
          break;
        case "Staff":
          items = data.staff;
          break;
        case "Services":
          items = data.services;
          break;
        default:
          items = [];
          break;
      }

      const start = (currentPage - 1) * itemsPerPage;
      const end = start + itemsPerPage;
      const paginatedItems = items.slice(start, end);

      setPaginatedData(paginatedItems);
      setTotalPages(Math.ceil(items.length / itemsPerPage));
    }
  };

  const handlePageChange = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
      paginateData(data); // Re-paginate data when page changes
    }
  };

  const renderPaginationButtons = () => {
    const buttons = [];
    const step = 5; // Number of page buttons to show
    let startPage = Math.max(1, currentPage - Math.floor(step / 2));
    let endPage = Math.min(totalPages, startPage + step - 1);

    if (endPage - startPage + 1 < step) {
      startPage = Math.max(1, endPage - step + 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      buttons.push(
        <li
          key={i}
          className={`page-item ${currentPage === i ? "active" : ""}`}
        >
          <button
            className="page-link"
            onClick={() => handlePageChange(i)}
            style={{
              backgroundColor: currentPage === i ? "white" : "#212529",
              border: "1px solid #212529",
              borderRadius: "5px",
              color: currentPage === i ? "black" : "white",
            }}
          >
            {i}
          </button>
        </li>
      );
    }

    return buttons;
  };

  const renderComponent = () => {
    switch (component) {
      case "Total Order":
        return <TotalOrder heading={component} data={paginatedData} />;
      case "Confirm Order":
        return <OrderConfirm heading={component} data={paginatedData} />;
      case "Pending Order":
        return <PendingOrder heading={component} data={paginatedData} />;
      case "Staff":
        return <PartnerStaff heading={component} data={paginatedData} />;
      case "Services":
        return <Services heading={component} data={paginatedData} />;
      default:
        return null;
    }
  };

  return (
    <div>
      <MainHeader setCollapsed={setCollapsed} collapsed={collapsed} />
      <Header screenTitle="Partner Detail" />
      <div className="w-100 mb-2">
        <div className="row p-3 w-[100%] d-flex justify-content-start container">
          <div className="col-md-4 mb-4">
            <div className="dashboard-icon-header2 bg-image p-3 rounded text-white d-flex justify-content-center" onClick={() => setComponent('Total Order')}>
              <div className="dash-header-des text-center mt-3">
                <span className="dasboard-box-text">Total Order</span>
                <h1 className="text-white" style={{ fontWeight: "bold" }}>
                  {data?.totalOrder?.length ?? "00"}
                </h1>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-4">
            <div className="dashboard-icon-header2 p-3 rounded text-white d-flex justify-content-center" onClick={() => setComponent('Confirm Order')}>
              <div className="dash-header-des text-center mt-3">
                <span className="dasboard-box-text">Order Completed</span>
                <h1 className="text-white" style={{ fontWeight: "bold" }}>
                  {data?.confirmOrder?.length ?? "00"}
                </h1>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-4">
            <div className="dashboard-icon-header2 p-3 rounded text-white d-flex justify-content-center" onClick={() => setComponent('Pending Order')}>
              <div className="dash-header-des text-center mt-3">
                <span className="dasboard-box-text">Pending Order</span>
                <h1 className="text-white" style={{ fontWeight: "bold" }}>
                  {data?.pendingOrder?.length ?? "00"}
                </h1>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-4">
            <div className="dashboard-icon-header2 p-3 rounded text-white d-flex justify-content-center" onClick={() => setComponent('Staff')}>
              <div className="dash-header-des text-center mt-3">
                <span className="dasboard-box-text">Total Staff</span>
                <h1 className="text-white" style={{ fontWeight: "bold" }}>
                  {data?.staff?.length ?? "00"}
                </h1>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-4">
            <div className="dashboard-icon-header2 p-3 rounded text-white d-flex justify-content-center" onClick={() => setComponent('Services')}>
              <div className="dash-header-des text-center mt-3">
                <span className="dasboard-box-text">Total Services</span>
                <h1 className="text-white" style={{ fontWeight: "bold" }}>
                  {data?.services?.length ?? "00"}
                </h1>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-4">
            <div className="dashboard-icon-header2 p-3 rounded text-white d-flex justify-content-center">
              <div className="dash-header-des text-center mt-3">
                <span className="dasboard-box-text">Total Earning</span>
                <h1 className="text-white" style={{ fontWeight: "600" }}>
                  {data?.totalEarnings ?? "0.00"}$
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      {renderComponent()}





      <div style={{ display: "flex", justifyContent: "center" }}>
        <nav aria-label="Page navigation example">
          <ul className="pagination justify-content-center gap-2">
            <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
              <button
                className="page-link"
                style={{ color: "black" }}
                onClick={() => handlePageChange(Math.max(1, currentPage - 1))}
                disabled={currentPage === 1}
              >
                Previous
              </button>
            </li>
            {renderPaginationButtons()}
            <li className={`page-item ${currentPage === totalPages ? "disabled" : ""}`}>
              <button
                className="page-link"
                style={{ color: "black" }}
                onClick={() => handlePageChange(Math.min(totalPages, currentPage + 1))}
                disabled={currentPage === totalPages}
              >
                Next
              </button>
            </li>
          </ul>
        </nav>
        
      </div>
      <div className="viewdetail mx-3 mb-3">
        <button className="rounded-pill bg-white text-black outline-none px-4 py-2 border" onClick={() => setShown(!shown)}>
          View Detail
        </button>
      </div>
      {data && (
        <div className={`container mb-4 ${shown ? 'visible-transition' : 'hidden-transition'}`}>
          <div className="row">
            {[
              { label: "First Name", value: data?.patner?.name ?? "N/A" },
              { label: "Email", value: data?.patner?.email ?? "N/A" },
              { label: "Phone Number", value: data?.patner?.phoneNumber ?? "N/A" },
              { label: "Business Name", value: data?.patner?.businessName ?? "N/A" },
              { label: "Location", value: data?.patner?.salonId?.location_name ?? "N/A" }
            ].map(({ label, value }, index) => (
              <div className="col-lg-6 col-md-6 col-sm-12" key={index}>
                <h6>{label}: </h6>
                <input
                  type="text"
                  style={{
                    border: "1px solid black",
                    borderRadius: "5px",
                    padding: "8px",
                    width: "100%",
                  }}
                  value={value}
                  disabled
                />
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
