import { Button } from '@mui/material'
import React from 'react'
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
const BlockButton = ({ text, click, compenet }) => {
    return (

        <Button  startIcon={<DoDisturbIcon />} component={compenet} onClick={click} className='px-4 ' style={{ marginTop: "1px", backgroundColor: "#212529", color: "#fff", borderRadius: "3px", height: "32px", textTransform: 'capitalize', width: "130px" }} type="primary">{text}</Button>
    )
}

export default BlockButton