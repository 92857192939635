import { Box } from "@mui/material";
import { Typography } from "antd";
import React, { useState } from "react";
import Header from "../../Components/Header";
import MainHeader from "../../Components/MainHeader";
import BsButton from "../../Components/BsButton";
import { Post } from "../../Config/ApiHandling";
import { useNavigate } from "react-router-dom";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import Copyright from '../../Components/Copyright'
import { ToastContainer, toast } from "react-toastify";

const CreateAdmin = ({ setCollapsed, collapsed }) => {
  const [loading, setLoading] = useState(false);
  const [showpassword, setshowpassword] = useState(false);
  const [showconfirmpassword, setshowConfirmpassword] = useState(false);
  // const [disable, setdisable] = useState(false);
  let navigate = useNavigate();

  const handleShowPassword = () => {
    setshowpassword((prevShowPassword) => !prevShowPassword);
  };

  const handleShowConfirmPassword = () => {
    setshowConfirmpassword((prevShowPassword) => !prevShowPassword);
  };

  const countrycode = [
    "+966", // Saudi Arabia
    "+1", // United States
    "+44", // United Kingdom
    "+92", // Pakistan
    "+91", // India
    "+33", // France
    "+49", // Germany
    "+81", // Japan
    "+86", // China
    "+61", // Australia
    "+34", // Spain
    "+39", // Italy
    "+7", // Russia
    "+82", // South Korea
    "+52", // Mexico
    "+55", // Brazil
    "+971", // United Arab Emirates
    "+968", // Oman
    "+20", // Egypt
    "+27", // South Africa
    "+30", // Greece
    "+31", // Netherlands
    "+32", // Belgium
    "+46", // Sweden
    "+47", // Norway
    "+48", // Poland
    "+54", // Argentina
    "+56", // Chile
    "+57", // Colombia
    "+58", // Venezuela
    "+60", // Malaysia
    "+63", // Philippines
    "+64", // New Zealand
    "+65", // Singapore
    "+66", // Thailand
    "+81", // Japan
    "+82", // South Korea
    "+84", // Vietnam
    "+86", // China
    "+90", // Turkey
    "+91", // India
    "+92", // Pakistan
    "+93", // Afghanistan
    "+94", // Sri Lanka
    "+95", // Myanmar
    "+98", // Iran
    "+212", // Morocco
    "+213", // Algeria
    "+216", // Tunisia
    "+218", // Libya
    "+220", // Gambia
    "+221", // Senegal
    "+222", // Mauritania
    "+223", // Mali
    "+224", // Guinea
    "+225", // Ivory Coast
    "+226", // Burkina Faso
    "+227", // Niger
    "+228", // Togo
    "+229", // Benin
    "+230", // Mauritius
    "+231", // Liberia
    "+232", // Sierra Leone
    "+233", // Ghana
    "+234", // Nigeria
    "+235", // Chad
    "+236", // Central African Republic
    "+237", // Cameroon
    "+238", // Cape Verde
    "+239", // Sao Tome and Principe
    "+240", // Equatorial Guinea
    "+241", // Gabon
    "+242", // Republic of the Congo
    "+243", // Democratic Republic of the Congo
    "+244", // Angola
    "+245", // Guinea-Bissau
    "+246", // British Indian Ocean Territory
    "+247", // Ascension Island
    "+248", // Seychelles
    "+249", // Sudan
    "+250", // Rwanda
    "+251", // Ethiopia
    "+252", // Somalia
    "+253", // Djibouti
    "+254", // Kenya
    "+255", // Tanzania
    "+256", // Uganda
    "+257", // Burundi
    "+258", // Mozambique
    "+260", // Zambia
    "+261", // Madagascar
    "+262", // Reunion
    "+263", // Zimbabwe
    "+264", // Namibia
    "+265", // Malawi
    "+266", // Lesotho
    "+267", // Botswana
    "+268", // Eswatini
    "+269", // Comoros
  ];

  const initialValues = {
    firstname: "",
    lastname: "",
    email: "",
    countrycode: "",
    phonenumber: "",
    password: "",
    confirmpassword: "",
  };

  const validationSchemaAdminCreate = Yup.object().shape({
    firstname: Yup.string().required("⚠️First Name is Required"),
    lastname: Yup.string().required("⚠️Last Name is Required"),
    email: Yup.string()
      .email('Invalid email')
      .required('⚠️Email is required')
      .test('contains-at', 'Email must contain "@"', (value) => {
        if (value) {
          const emailRegex = /@/;
          return emailRegex.test(value);
        }
        return true;
      }),
    phonenumber: Yup.string()
      .matches(/^[-+0-9]+$/, ('Phone number must only contain digits'))
      .min(10, "Phone number is too short")
      .max(15, 'Phone number is too long')
      .required('⚠️Phone number is required'),
    password: Yup.string()
      .matches(
        /^(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$%^&+=!]).*$/,
        'Password must contain at least one capital letter, one number, and one special symbol'
      )
      .min(8, 'Password must be at least 8 characters long')
      .required('⚠️Password is required'),

    confirmpassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required('⚠️Password is required'),

  })

  const senddata = (values) => {
    setLoading(false);
    // setdisable(false);
    const defaultCountryCode = "+966";
    const countryCodeToUse =
      values.countrycode && values.countrycode !== ""
        ? values.countrycode
        : defaultCountryCode;
    Post("admin/registeradmin", {
      firstname: values.firstname,
      lastname: values.lastname,
      email: values.email,
      phonenumber: countryCodeToUse + values.phonenumber,
      password: values.password,
      confirmPassword: values.confirmpassword,
    })
      .then((res) => {
        setLoading(false);
        // setdisable(false);
        toast.success("Admin created successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          draggable: true,
          progress: undefined,
        });
        setTimeout(() => {
          navigate("/dashboard/adminlist");
        }, 1000);
      })
      .catch((error) => {
        // setdisable(false);
        setLoading(false);
        if (error && error.response && error.response.data) {
          const { status, data, message } = error.response.data;
          if (status === false && data === null && message) {
            toast.error(`Error: ${message}`, {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              draggable: true,
              progress: undefined,
            });
            return;
          }
        }
      });
  };

  return (
    <div>
      <MainHeader setCollapsed={setCollapsed} collapsed={collapsed} />
      <Header screenTitle="Create Admin" />
      <Box
        sx={{ width: { md: "98%", sm: "325px", xs: "100%" } }}
        className=" shadow m-auto mt-2"
      >
        <ToastContainer />
        <Box>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchemaAdminCreate}
            onSubmit={(values) => senddata(values)}
            validateOnChange
          >
            {({ handleSubmit }) => (
              <Form onSubmit={handleSubmit}>
                <Box className="row p-3">
                  <div className="col-md-6">
                    <Typography className="" variant="p">
                      {" "}
                      First Name :{" "}
                    </Typography>
                    <div className="mt-2">
                      <Field
                        className="form-control w-100"
                        type="text"
                        name="firstname"
                      />
                      <ErrorMessage
                        name="firstname"
                        component="div"
                        className="error text-danger"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <Typography className="" variant="p">
                      {" "}
                      Last Name :{" "}
                    </Typography>
                    <div className="mt-2">
                      <Field
                        className="form-control w-100"
                        type="text"
                        name="lastname"
                      />
                      <ErrorMessage
                        name="lastname"
                        component="div"
                        className="error text-danger"
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <Typography className="" variant="p">
                      {" "}
                      Email :{" "}
                    </Typography>
                    <div className="mt-2">
                      <Field
                        className="form-control w-100"
                        type="text"
                        name="email"
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="error text-danger"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <Typography className="" variant="p">
                      {" "}
                      Phone Number :{" "}
                    </Typography>
                    <div className="mt-2">
                      <div className="d-flex">
                        <Field
                          as="select"
                          className="form-control w-25"
                          name="countrycode"
                        >
                          {countrycode.map((item, index) => (
                            <option key={index} value={item}>
                              {item}
                            </option>
                          ))}
                        </Field>
                        <Field
                          className="form-control w-75"
                          type="text"
                          pattern="[0-9]*"
                          name="phonenumber"
                        />
                      </div>
                      <ErrorMessage
                        name="phonenumber"
                        component="div"
                        className="error text-danger"
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <Typography className="mt-3" variant="p">
                      {" "}
                      Password :{" "}
                    </Typography>
                    <div className="mt-2 d-flex relative-position">
                      <Field
                        className="form-control w-100"
                        type={showpassword ? "text" : "password"}
                        name="password"
                      />
                      <button className="hide_btn" onClick={handleShowPassword} type="button">
                        {showpassword ? (
                          <EyeOutlined style={{ color: "#14213d" }} />
                        ) : (
                          <EyeInvisibleOutlined style={{ color: "#14213d" }} />
                        )}
                      </button>
                    </div>
                    <ErrorMessage
                      name="password"
                      component="div"
                      className="error text-danger"
                    />
                  </div>
                  <div className="col-md-6">
                    <Typography className="mt-3" variant="p">
                      {" "}
                      Confirm Password :{" "}
                    </Typography>
                    <div className="mt-2 d-flex relative-position">
                      <Field
                        className="form-control w-100"
                        type={showconfirmpassword ? "text" : "password"}
                        name="confirmpassword"
                      />
                      <button
                        className="hide_btn"
                        onClick={handleShowConfirmPassword}
                        type="button"
                      >
                        {showconfirmpassword ? (
                          <EyeOutlined style={{ color: "#14213d" }} />
                        ) : (
                          <EyeInvisibleOutlined style={{ color: "#14213d" }} />
                        )}
                      </button>
                    </div>
                    <ErrorMessage
                      name="confirmpassword"
                      component="div"
                      className="error text-danger"
                    />
                  </div>
                  <Box
                    sx={{ display: "flex", justifyContent: "start" }}
                    className="  py-3"
                  >
                    <BsButton text={"Create"} loading={loading} onClick={handleSubmit} type="submit" />
                  </Box>
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
      </Box>
      <Copyright />
    </div>
  );
};

export default CreateAdmin;