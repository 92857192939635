import { useEffect, useState } from "react";
import { FaSearch } from "react-icons/fa";
import "./SearchBar.css";

export const SearchBar = ({ setResults, saloonName, setSaloonName }) => {
  const [input, setInput] = useState("");
  useEffect(() => {
    setInput(saloonName);
  }, [saloonName]);
  const fetchData = (value) => {
    const token = localStorage.getItem("token");

    fetch("https://backend.beautyparkbp.com/api/partner/getBusiness", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((json) => {
        const businesses = json.data?.businesses || [];
        const lowerCaseValue = value.toLowerCase();

        const results = businesses.filter((business) => {
          if (!value) return true;
          return business.partnername_eng
            .toLowerCase()
            .includes(lowerCaseValue);
        });

        setResults(results);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setResults([]);
      });
  };

  const handleChange = (value) => {
    if (saloonName) {
      setSaloonName("");
      setInput("");
    } else {
      setInput(value);
    }
    fetchData(value);
  };

  const handleSelect = (name) => {
    setInput(name); // Set the selected name in the input field
    setResults([]); // Clear the results list
  };

  return (
    <div className="input-wrapper">
      <FaSearch id="search-icon" />
      <input
        type="text"
        placeholder="Type to search..."
        value={input}
        onChange={(e) => handleChange(e.target.value)}
      />
    </div>
  );
};
