import React, { useEffect, useState } from 'react'
import MainHeader from '../../Components/MainHeader'
import Header from '../../Components/Header'
import { Box, Button } from '@mui/material'
import { Typography, Skeleton } from 'antd'
import BsButton from '../../Components/BsButton'
import { Post, Put, imageurl, GetById } from '../../Config/ApiHandling'
import { useNavigate, useParams } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import DeleteButton from '../../Components/DeleteButton'
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import Copyright from '../../Components/Copyright'

const EditCategory = ({ setCollapsed, collapsed }) => {
    const [loading, setLoading] = useState(true);
    const [selectedImage, setSelectedImage] = useState(null);
    const [formValues, setFormValues] = useState({
        title: '',
        title_arb: '',
        images: '',
    });
    let navigation = useNavigate()
    const params = useParams()
    const token = localStorage.getItem("token")

    useEffect(() => {
        if (params.id) {
            GetById("getcategoriesbyid", params.id, token)
                .then((res) => {
                    setFormValues(res.data.data)
                    setLoading(false);
                })
                .catch((err) => {
                    const errorMessage = err.response && err.response.data && err.response.data.message
                    ? err.response.data.message
                    : 'An error occurred';
                toast.error(errorMessage, {
                    position: "top-right",
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                    setLoading(false);
                })
        } else {
            setFormValues({
                title: '',
                title_arb: '',
                images: '',
            });
            setLoading(false);
        }
    }, [params.id, token]);




    const initialValues = {
        title: formValues?.title || '',
        title_arb: formValues?.title_arb || '',
        images: formValues?.images || null,
    };


    const validationSchemaCategory = Yup.object().shape({
        title: Yup.string()
            .required('⚠️ Title (English) is required'),
        title_arb: Yup.string()
            .required('⚠️ Title (Arabic) is required'),
        images: Yup.mixed().required("⚠️ Image is Required"),
    });


    let sendData = (values) => {
        const formData = new FormData();
        formData.append('title', values.title);
        formData.append('title_arb', values.title_arb);
        formData.append('images', values.images);


        if (formValues?._id) {
            setLoading(true);
            Put("updatecategory", formValues._id, formData, token)
                .then((res) => {
                    setLoading(false);
                    toast("Category updated successfully", {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        draggable: true,
                        progress: undefined,
                    });
                    setTimeout(() => {
                        navigation("/dashboard/categorylist");
                    }, 1000);
                })
                .catch((err) => {
                    setLoading(false);
                    const errorMessage = err.response && err.response.data && err.response.data.message
                        ? err.response.data.message
                        : 'An error occurred';

                    toast(errorMessage, {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        draggable: true,
                        progress: undefined,
                    });
                });
        } else {
            Post("createCategory", formData, token)
                .then((res) => {
                    setLoading(false);
                    toast("Category created successfully", {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        draggable: true,
                        progress: undefined,
                    });
                    setTimeout(() => {
                        navigation("/dashboard/categorylist");
                    }, 1000);
                })
                .catch((err) => {
                    setLoading(false);
                    const errorMessage = err.response && err.response.data && err.response.data.message
                        ? err.response.data.message
                        : 'An error occurred';

                    toast(errorMessage, {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        draggable: true,
                        progress: undefined,
                    });
                });
        }
    };





    const handleFileChange = (e, setFieldValue) => {
        const file = e.target.files[0];

        if (file) {
            const newImageURL = URL.createObjectURL(file);
            setSelectedImage(newImageURL);
            setFieldValue('images', file);
            setFormValues({ ...formValues, images: file });
        } else {
            setSelectedImage(null);
            setFieldValue('images', null);
            setFormValues({ ...formValues, images: null });
        }
    };


    return (
        <div>
            <MainHeader setCollapsed={setCollapsed} collapsed={collapsed} />
            <Header screenTitle={`${formValues?._id ? "Edit Category" : "Create Category"}`} buttonList={[
                {
                    displayField: () => (
                        // <Button color='error' className='delete_btn' onClick={() => navigation("/dashboard/adminList")} variant='contained'>Cancel</Button>
                        <DeleteButton click={() => navigation("/dashboard/categorylist")} text="Cancel" />
                    )
                }
            ]} />
            <Box sx={{ width: { md: '98%', sm: '325px', xs: '100%' } }} className=' shadow m-auto mt-2'>
                <Box >
                    <ToastContainer />
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchemaCategory}
                        onSubmit={(values) => sendData(values)}
                        validateOnChange
                        enableReinitialize={true}
                    >
                        {({ handleSubmit, setFieldValue, touched, errors }) => (
                            <Form onSubmit={handleSubmit}>
                                {loading ? (
                                    <div className="mt-2">
                                        <Box className='row p-3'>
                                            <div className="col-md-6">
                                                <Typography variant='p'> Title (Eng) : </Typography>
                                                <div className='mt-2'>
                                                    <Skeleton.Input active style={{ width: '50vh' }} />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <Typography variant='p'> Title (Arb) : </Typography>
                                                <div className='mt-2'>
                                                    <Skeleton.Input active style={{ width: '50vh' }} />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <Typography variant='p'> Image : </Typography>
                                                <div className='mt-2'>
                                                    <Skeleton.Image active style={{ width: '30vh' }} />
                                                </div>
                                            </div>
                                            <Box sx={{ display: 'flex', justifyContent: 'start' }} className='  py-3'>
                                                <Skeleton.Button active shape='button' style={{ width: '20vh' }} />
                                            </Box>
                                        </Box>
                                    </div>
                                ) : (
                                    <Box className='row p-3'>
                                        <div className="col-md-6">
                                            <Typography variant='p'> Title (Eng) : </Typography>
                                            <div className='mt-2'>
                                                <Field
                                                    className="form-control w-100"
                                                    type="text"
                                                    name="title"
                                                    value={formValues?.title || ""}
                                                    onChange={(e) => {
                                                        setFieldValue('title', e.target.value);
                                                        setFormValues({ ...formValues, title: e.target.value });
                                                    }}
                                                />
                                                {touched.title && errors.title ? (
                                                    <div className="error text-danger">{errors.title}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <Typography variant='p'> Title (Arb) : </Typography>
                                            <div className='mt-2'>
                                                <Field
                                                    className="form-control w-100"
                                                    type="text"
                                                    name="title_arb"
                                                    value={formValues?.title_arb || ""}
                                                    onChange={(e) => {
                                                        setFieldValue('title_arb', e.target.value);
                                                        setFormValues({ ...formValues, title_arb: e.target.value });
                                                    }}
                                                />
                                                {touched.title_arb && errors.title_arb ? (
                                                    <div className="error text-danger">{errors.title_arb}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <Typography variant='p'> Image : </Typography>
                                            <div className='mt-2'>
                                                <input
                                                    type="file"
                                                    id="fileInput"
                                                    style={{ display: 'none' }}
                                                    accept=".jpg, .jpeg, .png"
                                                    onChange={(e) => handleFileChange(e, setFieldValue)}
                                                />

                                                <Button
                                                    component="label"
                                                    className="form-control w-100"
                                                    htmlFor="fileInput"
                                                >
                                                    Select Image
                                                </Button>
                                                {formValues?.images && (
                                                    <div>
                                                        {formValues?.images instanceof File ? (
                                                            <img
                                                                src={selectedImage}
                                                                alt="Selected"
                                                                style={{ width: '100px', marginTop: '10px' }}
                                                            />
                                                        ) : (
                                                            <img
                                                                src={`${imageurl(formValues.images).imageURL}`}
                                                                style={{ width: '100px', marginTop: '10px' }}
                                                                alt=''
                                                            />
                                                        )}
                                                    </div>
                                                )}
                                                {touched.images && errors.images ? (
                                                    <div className="error text-danger">{errors.images}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                        <Box sx={{ display: 'flex', justifyContent: 'start' }} className='  py-3'>
                                            <BsButton text={`${formValues?._id ? "Update" : "Create Category"}`} loading={loading} onClick={handleSubmit} type="submit" />
                                        </Box>
                                    </Box>
                                )}
                            </Form>
                        )}
                    </Formik>
                </Box >
            </Box >
            <Copyright />
        </div >
    )

}

export default EditCategory