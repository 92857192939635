import { Skeleton } from "antd";
// import { Box } from '@mui/material'
import { ToastContainer, toast } from "react-toastify";
import Header from "../../Components/Header";
import MainHeader from "../../Components/MainHeader";
import { Delete, GetTheList, Post, imageurl } from "../../Config/ApiHandling";
import { useCallback, useEffect, useState } from "react";
import SearchInput from "../../Components/SearchInp";
import { Modal } from "react-bootstrap";
import EditButton from "../../Components/EditButton";
import Copyright from "../../Components/Copyright";
import DeleteButton from "../../Components/DeleteButton";
import ApprovedButton from "../../Components/ApprovedButtton";
import Swal from "sweetalert2";
import AddButton from "../../Components/AddButton";

const PromoList = ({ setCollapsed, collapsed }) => {
  let [data, setData] = useState([]);
  let [flag, setflag] = useState(false);
  let [detail, setDetail] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState([]);
  const startIndex = (currentPage - 1) * 10 + 1;

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const PromoDetails = (userId) => {
    setDetail(data.filter((item) => item._id === userId));
    setShowModal(true);
  };

  let cols = [
    {
      name: "Sr No",
      key: "index",
    },

    {
      name: "Partner Name",
      key: "businessId?.partnername_eng",
    },
    {
      name: "Promo Title",
      key: "name",
    },
    {
      name: "Action",
      displayFeild: (item) => (
        <>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <ApprovedButton
              disabled={item.isAdminApprove}
              text="Approved"
              click={() => approvedPromo(item._id)}
            />
            <EditButton text="" click={() => PromoDetails(item._id)} />
            <DeleteButton text="" click={() => DeletePromo(item._id)} />
          </div>
        </>
      ),
    },
  ];

  const token = localStorage.getItem("token");
  // const GetData = () => {
  //     const limit = 10;
  //     GetTheList(`partner/getPromos`, currentPage, limit, token)
  //         .then((res) => {
  //             setData(res?.data?.promos);
  //             setTotalPages(res.data.last_page);
  //         })
  //         .catch((e) => {
  //             const errorMessage = e.response && e.response.data && e.response.data.message
  //                 ? e.response.data.message
  //                 : 'An error occurred';
  //             toast.error(errorMessage, {
  //                 position: "top-right",
  //                 autoClose: 4000,
  //                 hideProgressBar: false,
  //                 closeOnClick: true,
  //                 pauseOnHover: true,
  //                 draggable: true,
  //                 progress: undefined,
  //                 theme: "light",
  //             });
  //         }).finally(() => {
  //             setflag(false);
  //         });
  // }

  // useEffect(() => {
  //     GetData(currentPage)
  // }, [currentPage])

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };
  const GetData = useCallback(() => {
    const limit = 10;
    GetTheList(`partner/getPromosAdmin`, currentPage, limit, token)
      .then((res) => {
        setData(res?.data?.promos);
        setTotalPages(res.data.last_page);
      })
      .catch((e) => {
        const errorMessage =
          e.response && e.response.data && e.response.data.message
            ? e.response.data.message
            : "An error occurred";
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      })
      .finally(() => {
        setflag(false);
      });
  }, [currentPage, token]);

  useEffect(() => {
    GetData();
  }, [GetData]);

  let approvedPromo = (e) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Approved it!",
    })
      ?.then((result) => {
        if (result.isConfirmed) {
          Post("/partner/approved-promo/" + e, e, token)
            ?.then((res) => {
              GetData();
            })
            .catch((e) => {
              const errorMessage =
                e.response && e.response.data && e.response.data.message
                  ? e.response.data.message
                  : "An error occurred";
              toast.error(errorMessage, {
                position: "top-right",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
            });
        }
      })
      .catch((e) => {
        const errorMessage =
          e.response && e.response.data && e.response.data.message
            ? e.response.data.message
            : "An error occurred";
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  };

  let DeletePromo = (e) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    })
      ?.then((result) => {
        if (result.isConfirmed) {
          Delete("/partner/deletePromo", e, token)
            ?.then((res) => {
              GetData();
            })
            .catch((e) => {
              const errorMessage =
                e.response && e.response.data && e.response.data.message
                  ? e.response.data.message
                  : "An error occurred";
              toast.error(errorMessage, {
                position: "top-right",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
            });
        }
      })
      .catch((e) => {
        const errorMessage =
          e.response && e.response.data && e.response.data.message
            ? e.response.data.message
            : "An error occurred";
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  };

  const renderPaginationButtons = () => {
    const buttons = [];
    const maxButtonsToShow = 5;
    const halfWay = Math.floor(maxButtonsToShow / 2);

    let startPage, endPage;

    if (totalPages <= maxButtonsToShow) {
      // If total pages are less than max buttons to show, show all pages
      startPage = 1;
      endPage = totalPages;
    } else if (currentPage <= halfWay) {
      // If current page is near the start, show the first few pages
      startPage = 1;
      endPage = maxButtonsToShow;
    } else if (currentPage + halfWay >= totalPages) {
      // If current page is near the end, show the last few pages
      startPage = totalPages - maxButtonsToShow + 1;
      endPage = totalPages;
    } else {
      // Otherwise, center the current page in the range
      startPage = currentPage - halfWay;
      endPage = currentPage + halfWay;
    }

    // Generate page buttons
    for (let i = startPage; i <= endPage; i++) {
      buttons.push(
        <li
          key={i}
          className={`page-item ${currentPage === i ? "active" : ""}`}
        >
          <button
            className="page-link"
            onClick={() => handlePageChange(i)}
            style={{
              backgroundColor: currentPage === i ? "white" : "#212529",
              border: "1px solid #212529",
              borderRadius: "5px",
              color: currentPage === i ? "black" : "white",
            }}
          >
            {i}
          </button>
        </li>
      );
    }

    return buttons;
  };

  return (
    <>
      <div>
        <MainHeader setCollapsed={setCollapsed} collapsed={collapsed} />
        <Header screenTitle="Promo List" />
        <ToastContainer />
        <div
          style={{
            display: "flex",
            alignItems: "",
            justifyContent: "space-between",
            width: "100%",
            padding: "0px 30px 0px 60px",
          }}
        >
          <AddButton
            path="/dashboard/createcashback"
            text="Add cash back voucher"
          />
          <SearchInput
            setflag={setflag}
            data={data}
            setData={setData}
            originalData={GetData}
            endpoint={"partner/search-promos"}
            msg={"This_is_not_typ_case"}
          />
        </div>
        <>
          <Modal
            show={showModal}
            onHide={handleCloseModal}
            centered
            backdrop="true"
            keyboard={false}
            dialogClassName="custom-modal"
            size="lg"
          >
            <Modal.Header closeButton>
              <Modal.Title className="header-title">Promo Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {detail && (
                <>
                  <div className="container">
                    <div className="row mt-4">
                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <h6>Name: </h6>{" "}
                        <input
                          type="text"
                          style={{
                            border: "1px solid black",
                            borderRadius: "5px",
                            padding: "8px",
                            width: "100%",
                          }}
                          value={detail[0]?.name}
                          disabled
                        />
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <h6>Value Type: </h6>{" "}
                        <input
                          type="text"
                          style={{
                            border: "1px solid black",
                            borderRadius: "5px",
                            padding: "8px",
                            width: "100%",
                            textAlign: "center",
                          }}
                          value={detail[0]?.val_type}
                          disabled
                        />
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <h6>Value: </h6>{" "}
                        <input
                          type="text"
                          style={{
                            border: "1px solid black",
                            borderRadius: "5px",
                            padding: "8px",
                            width: "100%",
                          }}
                          value={detail[0]?.value}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <h6>Discount Validity From: </h6>{" "}
                        <input
                          type="text"
                          style={{
                            border: "1px solid black",
                            borderRadius: "5px",
                            padding: "8px",
                            width: "100%",
                          }}
                          value={detail[0]?.dis_val_from}
                          disabled
                        />
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <h6>Discount Validity Expiry: </h6>{" "}
                        <input
                          type="text"
                          style={{
                            border: "1px solid black",
                            borderRadius: "5px",
                            padding: "8px",
                            width: "100%",
                          }}
                          value={
                            detail[0]?.dis_val_exp === null
                              ? "--"
                              : detail[0]?.dis_val_exp
                          }
                          disabled
                        />
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <h6>In Continuous: </h6> <p> {detail[0]?.continuous}</p>
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <h6>Display: </h6> <p> {detail[0]?.display}</p>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <h6>Services: </h6>{" "}
                        <input
                          type="text"
                          style={{
                            border: "1px solid black",
                            borderRadius: "5px",
                            padding: "8px",
                            width: "100%",
                          }}
                          value={detail[0]?.service?.service_cat}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <h6>Banner Image: </h6>{" "}
                        <img
                          className="img-fluid"
                          style={{ width: "70px" }}
                          src={`${imageurl(detail[0]?.images).imageURL}`}
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <h6>Publish Offer Now?: </h6>{" "}
                        <input
                          type="text"
                          style={{
                            border: "1px solid black",
                            borderRadius: "5px",
                            padding: "8px",
                            width: "100%",
                          }}
                          value={detail[0]?.expire}
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}
            </Modal.Body>
          </Modal>
          <div style={{ display: "flex", justifyContent: "center" }}>
            {flag ? (
              <>
                <table
                  className="table table-striped table-bordered text-center"
                  style={{
                    width: "90%",
                    margin: "1%",
                    border: "1px solid #ccc",
                  }}
                >
                  <thead>
                    <tr>
                      {cols &&
                        cols.map((item, index) => {
                          return (
                            <th
                              className="header-title"
                              style={{
                                backgroundColor: "#212529",
                                color: "#fff",
                              }}
                              key={index}
                            >
                              {item.name}
                            </th>
                          );
                        })}
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td colSpan="6">
                        {flag ? (
                          <Skeleton.Input
                            style={{ width: "90vh" }}
                            active
                            animation="wave"
                          />
                        ) : (
                          "No Data"
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </>
            ) : Array.isArray(data) && data.length > 0 ? (
              <table
                className="table table-striped table-bordered text-center"
                style={{ width: "90%", margin: "1%", border: "1px solid #ccc" }}
              >
                <thead>
                  <tr>
                    {cols &&
                      cols.map((item, index) => {
                        return (
                          <th
                            className={`header-title${
                              index === 3
                                ? " w-25"
                                : index === 2
                                ? " w-30per"
                                : index === 0
                                ? " w-7per"
                                : " w-30per"
                            }`}
                            style={{
                              backgroundColor: "#212529",
                              color: "#fff",
                              fontSize: "20px",
                            }}
                            key={index}
                          >
                            {item.name}
                          </th>
                        );
                      })}
                  </tr>
                </thead>
                <tbody>
                  {data.map((rowData, key) => (
                    <tr key={key} className="table_img tablerow">
                      {cols.map((col, index) => (
                        <td style={{ verticalAlign: "middle" }} key={index}>
                          {col.displayFeild ? (
                            col.displayFeild(rowData)
                          ) : col.key === "index" ? (
                            startIndex + key
                          ) : col.img ? (
                            <img
                              className="table_img"
                              src={`${rowData[col.img]}`}
                              alt="NoImage"
                              style={{ width: "60px", height: "55px" }}
                            />
                          ) : col.key === "amount" || col.key === "price" ? (
                            `$${rowData[col.key]}`
                          ) : col.key === "name" ? (
                            rowData?.name
                          ) : col.key === "businessId?.partnername_eng" ? (
                            rowData?.businessId?.partnername_eng
                          ) : null}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <table
                className="table table-striped table-bordered text-center"
                style={{ width: "90%", margin: "1%", border: "1px solid #ccc" }}
              >
                <thead>
                  <tr>
                    {cols &&
                      cols.map((item, index) => {
                        return (
                          <th
                            className="header-title"
                            style={{
                              backgroundColor: "#212529",
                              color: "#fff",
                            }}
                            key={index}
                          >
                            {item.name}
                          </th>
                        );
                      })}
                  </tr>
                </thead>
                <tbody>
                  {cols.map((item, index) => (
                    <tr key={index}>
                      <td key={index} colSpan="6">
                        <Skeleton.Input
                          style={{ width: "90vh" }}
                          active
                          animation="wave"
                        />{" "}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <nav aria-label="Page navigation example">
            <ul className="pagination justify-content-center gap-2  ">
              <li
                className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
              >
                <button
                  className="page-link"
                  style={{ color: "black" }}
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                >
                  Previous
                </button>
              </li>
              {/* {Array.from({ length: totalPages }, (_, index) => (
                                <li key={index} className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}>
                                    <button className="page-link" onClick={() => handlePageChange(index + 1)} style={{
                            backgroundColor: currentPage === index + 1 ? "white" : "#212529",
                            border: "1px solid #212529",
                            borderRadius: "5px",
                            color: currentPage === index + 1 ? "black" : "white"
                        }}>
                                        {index + 1}
                                    </button>
                                </li>
                            ))} */}
              {renderPaginationButtons()}
              <li
                className={`page-item ${
                  currentPage === totalPages ? "disabled" : ""
                }`}
              >
                <button
                  className="page-link"
                  style={{ color: "black" }}
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalPages}
                >
                  Next
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      <Copyright />
    </>
  );
};

export default PromoList;

// route.post("/approved-promo/:id", verifyAdminToken, upload, promoController.approvedpromo)
