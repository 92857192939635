import React, { useEffect, useState } from 'react';
import MainHeader from '../../Components/MainHeader';
import Header from '../../Components/Header';
import { Box, Button, Grid, TextField, Typography, CircularProgress, IconButton } from '@mui/material';
import { Post, GetList, imageurl, Put } from '../../Config/ApiHandling';
import { useNavigate, useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import DeleteButton from '../../Components/DeleteButton';
import { Form, Formik, Field } from 'formik';
import * as Yup from 'yup';
import Copyright from '../../Components/Copyright';
import ClearIcon from '@mui/icons-material/Clear';

const EditHomeSlider = ({ setCollapsed, collapsed }) => {
    const [loading, setLoading] = useState(true);
    const [selectedImage, setSelectedImage] = useState(null);
    const [link, setLink] = useState('');
    const [textOne, setTextOne] = useState('');
    const [textTwo, setTextTwo] = useState('');
    const [formValues, setFormValues] = useState({ image: null });
    const [isEditMode, setIsEditMode] = useState(false);
    const [isViewOnly, setIsViewOnly] = useState(false);
    const [isid, setid] = useState('');
    const navigation = useNavigate();
    const location = useLocation();
    const token = localStorage.getItem('token');

    useEffect(() => {
        // Extract ID from URL
        const searchParams = new URLSearchParams(location.search);
        const id = searchParams.get('id');
        const viewId = searchParams.get('viewid');

        if (id) {
            setIsEditMode(true);
            setIsViewOnly(false);
            setid(id);
        } else if (viewId) {
            setIsEditMode(false);
            setIsViewOnly(true);
            setid(viewId);
        } else {
            setIsEditMode(false);
            setIsViewOnly(false);
        }

        if (isEditMode || isViewOnly) {
            // Fetch existing data if in edit or view-only mode
            GetList('/admin/gethomeSlider-images/' + isid, token)
                .then((res) => {
                    const data = res.data.data || {};
                    const image = data.images?.[0] || null;
                    setFormValues({ image });
                    setSelectedImage(image ? imageurl(image).imageURL : null);
                    setLink(data.link || '');
                    setTextOne(data.textOne || '');
                    setTextTwo(data.textTwo || '');
                    setLoading(false);
                })
                .catch((e) => {
                    const errorMessage = e.response?.data?.message || 'An error occurred';
                    toast.error(errorMessage);
                    setLoading(false);
                });
        } else {
            setLoading(false); // No need to load if adding
        }
    }, [isEditMode, isViewOnly, location.search, token]);

    const initialValues = {
        image: formValues.image || null,
        link: link,
        textOne: textOne,
        textTwo: textTwo,
    };

    const validationSchemaCategory = Yup.object().shape({
        image: Yup.mixed().required('⚠️ An image is required'),
        link: Yup.string().url('⚠️ Enter a valid URL').required('⚠️ Link is required'),
        textOne: Yup.string().required('⚠️ Text One is required'),
        textTwo: Yup.string().required('⚠️ Text Two is required'),
    });

    const handleSubmit = (values) => {
        if (isViewOnly) return; // Prevent form submission in view-only mode

        const formData = new FormData();

        if (values.image) {
            formData.append('images', values.image);
        }
        formData.append('link', values.link);
        formData.append('textOne', values.textOne);
        formData.append('textTwo', values.textTwo);

        const url = isEditMode ? '/admin/updateHomeSlider-images/' : '/admin/homeSlider-images';
        if (isEditMode) {
            Put(url, isid, formData, token)
                .then(() => {
                    setLoading(false);
                    toast.success('Image updated successfully');
                    setTimeout(() => {
                        navigation('/dashboard/homeslider');
                    }, 1000);
                })
                .catch((err) => {
                    setLoading(false);
                    const errorMessage = err.response?.data?.message || 'An error occurred';
                    toast.error(errorMessage);
                });
        } else {
            Post(url, formData, token)
                .then(() => {
                    setLoading(false);
                    toast.success('Image added successfully');
                    setTimeout(() => {
                        navigation('/dashboard/homeslider');
                    }, 1000);
                })
                .catch((err) => {
                    setLoading(false);
                    const errorMessage = err.response?.data?.message || 'An error occurred';
                    toast.error(errorMessage);
                });
        }
    };

    const handleFileChange = (e, setFieldValue) => {
        if (isViewOnly) return; // Prevent file changes in view-only mode

        const file = e.target.files[0];
        if (file) {
            setSelectedImage(URL.createObjectURL(file));
            setFieldValue('image', file);
            setFormValues({ image: file });
        }
    };

    const handleRemoveImage = () => {
        if (isViewOnly) return; // Prevent image removal in view-only mode

        setSelectedImage(null);
        setFormValues({ image: null });
    };

    return (
        <div>
            <MainHeader setCollapsed={setCollapsed} collapsed={collapsed} />
            <Header
                screenTitle={isEditMode ? 'Edit Home Slider Image' : (isViewOnly ? 'View Home Slider Image' : 'Add Home Slider Image')}
                buttonList={[
                    {
                        displayField: () => (
                            <Button
                                variant="contained"
                                onClick={() => navigation('/dashboard/homeslider')}
                                sx={{
                                    backgroundColor: '#d32f2f', // Dark background color
                                    color: '#fff', // White text color
                                    '&:hover': {
                                        backgroundColor: '#e08080fa' // Slightly darker on hover
                                    }
                                }}
                            >
                                Cancel
                            </Button>
                        ),
                    },
                ]}
            />
            <Box sx={{ width: '90%', mx: 'auto', mt: 2, p: 3, boxShadow: 3, borderRadius: 2 }}>
                <ToastContainer />
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchemaCategory}
                    onSubmit={handleSubmit}
                    validateOnChange
                    enableReinitialize
                >
                    {({ handleSubmit, setFieldValue, touched, errors }) => (
                        <Form onSubmit={handleSubmit}>
                            {loading ? (
                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                                    <CircularProgress />
                                </Box>
                            ) : (
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={6}>
                                        <Typography variant="h6">Image:</Typography>
                                        <Box mt={2}>
                                            <input
                                                type="file"
                                                id="fileInput"
                                                style={{ display: 'none' }}
                                                accept=".jpg, .jpeg, .png"
                                                onChange={(e) => handleFileChange(e, setFieldValue)}
                                                disabled={isViewOnly}
                                            />
                                            <Button
                                                component="label"
                                                htmlFor="fileInput"
                                                variant="contained"
                                                fullWidth
                                                sx={{
                                                    backgroundColor: 'rgb(33, 37, 41)', // Dark background color
                                                    color: '#fff', // White text color
                                                    '&:hover': {
                                                        backgroundColor: 'rgb(28, 32, 34)' // Slightly darker on hover
                                                    }
                                                }}
                                                disabled={isViewOnly}
                                            >
                                                {isViewOnly ? 'View Image' : 'Select Image'}
                                            </Button>

                                            {selectedImage && (
                                                <Box mt={2} sx={{ position: 'relative', display: 'inline-block' }}>
                                                    <img
                                                        src={selectedImage}
                                                        alt="Selected"
                                                        style={{ width: '100px', height: '100px', objectFit: 'cover' }}
                                                    />
                                                    {!isViewOnly && (
                                                        <IconButton
                                                            onClick={handleRemoveImage}
                                                            sx={{ position: 'absolute', top: 0, right: 0 }}
                                                        >
                                                            <ClearIcon />
                                                        </IconButton>
                                                    )}
                                                </Box>
                                            )}
                                            {touched.image && errors.image && (
                                                <Typography color="error" variant="body2" sx={{ mt: 1 }}>
                                                    {errors.image}
                                                </Typography>
                                            )}
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            fullWidth
                                            label="Text One"
                                            value={textOne}
                                            onChange={(e) => setTextOne(e.target.value)}
                                            margin="normal"
                                            InputProps={{ readOnly: isViewOnly }}
                                            error={touched.textOne && Boolean(errors.textOne)}
                                            helperText={touched.textOne && errors.textOne}
                                        />
                                        <TextField
                                            fullWidth
                                            label="Text Two"
                                            value={textTwo}
                                            onChange={(e) => setTextTwo(e.target.value)}
                                            margin="normal"
                                            InputProps={{ readOnly: isViewOnly }}
                                            error={touched.textTwo && Boolean(errors.textTwo)}
                                            helperText={touched.textTwo && errors.textTwo}
                                        />
                                        <TextField
                                            fullWidth
                                            label="Link"
                                            value={link}
                                            onChange={(e) => setLink(e.target.value)}
                                            margin="normal"
                                            InputProps={{ readOnly: isViewOnly }}
                                            error={touched.link && Boolean(errors.link)}
                                            helperText={touched.link && errors.link}
                                        />
                                    </Grid>
                                    {!isViewOnly && (
                                        <Grid item xs={12}>
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                fullWidth
                                                sx={{
                                                    backgroundColor: 'rgb(33, 37, 41)', // Dark background color
                                                    color: '#fff', // White text color
                                                    '&:hover': {
                                                        backgroundColor: 'rgb(28, 32, 34)' // Slightly darker on hover
                                                    }
                                                }}
                                                disabled={loading}
                                            >
                                                {loading ? 'Processing...' : (isEditMode ? 'Update' : 'Add')}
                                            </Button>
                                        </Grid>
                                    )}
                                </Grid>
                            )}
                        </Form>
                    )}
                </Formik>
            </Box>
            <Copyright />
        </div>
    );
};

export default EditHomeSlider;
