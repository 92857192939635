import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons"
import Logout from '@mui/icons-material/Logout';
import { useLocation, useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'


const MainHeader = ({ setCollapsed, collapsed }) => {
  let navigate = useNavigate()
  let location = useLocation();
  const [isLogin, setIsLogin] = useState(localStorage.getItem('islogin'));
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const adminid = localStorage.getItem("Admin Details")
  if (adminid) {
    var admin = JSON.parse(adminid);
  }
  const Profile = () => {
    navigate(`/dashboard/adminDetails/${admin._id}`, {
    });
  }
  const getFirstLetter = (str) => {
    return str ? str.charAt(0).toUpperCase() : '';
  };


  const Handlogout = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to logout!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, logout !"
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.removeItem("token")
        localStorage.removeItem("Admin Details")
        localStorage.setItem('islogin', false);
        setIsLogin(false);
        Swal.fire({
          title: "Logout!",
          text: "You are logout successfully .",
          icon: "success"
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire({
          title: "Cancelled",
          text: "Your action has been cancelled.",
          icon: "error"
        });
      }
    });

  }
  useEffect(() => {
    if (isLogin === false) {
      navigate('/');
    }
  }, [isLogin, navigate]);
  return (
    <div>
      <Box className="w-100 py-2 px-3 align-items-center mb-1" sx={{ backgroundColor: "#DADADA", border: "4px solid white", display: "flex", justifyContent: "space-between" }}>
        <button
          type="primary"
          onClick={() => setCollapsed(!collapsed)}
          style={{
            marginLeft: 16,
            backgroundColor: "transparent",
            color: "black",
            fontSize: "25px",
            border: "none"
          }}
        >
          {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
        </button>
        {(location.pathname === '/dashboard/adminlist') && (
          <Typography variant='h6' className='pt-1 fs-4 px-2 header-title' sx={{ color: "#212529" }}>
            Admin Dashboard
          </Typography>
        )}
        {(location.pathname === '/dashboard') && (
          <Typography variant='h6' className='pt-1 fs-4 px-2 header-title' sx={{ color: "#212529" }}>
            Dashboard
          </Typography>
        )}
        <Tooltip>
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2, backgroundColor: "#212529" }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <Avatar sx={{ width: 32, height: 32, backgroundColor: "#212529" }}>{getFirstLetter(admin?.firstname)}</Avatar>
          </IconButton>
        </Tooltip>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              '&::before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          <MenuItem onClick={Profile}>
            <Avatar /> Profile
          </MenuItem>
          <Divider />
          <MenuItem onClick={Handlogout}>
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            Logout
          </MenuItem>
        </Menu>
      </Box>
    </div>
  )
}

export default MainHeader       