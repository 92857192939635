import { Skeleton } from 'antd'
import { ToastContainer } from 'react-toastify'
import { toast } from 'react-toastify'
import Header from '../../Components/Header'
import MainHeader from '../../Components/MainHeader'
import { GetTheList } from '../../Config/ApiHandling'
// import Swal from 'sweetalert2'
import { useEffect, useState, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
// import DeleteButton from '../../Components/DeleteButton'
import EditButton from '../../Components/EditButton'
// import AddButton from '../../Components/AddButton'
// import SearchInput from '../../Components/SearchInp'
import Copyright from '../../Components/Copyright'


const FooterList = ({ setCollapsed, collapsed }) => {
    let [data, setData] = useState([])
    let [flag, setflag] = useState(false)
    const [currentPage, setCurrentPage] = useState(1);
    // const [totalPages, setTotalPages] = useState([]);
    const startIndex = (currentPage - 1) * 10 + 1;
    let navigation = useNavigate()

    let cols = [
        {
            name: "Sr No",
            key: "index"
        },
        {
            name: "Text",
            key: "text"
        },
        {
            name: "Action",
            displayFeild: (item) => (
                <>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <EditButton text="Edit" click={() => edit(item)} />
                    </div>
                </>
            ),
        },
    ]

    const token = localStorage.getItem("token")
    const GetData = useCallback(() => {
        const limit = 10;
        GetTheList(`getFooter`, currentPage, limit, token)
            .then((res) => {
                setData(res.data.data);
                // setTotalPages(res.data.data.last_page);
            })
            .catch((e) => {
                const errorMessage = e.response && e.response.data && e.response.data.message
                    ? e.response.data.message
                    : 'An error occurred';
                toast.error(errorMessage, {
                    position: "top-right",
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }).finally(() => {
                setflag(false);
            });
    }, [currentPage, token])


    useEffect(() => {
        GetData(currentPage)
    }, [currentPage, GetData])

    // const handlePageChange = (newPage) => {
    //     setCurrentPage(newPage);
    // };

    let edit = (item) => {
        navigation(`/dashboard/editfooter/${item._id}`, {
            state: item
        });
    }

    return (
        <>
            <div>
                <MainHeader setCollapsed={setCollapsed} collapsed={collapsed} />
                <Header screenTitle="Footer" />
                <ToastContainer />
                <>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        {flag ? (
                            <>
                                <table
                                    className="table table-striped table-bordered text-center"
                                    style={{ width: '90%', margin: '1%', border: '1px solid #ccc' }}
                                >

                                    <thead>
                                        <tr>
                                            {cols &&
                                                cols.map((item, index) => {
                                                    return <th className='header-title' style={{ backgroundColor: "#212529", color: "#fff" }} key={index}>{item.name}</th>;
                                                })}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td colSpan="6">No Data</td>
                                        </tr>
                                    </tbody>
                                </table>

                            </>
                        ) : Array.isArray(data) && data.length > 0 ? (
                            <table
                                className="table table-striped table-bordered text-center"
                                style={{ width: '90%', margin: '1%', border: '1px solid #ccc' }}
                            >
                                <thead>
                                    <tr>
                                        {cols &&
                                            cols.map((item, index) => {
                                                return <th className='header-title' style={{ backgroundColor: "#212529", color: "#fff", fontSize: "20px" }} key={index}>{item.name}</th>;
                                            })}
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((rowData, key) => (
                                        <tr key={key} className='table_img tablerow'>
                                            {cols.map((col, index) => (
                                                <td style={{ verticalAlign: 'middle' }} key={index}>
                                                    {/* Render cell data based on column key */}
                                                    {/* Update this part to match your column keys and data structure */}
                                                    {col.displayFeild ? (
                                                        col.displayFeild(rowData)
                                                    ) : col.key === 'index' ? (
                                                        startIndex + key
                                                    ) : col.img ? (
                                                        <img
                                                            className='table_img'
                                                            src={`${rowData[col.img]}`}
                                                            alt="NoImage"
                                                            style={{ width: '60px', height: '55px' }}
                                                        />
                                                    ) : (
                                                        (col.key === 'amount' || col.key === 'price') ? `$${rowData[col.key]}` :
                                                            col.key === "text" ? rowData?.text : null
                                                    )}
                                                </td>
                                            ))}
                                        </tr>
                                    ))}
                                </tbody>

                            </table>
                        ) : (
                            <table
                                className="table table-striped table-bordered text-center"
                                style={{ width: '90%', margin: '1%', border: '1px solid #ccc' }}
                            >
                                <thead>
                                    <tr>
                                        {cols &&
                                            cols.map((item, index) => {
                                                return <th className='header-title' style={{ backgroundColor: "#212529", color: "#fff" }} key={index}>{item.name}</th>;
                                            })}
                                    </tr>
                                </thead>
                                <tbody>
                                    {cols.map((item, index) => (
                                        <tr key={index}>
                                            <td key={index} colSpan="6"><Skeleton.Input style={{ width: '90vh' }} active animation="wave" /> </td>
                                        </tr>
                                    ))
                                    }
                                </tbody>
                            </table>

                        )}
                    </div>
                </>
            </div>
            <>
                <Copyright />
            </>
        </>
    )
}

export default FooterList