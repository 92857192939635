import { Skeleton } from "antd";
// import { Box } from '@mui/material'
// import AddButton from '../../Components/AddButton'
// import ViewButton from '../../Components/ViewButton'
import { ToastContainer } from "react-toastify";
import { toast } from "react-toastify";
import Header from "../../Components/Header";
import MainHeader from "../../Components/MainHeader";
import { Delete, GetTheList } from "../../Config/ApiHandling";
import Swal from "sweetalert2";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DeleteButton from "../../Components/DeleteButton";
import EditButton from "../../Components/EditButton";
import AddButton from "../../Components/AddButton";
import { Button, Modal } from "react-bootstrap";
import SearchInput from "../../Components/SearchInp";
import Copyright from "../../Components/Copyright";

const FAQsList = ({ setCollapsed, collapsed }) => {
  let [data, setData] = useState([]);
  // let [detail, setDetail] = useState([])
  let [flag, setflag] = useState(false);
  let navigation = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [userDetails, setUserDetails] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState([]);
  const startIndex = (currentPage - 1) * 10 + 1;
  // let navigate = useNavigate()

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleShowModal = (userData) => {
    setUserDetails(userData);
    setShowModal(true);
  };

  // const fetchPartnerDetails = (userId, item) => {
  //     setDetail(data.filter((partner) => partner._id === userId));
  //     setShowModal(true);
  // };

  let cols = [
    {
      name: "Sr No",
      key: "index",
    },
    {
      name: "Title",
      key: "title",
    },
    {
      name: "Description",
      key: "description",
      viewMore: true,
    },
    {
      name: "Action",
      displayFeild: (item) => (
        <>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <EditButton text="Edit" click={() => edit(item)} />
            <DeleteButton text="Delete" click={() => DeleteFAQs(item._id)} />
          </div>
        </>
      ),
    },
  ];

  const token = localStorage.getItem("token");
  // const GetData = () => {
  //     const limit = 10;
  //     GetTheList(`getFaqs`, currentPage, limit, token)
  //         .then((res) => {
  //             setData(res.data.data.faqs);
  //             setTotalPages(res.data.data.last_page);
  //         })
  //         .catch((e) => {
  //             const errorMessage = e.response && e.response.data && e.response.data.message
  //                 ? e.response.data.message
  //                 : 'An error occurred';
  //             toast.error(errorMessage, {
  //                 position: "top-right",
  //                 autoClose: 4000,
  //                 hideProgressBar: false,
  //                 closeOnClick: true,
  //                 pauseOnHover: true,
  //                 draggable: true,
  //                 progress: undefined,
  //                 theme: "light",
  //             });
  //         }).finally(() => {
  //             setflag(false);
  //         });
  // }

  // useEffect(() => {
  //     GetData(currentPage)
  // }, [currentPage])

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };
  const GetData = useCallback(() => {
    const limit = 10;
    GetTheList(`getFaqs`, currentPage, limit, token)
      .then((res) => {
        setData(res.data.data.faqs);
        setTotalPages(res.data.data.last_page);
      })
      .catch((e) => {
        const errorMessage =
          e.response && e.response.data && e.response.data.message
            ? e.response.data.message
            : "An error occurred";
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      })
      .finally(() => {
        setflag(false);
      });
  }, [currentPage, token]);

  useEffect(() => {
    GetData();
  }, [GetData]);

  let edit = (item) => {
    navigation(`/dashboard/editfaq/${item._id}`, {
      state: item,
    });
  };

  let DeleteFAQs = (e) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    })
      ?.then((result) => {
        if (result.isConfirmed) {
          Delete("deleteFaqs", e, token)
            ?.then((res) => {
              GetData();
            })
            .catch((e) => {
              const errorMessage =
                e.response && e.response.data && e.response.data.message
                  ? e.response.data.message
                  : "An error occurred";
              toast.error(errorMessage, {
                position: "top-right",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
            });
        }
      })
      .catch((e) => {
        const errorMessage =
          e.response && e.response.data && e.response.data.message
            ? e.response.data.message
            : "An error occurred";
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  };

  const renderPaginationButtons = () => {
    const buttons = [];
    const maxButtonsToShow = 5;
    const halfWay = Math.floor(maxButtonsToShow / 2);

    let startPage, endPage;

    if (totalPages <= maxButtonsToShow) {
      // If total pages are less than max buttons to show, show all pages
      startPage = 1;
      endPage = totalPages;
    } else if (currentPage <= halfWay) {
      // If current page is near the start, show the first few pages
      startPage = 1;
      endPage = maxButtonsToShow;
    } else if (currentPage + halfWay >= totalPages) {
      // If current page is near the end, show the last few pages
      startPage = totalPages - maxButtonsToShow + 1;
      endPage = totalPages;
    } else {
      // Otherwise, center the current page in the range
      startPage = currentPage - halfWay;
      endPage = currentPage + halfWay;
    }

    // Generate page buttons
    for (let i = startPage; i <= endPage; i++) {
      buttons.push(
        <li
          key={i}
          className={`page-item ${currentPage === i ? "active" : ""}`}
        >
          <button
            className="page-link"
            onClick={() => handlePageChange(i)}
            style={{
              backgroundColor: currentPage === i ? "white" : "#212529",
              border: "1px solid #212529",
              borderRadius: "5px",
              color: currentPage === i ? "black" : "white",
            }}
          >
            {i}
          </button>
        </li>
      );
    }

    return buttons;
  };

  return (
    <>
      <div>
        <MainHeader setCollapsed={setCollapsed} collapsed={collapsed} />
        <Header screenTitle="Faqs List" />
        <ToastContainer />
        <div
          style={{
            display: "flex",
            alignItems: "end",
            justifyContent: "end",
            width: "100%",
            margin: "0px -40px",
          }}
        >
          <SearchInput
            setflag={setflag}
            data={data}
            setData={setData}
            originalData={GetData}
            endpoint={"search-Faqs"}
            msg={"This_is_not_typ_case"}
          />
          <AddButton path="/dashboard/createfaq" />
        </div>
        <>
          <Modal
            show={showModal}
            onHide={handleCloseModal}
            centered
            backdrop="true"
            keyboard={false}
            dialogClassName="custom-modal"
          >
            <Modal.Header closeButton>
              <Modal.Title>DESCRIPTION</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {userDetails && (
                <>
                  <div
                    style={{ display: "flex", justifyContent: "space-evenly" }}
                  >
                    <>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: userDetails.description,
                        }}
                      ></div>
                    </>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-evenly",
                      paddingTop: "10px",
                    }}
                  >
                    <>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: userDetails.description_arb,
                        }}
                      ></div>
                    </>
                  </div>
                </>
              )}
            </Modal.Body>
          </Modal>
          <div style={{ display: "flex", justifyContent: "center" }}>
            {flag ? (
              <>
                <table
                  className="table table-striped table-bordered text-center"
                  style={{
                    width: "90%",
                    margin: "1%",
                    border: "1px solid #ccc",
                  }}
                >
                  <thead>
                    <tr>
                      {cols &&
                        cols.map((item, index) => {
                          return (
                            <th
                              className="header-title"
                              style={{
                                backgroundColor: "#212529",
                                color: "#fff",
                              }}
                              key={index}
                            >
                              {item.name}
                            </th>
                          );
                        })}
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td colSpan="6">
                        {flag ? (
                          <Skeleton.Input
                            style={{ width: "90vh" }}
                            active
                            animation="wave"
                          />
                        ) : (
                          "No Data"
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </>
            ) : Array.isArray(data) && data.length > 0 ? (
              <table
                className="table table-striped table-bordered text-center"
                style={{ width: "90%", margin: "1%", border: "1px solid #ccc" }}
              >
                <thead>
                  <tr>
                    {cols &&
                      cols.map((item, index) => {
                        return (
                          <th
                            className="header-title"
                            style={{
                              backgroundColor: "#212529",
                              color: "#fff",
                              fontSize: "20px",
                            }}
                            key={index}
                          >
                            {item.name}
                          </th>
                        );
                      })}
                  </tr>
                </thead>
                <tbody>
                  {data.map((item, key) => (
                    <tr key={key} className="table_img tablerow">
                      {cols.map((col, index) => (
                        <td style={{ verticalAlign: "middle" }} key={index}>
                          {col.displayFeild ? (
                            col.displayFeild(item)
                          ) : col.key === "index" ? (
                            startIndex + key
                          ) : col.img ? (
                            <img
                              className="table_img"
                              src={`${item[col.img]}`}
                              alt="NoImage"
                              style={{ width: "60px", height: "55px" }}
                            />
                          ) : col.key === "amount" || col.key === "price" ? (
                            `$${item[col.key]}`
                          ) : col.key === "title" ? (
                            item?.title
                          ) : col.key === "description" ? (
                            <>
                              {col.viewMore ? (
                                <>
                                  {item.description.length > 0 ? (
                                    <>
                                      <Button
                                        onClick={() => handleShowModal(item)}
                                        variant="dark"
                                        className="view_btn"
                                      >
                                        View
                                      </Button>
                                    </>
                                  ) : (
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: item.description,
                                      }}
                                    ></div>
                                  )}
                                </>
                              ) : (
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: item.description,
                                  }}
                                ></div>
                              )}
                            </>
                          ) : null}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <table
                className="table table-striped table-bordered text-center"
                style={{ width: "90%", margin: "1%", border: "1px solid #ccc" }}
              >
                <thead>
                  <tr>
                    {cols &&
                      cols.map((item, index) => {
                        return (
                          <th
                            className="header-title"
                            style={{
                              backgroundColor: "#212529",
                              color: "#fff",
                            }}
                            key={index}
                          >
                            {item.name}
                          </th>
                        );
                      })}
                  </tr>
                </thead>
                <tbody>
                  {cols.map((item, index) => (
                    <tr key={index}>
                      <td key={index} colSpan="6">
                        <Skeleton.Input
                          style={{ width: "90vh" }}
                          active
                          animation="wave"
                        />{" "}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <nav aria-label="Page navigation example">
            <ul className="pagination justify-content-center gap-2  ">
              <li
                className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
              >
                <button
                  className="page-link"
                  style={{ color: "black" }}
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                >
                  Previous
                </button>
              </li>
              {/* {Array.from({ length: totalPages }, (_, index) => (
                                <li key={index} className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}>
                                    <button className="page-link" onClick={() => handlePageChange(index + 1)} style={{
                            backgroundColor: currentPage === index + 1 ? "white" : "#212529",
                            border: "1px solid #212529",
                            borderRadius: "5px",
                            color: currentPage === index + 1 ? "black" : "white"
                        }}>
                                        {index + 1}
                                    </button>
                                </li>
                            ))} */}
              {renderPaginationButtons()}
              <li
                className={`page-item ${
                  currentPage === totalPages ? "disabled" : ""
                }`}
              >
                <button
                  className="page-link"
                  style={{ color: "black" }}
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalPages}
                >
                  Next
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      <Copyright />
    </>
  );
};

export default FAQsList;
