import MainHeader from "../Components/MainHeader";
import React, { useEffect, useRef, useState } from "react";
import { MdBusinessCenter } from "react-icons/md";
import { MdGroups } from "react-icons/md";
import { FaUserCircle } from "react-icons/fa";
import { Get, GetList, GetTheList, Post } from "../Config/ApiHandling";
import Copyright from "../Components/Copyright";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";

function SubMainDashboard({ setCollapsed, collapsed }) {
  let [saloon, setSaloon] = useState();
  let [saloonList, setSaloonList] = useState();
  let [client, setClient] = useState();
  let [staff, setStaff] = useState();
  let [totalEarnings, setTotalEarnings] = useState();
  let [receivables, setReceivables] = useState();
  let [totalBookings, setTotalBookings] = useState();
  let [saloonComission, setSaloonComission] = useState();
  const [selectedSalonId, setSelectedSalonId] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const token = localStorage.getItem("token");
  const selectedSalonIdRef = useRef(null);
  const [showModal, setShowModal] = useState(false);
  const [commissionAmount, setCommissionAmount] = useState("");
  const [selectedPartnerId, setSelectedPartnerId] = useState(null);
  const [comisionError, setCommisionError] = useState("");
  const [isAdminApprove, setIsAdminApprove] = useState(false);
  let navigation = useNavigate();

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const fetchCommisssion = () => {
    if (!selectedSalonId) {
      toast.error("Please select salon.", {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }
    setShowModal(true);
  };

  useEffect(() => {
    Get("partner/getStaff")
      .then((succ) => {
        setStaff(succ.data.data?.totalData);
      })
      .catch((err) => {
        const errorMessage =
          err.response && err.response.data && err.response.data.message
            ? err.response.data.message
            : "An error occurred";
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
    Get("get-clients")
      .then((succ) => {
        setClient(succ.data.data?.total);
      })
      .catch((err) => {
        const errorMessage =
          err.response && err.response.data && err.response.data.message
            ? err.response.data.message
            : "An error occurred";
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
    const token = localStorage.getItem("token");
    GetList("partner/getBusiness", token)
      .then((res) => {
        setSaloon(res?.data?.data?.total);
        setSaloonList(res?.data?.data?.businesses);
      })
      .catch(() => {});
  }, []);

  const handleSalonSelect = (event) => {
    if (event && event.target) {
      const selectedSalonid1 = event.target.value;
      selectedSalonIdRef.current = event.target.value;
      setSelectedSalonId(selectedSalonid1);
      handleSalonSelectData(); // Call handleSalonSelectData without passing selectedSalonId
    }
  };

  const handleSalonSelectData = () => {
    const selectedSalonIdByRef = selectedSalonIdRef.current;
    if (selectedSalonIdByRef) {
      const limit = 10;
      GetTheList(
        `admin/getSalonDetails/${selectedSalonIdByRef}`,
        currentPage,
        limit,
        token
      )
        .then((res) => {
          setReceivables(res?.data?.Receivables);
          setTotalEarnings(res?.data?.TotalEarnings);
          setTotalBookings(res?.data?.TotalBooking);
          setSaloonComission(res?.data?.Commision);
          setCommissionAmount(res?.data?.partnerCommision);
          setSelectedPartnerId(res?.data?.partnerId);
        })
        .catch((err) => {
          const errorMessage =
            err.response && err.response.data && err.response.data.message
              ? err.response.data.message
              : "An error occurred";
          toast.error(errorMessage, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        });
    }
  };

  const handleSaloonClick = () => {
    navigation("/dashboard/saloonlist");
  };

  const handleClientClick = () => {
    navigation("/dashboard/clientlist");
  };

  const handleTotalBookingClick = () => {
    navigation("/dashboard/bookingslist");
  };

  const approvedPromo = (amount) => {
    if (!amount) {
      setCommisionError("⚠️ Commission is required");
      return;
    }
    Post(
      `/admin/approvedPartner/${selectedPartnerId}`,
      { commision: amount },
      token
    )
      .then((res) => {
        setShowModal(false);
        setCommissionAmount("");
        setCommisionError("");
        handleSalonSelectData();
        toast.success("Comision added successfully", {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      })
      .catch((e) => {
        const errorMessage =
          e.response && e.response.data && e.response.data.message
            ? e.response.data.message
            : "An error occurred";
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  };

  useEffect(() => {
    handleSalonSelectData();
  }, [currentPage]);

  return (
    <div className="w-100">
      <ToastContainer />
      <MainHeader setCollapsed={setCollapsed} collapsed={collapsed} />
      <Modal
        show={showModal}
        onHide={handleCloseModal}
        centered
        backdrop="true"
        keyboard={false}
        dialogClassName="custom-modal"
        size="md"
      >
        <Modal.Header closeButton>
          <Modal.Title className="dashboard-commission-modal">
            Commission
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* {detail && ( */}
          <div className="container">
            <div className="row mt-4">
              <div className="col-lg-6 col-md-6 col-sm-12">
                <h6>% Amount: </h6>
                <input
                  type="number"
                  value={commissionAmount}
                  onChange={(e) => setCommissionAmount(e.target.value)}
                  style={{
                    border: "1px solid black",
                    borderRadius: "5px",
                    padding: "8px",
                    width: "100%",
                  }}
                  className="no-spinner"
                />
                {commissionAmount ? null : <p>{comisionError}</p>}
              </div>
            </div>
          </div>
          {/* )} */}
        </Modal.Body>
        <Modal.Footer>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-primary"
              onClick={() =>
                approvedPromo(
                  selectedPartnerId,
                  commissionAmount,
                  isAdminApprove
                )
              }
            >
              Save changes
            </button>
          </div>
        </Modal.Footer>
      </Modal>
      <div className="container">
        <div className='className="row px-3 d-flex justify-content-around"'>
          <div className="col-md-4 mb-4 w-100">
            <div className="dash-header-des text-center mt-3">
              <h1 className="dasboard-box-text">Select Saloon</h1>
              <hr />
              <select
                name="salon"
                id="salonSelect"
                className="saloon-list"
                onChange={handleSalonSelect}
                value={selectedSalonId}
              >
                <option value="" disabled>
                  Select salon
                </option>
                {saloonList &&
                  saloonList.map((item, index) => (
                    <option key={index} value={item._id}>
                      {item.partnername_eng}
                    </option>
                  ))}
              </select>
            </div>
          </div>
        </div>
        <div className="row p-3 d-flex justify-content-between">
          <div
            className="col-sm-12 col-md-6 mb-4 display-box-content"
            onClick={handleSaloonClick}
            style={{ cursor: "pointer" }}
          >
            <div className="dashboard-icon-header p-3 rounded text-white d-flex justify-content-between flex-column">
              <div className="d-flex justify-content-center">
                <MdBusinessCenter
                  size={50}
                  color="#fff"
                  style={{ padding: "5px", fontSize: "20px" }}
                />
              </div>
              <div className="dash-header-des text-center">
                <span className="dasboard-box-head">SALOON</span>
                <h1 className="text-white">{saloon ?? "...."}</h1>
              </div>
            </div>
          </div>
          <div
            className="col-sm-12 col-md-6 mb-4 display-box-content"
            onClick={handleClientClick}
            style={{ cursor: "pointer" }}
          >
            <div className="dashboard-icon-header p-3 rounded text-white d-flex justify-content-between flex-column">
              <div className="d-flex justify-content-center">
                <FaUserCircle
                  size={50}
                  color="#fff"
                  style={{ padding: "5px", fontSize: "20px" }}
                />
              </div>
              <div className="dash-header-des text-center">
                <span className="dasboard-box-head">CLIENT</span>
                <h1 className="text-white">{client ?? "...."}</h1>
              </div>
            </div>
          </div>
          <div
            className="col-sm-12 col-md-6 mb-4 display-box-content"
            onClick={handleTotalBookingClick}
            style={{ cursor: "pointer" }}
          >
            <div className="dashboard-icon-header p-3 rounded text-white d-flex justify-content-between flex-column">
              <div className="d-flex justify-content-center">
                <MdGroups
                  size={50}
                  color="#fff"
                  style={{ padding: "5px", fontSize: "20px" }}
                />
              </div>
              <div className="dash-header-des text-center">
                <span className="dasboard-box-head">Total Booking</span>
                <h1 className="text-white">{totalBookings ?? "0"}</h1>
              </div>
            </div>
          </div>
        </div>
        <div className="row p-3 d-flex justify-content-between">
          <div className="col-sm-12 col-md-6 mb-4 display-box-content">
            <div className="dashboard-icon-header bg-dark p-3 rounded text-white d-flex justify-content-center">
              <div className="dash-header-des text-center mt-3">
                <span className="dasboard-box-head">Receivables</span>
                <h1 className="text-white" style={{ fontWeight: "bold" }}>
                  {receivables ?? "0.00"}
                </h1>
                <span className="dasboard-box-head">SAR</span>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 mb-4 display-box-content">
            <div className="dashboard-icon-header bg-dark p-3 rounded text-white d-flex justify-content-center">
              <div className="dash-header-des text-center mt-3">
                <span className="dasboard-box-head">Saloon Profit</span>
                <h1 className="text-white" style={{ fontWeight: "bold" }}>
                  {totalEarnings ?? "0.00"}
                </h1>
                <span className="dasboard-box-head">SAR</span>
              </div>
            </div>
          </div>
          <div
            className="col-sm-12 col-md-6 mb-4 display-box-content"
            onClick={fetchCommisssion}
            style={{ cursor: "pointer" }}
          >
            <div className="dashboard-icon-header bg-dark p-3 rounded text-white d-flex justify-content-center">
              <div className="dash-header-des text-center mt-3">
                <span className="dasboard-box-head">Saloon Commission</span>
                <h1 className="text-white" style={{ fontWeight: "bold" }}>
                  {saloonComission ?? "0.00"}
                </h1>
                <span className="dasboard-box-head">%</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Copyright />
    </div>
  );
}

export default SubMainDashboard;
