import React from 'react';

const Copyright = () => {
    return (
        <div style={styles.container}>
            <p className='container' style={styles.text}>&copy; 2024 Copyrights by Beauty park. All rights reserved.</p>
        </div>
    );
};

const styles = {
    container: {
        backgroundColor: '#0E0E0E',
        color: '#fff',
        padding: '10px',
        position: 'fixed',
        bottom: 0,
        width: '100%',
        zIndex: 1000,
    },
    text: {
        margin: 0,
        fontFamily: "poppins",
        textAlign: "center",
        cursor: "default"
    },
};

export default Copyright;
