import React, { useEffect, useState } from "react";
import MainHeader from "../../Components/MainHeader";
import Header from "../../Components/Header";
import { useLocation } from "react-router-dom";
import { useLoadScript } from "@react-google-maps/api";
import axios from "axios";
import countryList from "react-select-country-list";
import { useMemo } from "react";
import { ToastContainer, toast } from 'react-toastify';
import Select from "react-select";
export default function EditDetail({ setCollapsed, collapsed }) {
  const [detail, setDetail] = useState([]);
  const location = useLocation();
  const googleMapsApiKey = "AIzaSyC5PZM8s51v1b1Vf_-QQQHWwZlTtn2RQHY";
  const token = localStorage.getItem("token");
  const Countryoptions = useMemo(() => countryList().getData(), []);
  useEffect(() => {
    const filterdata = location.state?.data;
    // console.log(filterdata);
    if (filterdata) {
      setDetail(filterdata[0]);
    }
  }, []);

  // const { isLoaded } = useLoadScript({
  //   googleMapsApiKey,
  // });

  // const center = {
  //   lat: Number(detail[0]?.latitude),
  //   lng: Number(detail[0]?.longitude),
  // };

  // const mapOptions = {
  //   disableDefaultUI: true,
  // };

  const handleChange = (key, value) => {
    // console.log(key, value);
    setDetail((prevDetail) => ({
      ...prevDetail,
      [key]: value,
    }));
  };

  const handleSave = async () => {
    // console.log(detail);
    try {
      const response = await axios.put(
        `https://backend.beautyparkbp.com/api/partner/updateBusiness/${detail?._id}`,
        detail,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response) {

        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });

      }
    } catch (error) { }
  };
  const selectedCountry = detail?.country
  const selectcountry = (event) => {
    // console.log(event?.label);
    const newCountry = event?.label
    handleChange("country", newCountry)
  };
  const genderdropdown = ["male", "female", "unisex"];
  return (
    <div>
      <MainHeader setCollapsed={setCollapsed} collapsed={collapsed} />
      <Header screenTitle="Edit Saloon Details" />
      <ToastContainer />

      <div className="container mb-4">
        <div className="row mt-4">
          <div className="col-lg-6 col-md-6 col-sm-12">
            <h6>Salon Name (Eng): </h6>{" "}
            <input
              type="text"
              style={{
                border: "1px solid black",
                borderRadius: "5px",
                padding: "8px",
                width: "100%",
              }}
              value={detail?.partnername_eng}
              onChange={(e) => handleChange("partnername_eng", e.target.value)}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <h6>Salon Name (Arb): </h6>{" "}
            <input
              type="text"
              style={{
                border: "1px solid black",
                borderRadius: "5px",
                padding: "8px",
                width: "100%",
                textAlign: "end",
              }}
              value={detail?.partnername_arb}
              onChange={(e) => handleChange("partnername_arb", e.target.value)}
            />
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-lg-6 col-md-6 col-sm-12">
            <h6>Store Link: </h6>{" "}
            <input
              type="text"
              style={{
                border: "1px solid black",
                borderRadius: "5px",
                padding: "8px",
                width: "100%",
              }}
              value={detail?.store_link}
              onChange={(e) => handleChange("store_link", e.target.value)}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <h6>Service Type: </h6>{" "}
            {/* <input
              type="text"
              style={{
                border: "1px solid black",
                borderRadius: "5px",
                padding: "8px",
                width: "100%",
              }}
              value={detail?.service_Type}
              onChange={(e)=>handleChange("service_Type",e.target.value)}
            /> */}
            <select
              name="service_Type"
              className="w-100 p-2 rounded"
              id=""
              value={detail?.service_Type}
              onChange={(e) => handleChange("service_Type", e.target.value)}
            >
              {genderdropdown?.map((item, index) => {
                return (
                  <option value={item} index={index}>
                    {item}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-lg-6 col-md-6 col-sm-12">
            <h6>Home Amount Min (SAR): </h6>{" "}
            <input
              type="text"
              style={{
                border: "1px solid black",
                borderRadius: "5px",
                padding: "8px",
                width: "100%",
              }}
              value={detail?.home_Amnt_min}
              onChange={(e) => handleChange("home_Amnt_min", e.target.value)}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <h6>Salon Amount Min (SAR): </h6>{" "}
            <input
              type="text"
              style={{
                border: "1px solid black",
                borderRadius: "5px",
                padding: "8px",
                width: "100%",
              }}
              value={detail?.salon_Amnt_min}
              onChange={(e) => handleChange("salon_Amnt_min", e.target.value)}
            />
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-lg-6 col-md-6 col-sm-12">
            <h6>Settlement Frequency: </h6>{" "}
            <input
              type="text"
              style={{
                border: "1px solid black",
                borderRadius: "5px",
                padding: "8px",
                width: "100%",
              }}
              value={detail?.settlement_frequency}
              onChange={(e) =>
                handleChange("settlement_frequency", e.target.value)
              }
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <h6>Supplier Availability(Gender(s)): </h6>{" "}
            <select
              name="supplier_availability"
              className="w-100 p-2 rounded"
              id=""
              value={detail?.supplier_availability}
              onChange={(e) => handleChange("supplier_availability", e.target.value)}
            >
              {genderdropdown?.map((item, index) => {
                return (
                  <option value={item} index={index}>
                    {item}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-lg-6 col-md-6 col-sm-12">
            <h6>Auto Confirm Bookings: </h6>{" "}
            <select
              name="auto_Confirm_Bookings"
              className="w-100 p-2 rounded"
              id=""
              value={detail?.auto_Confirm_Bookings}
              onChange={(e) => handleChange("auto_Confirm_Bookings", e.target.value)}
            >
              {["Yes", "No"]?.map((item, index) => {
                return (
                  <option value={item} index={index}>
                    {item}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <h6>Payment Modes: </h6>{" "}
            <select
              name="payment_Modes"
              className="w-100 p-2 rounded"
              id=""
              value={detail?.payment_Modes}
              onChange={(e) => handleChange("payment_Modes", e.target.value)}
            >
              {["cash", "card"]?.map((item, index) => {
                return (
                  <option value={item} index={index}>
                    {item}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-lg-6 col-md-6 col-sm-12">
            <h6>Description(eng) (Optional): </h6>{" "}
            <textarea
              type="text"
              style={{
                border: "1px solid black",
                borderRadius: "5px",
                padding: "8px",
                width: "100%",
                height: "20vh",
                resize: "none",
              }}
              value={detail?.description_eng}
              onChange={(e) => handleChange("description_eng", e.target.value)}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <h6>Description(arb) (Optional): </h6>{" "}
            <textarea
              type="text"
              style={{
                border: "1px solid black",
                borderRadius: "5px",
                padding: "8px",
                width: "100%",
                height: "20vh",
                resize: "none",
                textAlign: "end",
              }}
              value={detail?.description_arb}
              onChange={(e) => handleChange("description_arb", e.target.value)}
            />
          </div>
        </div>
        {/* <div className="row mt-4">
          <div className="col-lg-12 col-md-6 col-sm-12">
            <h6>Location: </h6>
            <div style={{ height: "400px", width: "100%" }}>
              {!isLoaded ? (
                <h1>Loading...</h1>
              ) : detail?.latitude && detail?.longitude ? (
                <GoogleMap
                  mapContainerStyle={{ height: "100%", width: "100%" }}
                  zoom={10}
                  center={center}
                  options={mapOptions}
                >
                  <Marker
                    position={{
                      lat: Number(detail?.latitude),
                      lng: Number(detail?.longitude),
                    }}
                  />
                </GoogleMap>
              ) : (
                <p>No location available</p>
              )}
            </div>
          </div>
        </div> */}
        <div className="row mt-4">
          <div className="col-lg-6 col-md-6 col-sm-12">
            <h6>Neighborhood: </h6>{" "}
            <input
              type="text"
              style={{
                border: "1px solid black",
                borderRadius: "5px",
                padding: "8px",
                width: "100%",
              }}
              value={detail?.neighborhood}
              onChange={(e) => handleChange("neighborhood", e.target.value)}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <h6>Location Name: </h6>{" "}
            <input
              type="text"
              style={{
                border: "1px solid black",
                borderRadius: "5px",
                padding: "8px",
                width: "100%",
              }}
              value={detail?.location_name}
              onChange={(e) => handleChange("location_name", e.target.value)}
            />
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-lg-6 col-md-6 col-sm-12">
            <h6>Country: </h6>{" "}
            <Select
              id="country"
              options={Countryoptions}
              value={selectedCountry}
              onChange={selectcountry}
              placeholder={detail?.country}
            />
            {/* <input
              type="text"
              style={{
                border: "1px solid black",
                borderRadius: "5px",
                padding: "8px",
                width: "100%",
              }}
              value={detail?.country}
              onChange={(e)=>handleChange("country",e.target.value)}
            /> */}
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <h6>City: </h6>{" "}
            <input
              type="text"
              style={{
                border: "1px solid black",
                borderRadius: "5px",
                padding: "8px",
                width: "100%",
              }}
              value={detail?.city}
              onChange={(e) => handleChange("city", e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="container">
        <button
          onClick={handleSave}
          className="rounded-pill bg-dark outline-none  text-white px-5 py-1"
        >
          Save
        </button>
      </div>
    </div>
  );
}
